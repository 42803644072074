import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'durationTime',
})
export class DurationTimePipe implements PipeTransform {
  transform(month: number): unknown {
    const y = Math.trunc(month / 12);
    const m = month - y * 12;

    let result = '';
    if (y > 0) {
      result += `${y} ปี `;
    }
    if (m > 0) {
      result += `${m} เดือน `;
    }

    return result;
  }
}
