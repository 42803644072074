import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignUpCourseComponent } from './components/sign-up-course/sign-up-course.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../shared/shared.module';
import { IconModule } from '../utils/icon/icon.module';
import { SocialSigninComponent } from './components/social-signin/social-signin.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { TranslateModule } from '@ngx-translate/core';
import { PolicyComponent } from './components/sign-up/policy/policy.component';

@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    SignUpComponent,
    SignUpCourseComponent,
    SocialSigninComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    PolicyComponent,
  ],
  imports: [CommonModule, MatCheckboxModule, SharedModule, ReactiveFormsModule, IconModule, TranslateModule],
})
export class AuthModule { }
