import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { AppConfig } from 'src/app/_models/layout.model';

@Component({
  selector: 'app-footer-licence',
  templateUrl: './footer-licence.component.html',
  styleUrls: ['./footer-licence.component.scss'],
})
export class FooterLicenceComponent implements OnInit {
  config: AppConfig;

  constructor(private layoutConfigService: LayoutConfigService) {}

  ngOnInit(): void {
    this.initLayout();
  }

  initLayout(): void {
    this.config = this.layoutConfigService.getSavedConfig('config.app');
  }
}
