import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { RadioOption } from 'src/app/shared/radio-button/radio-button.component';

enum ModeExam {
  EXAM_COURSE = 1,
  EXAM_STORAGE = 2,
}

@Component({
  selector: 'app-filter-exam',
  templateUrl: './filter-exam.component.html',
  styleUrls: ['./filter-exam.component.scss'],
})
export class FilterExamComponent implements OnInit, OnDestroy {
  form: FormGroup;
  mode = new FormControl(1);

  modeOptions: RadioOption[] = [
    {
      id: ModeExam.EXAM_COURSE,
      name: 'ข้อสอบจากคอร์ส',
    },
    {
      id: ModeExam.EXAM_STORAGE,
      name: 'คลังข้อสอบ',
    },
  ];

  subscriptions: Subscription[] = [];

  @Output() params = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.push(
      this.mode.valueChanges.pipe(distinctUntilChanged()).subscribe((change) => {
        if (change === ModeExam.EXAM_STORAGE) {
          this.form.patchValue({ course_exam_type_id: null, exam_course: null, exam_storage: true });
        } else {
          this.form.patchValue({ exam_course: true, exam_storage: null });
        }
      }),
      this.form.valueChanges.subscribe((change) => {
        this.params.emit(change);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  get modeExam(): typeof ModeExam {
    return ModeExam;
  }

  get modeValue(): number {
    return this.mode.value;
  }

  initForm(): void {
    this.form = this.fb.group({
      course_id: null,
      student_id: null,
      exam_course: true,
      exam_storage: null,
      course_exam_type_id: null,
    });
  }

  clearParams(): void {
    this.form.patchValue({
      exam_course: null,
      exam_storage: null,
      course_exam_type_id: null,
    });
  }
}
