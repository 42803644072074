<div class="star-rating">
  <div [ngClass]="{ active: isRating(i) }" *ngFor="let item of counter(total); let i = index" (click)="rating(i)">
    <i
      matTooltip="{{ i + 1 }} คะแนน"
      class="fa-sm"
      [ngClass]="{
        'rating-enabled': ratingEnabled,
        fas: isRating(i),
        far: !isRating(i),
        'fa-star': !isHalfRating(i),
        'fa-star-half-alt': isHalfRating(i)
      }"
    ></i>
  </div>
</div>
