import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parent-profile',
  templateUrl: './parent-profile.component.html',
  styleUrls: ['./parent-profile.component.scss']
})
export class ParentProfileComponent implements OnInit {
  sideMenu = [
    {
      name: 'จัดการข้อมูลส่วนตัว',
      path: '/parents/profile/personal-information',
      icon: 'clipboard',
      route: 'info',
    },
    // {
    //   name: 'จัดการบัญชี',
    //   icon: 'key',
    //   route: 'account',
    // },
    {
      name: 'รหัสผ่าน',
      path: '/parents/profile/password',
      icon: 'lock',
      route: 'password',
    },
    {
      name: 'จัดการนักเรียน',
      path: '/parents/profile/parent-menu',
      icon: 'users',
      route: 'student',
    },
    // {
    //   id: 5,
    //   name: 'การแจ้งเตือน',
    //   path: '/parents/profile/notification',
    //   icon: 'bell',
    // },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
