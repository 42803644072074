<div class="auth-dialog">
  <div class="auth-dialog-bg">
    <div class="bg-content">
      <div class="bg-title">{{ "AUTH.SIGN_UP.TITLE" | translate }} {{ "APP_TITLE" | translate }}</div>
      <div class="bg-line"></div>
      <div class="bg-description">{{ "AUTH.SIGN_UP.DESCRIPTION" | translate }}</div>
    </div>
  </div>
  <div class="auth-dialog-content">
    <form [formGroup]="form">
      <div class="signup-page">
        <div class="signup-role">
          <div
            [ngClass]="{ active: roleId === role.id }"
            (click)="onSelectedRole(role.id)"
            *ngFor="let role of roleList"
          >
            {{ role.name }}
          </div>
        </div>
        <!-- <app-social-signin></app-social-signin>
        <div class="divide"><span>OR</span></div> -->
        <div class="row signup-form">
          <div class="profile col-lg-12 col-sm-12">
            <app-upload-image [imageValue]="form.value.image_url" formControlName="image"></app-upload-image>
            <button class="btn btn-light" (click)="selectImage()">{{ "AUTH.SIGN_UP.UPLOAD_IMAGE" | translate }}</button>
            <span *ngIf="error.image" class="text-danger error-message">
              {{ "AUTH.SIGN_UP.UPLOAD_IMAGE_SUGGESTION" | translate }}
            </span>
          </div>
          <div class="col-lg-6 col-sm-12">
            <app-input
              label="ชื่อ"
              placeholder="ชื่อ"
              formControlName="first_name"
              customNgClass="signup"
              [error]="error.first_name"
            ></app-input>
          </div>
          <div class="col-lg-6 col-sm-12">
            <app-input
              label="นามสกุล"
              placeholder="นามสกุล"
              formControlName="last_name"
              customNgClass="signup"
              [error]="error.last_name"
            ></app-input>
          </div>
          <ng-container *ngIf="roleId === SignUpRoleIds.STUDENT">
            <div class="col-lg-6 col-sm-12">
              <app-input
                label="ชื่อเล่น"
                placeholder="ชื่อเล่น"
                formControlName="nickname"
                customNgClass="signup"
                [error]="error.nickname"
              ></app-input>
            </div>
            <div class="col-lg-6 col-sm-12">
              <app-input
                label="สถานศึกษา"
                placeholder="สถานศึกษา"
                formControlName="academy"
                customNgClass="signup"
                [error]="error.academy"
              ></app-input>
            </div>
            <!-- <div class="col-12">
              <app-input
                label="เลขบัตรประชาชน"
                placeholder="เลขบัตรประชาชน"
                formControlName="identification"
                [customMask]="'0-0000-00000-00-0'"
                customNgClass="signup"
                [error]="error.identification"
              ></app-input>
            </div> -->
          </ng-container>
          <div class="col-lg-6 col-sm-12">
            <app-input
              label="Email"
              placeholder="Email"
              formControlName="email"
              [error]="error.email"
              customNgClass="signup"
            ></app-input>
          </div>

          <div class="col-lg-6 col-sm-12">
            <app-datepicker
              formControlName="birthdate"
              label="วัน/เดือน/ปี เกิด"
              placeholder="วัน/เดือน/ปี"
              [error]="error.birthdate"
            ></app-datepicker>
          </div>

          <div class="col-12">
            <app-input
              label="โทรศัพท์"
              placeholder="โทรศัพท์"
              formControlName="phone"
              [customMask]="'0{10}'"
              customNgClass="signup"
              [icon]="true"
              [isPhone]="true"
              [error]="error.phone"
            ></app-input>
          </div>
          <div class="col-lg-6 col-sm-12">
            <app-input
              label="รหัสผ่าน"
              type="password"
              placeholder="รหัสผ่าน"
              formControlName="password"
              customNgClass="signup"
              [icon]="'lock'"
              [error]="error.password"
              [passwordToggle]="true"
            ></app-input>
          </div>
          <div class="col-lg-6 col-sm-12">
            <app-input
              label="ยืนยันรหัสผ่าน"
              type="password"
              placeholder="ยืนยันรหัสผ่าน"
              formControlName="password_confirmation"
              customNgClass="signup"
              [icon]="'lock'"
              [error]="error.password_confirmation"
              [passwordToggle]="true"
            ></app-input>
          </div>
        </div>
        <div class="signup-footer">
          <div class="signup-policy">
            <div>{{ "AUTH.SIGN_UP.POLICY" | translate }}</div>
            <div class="content-inline">
              <mat-checkbox [checked]="is_accept_policy" (change)="valueChangepPolicy()">ยอมรับ</mat-checkbox>
              <span (click)="onReadPolicy()">ข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัว</span>
            </div>
          </div>
          <button class="btn btn-primary btn-signup" (click)="onSignup()" [disabled]="(onSubmit$ | async) || !is_accept_policy">
            {{ onSubmit$.value ? "กำลังดำเนินการ.." : "สมัคร" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
