import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePipe } from './custom-date.pipe';
import { DurationTimePipe } from './duration-time.pipe';
import { DurationVideoPipe } from './duration-video.pipe';
import { TimeIntervalPipe } from './time-interval.pipe';
import { JoinArrayPipe } from './join-array.pipe';

@NgModule({
  declarations: [CustomDatePipe, DurationTimePipe, DurationVideoPipe, TimeIntervalPipe, JoinArrayPipe],
  imports: [CommonModule],
  exports: [CustomDatePipe, DurationTimePipe, DurationVideoPipe, TimeIntervalPipe, JoinArrayPipe],
})
export class PipesModule {}
