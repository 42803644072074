import { Injectable } from '@angular/core';
import { Address } from './address.model';
import { Store, StoreConfig } from '@datorama/akita';

export interface AddressState {
  list: {
    isLoading: boolean;
    error: string;
    data: Address[];
  };
  payment_type: number;
}

export const initializeState = (): AddressState => {
  return {
    list: {
      isLoading: false,
      error: null,
      data: [],
    },
    payment_type: null,
  };
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'address' })
export class AddressStore extends Store<AddressState> {
  constructor() {
    super(initializeState());
  }
}
