<div class="stat-exam">
  <div class="stat-exam-filters">
    <app-filter-exam (params)="onFilter($event)"></app-filter-exam>
  </div>

  <div class="stat-exam-result" *ngIf="summary">
    <div class="stat-exam-detail-group">
      <div class="stat-exam-detail">
        <span>จำนวนข้อสอบ</span>
        <span>{{ summary.total_exams }} ชุด</span>
      </div>
      <div class="stat-exam-detail">
        <span>คะแนนสูงสุด (%)</span>
        <span>{{ summary.highscore }}%</span>
      </div>
      <div class="stat-exam-detail">
        <span>คะแนนต่ำสุด (%)</span>
        <span>{{ summary.lowscore }}%</span>
      </div>
    </div>
    <!-- [outerStrokeColor]="color.primary"
    [innerStrokeColor]="color.primary + '26'" -->
    <circle-progress
      [class]="'test'"
      [percent]="summary.mean_score"
      [radius]="100"
      [outerStrokeWidth]="12"
      [innerStrokeWidth]="12"
      [space]="-12"
      [outerStrokeColor]="'#E50172'"
      [innerStrokeColor]="'#E5017226'"
      [animation]="false"
      [animationDuration]="300"
      [titleFontSize]="42"
      [subtitleFontSize]="16"
      [unitsFontSize]="42"
      [showUnits]="true"
      [title]="summary.mean_score"
      subtitle="คะแนนสอบเฉลี่ย"
    ></circle-progress>
    <div class="stat-exam-detail-group">
      <div class="stat-exam-detail">
        <span>เวลาทำทั้งหมด</span>
        <span>{{ summary.duration_time }}</span>
      </div>
      <div class="stat-exam-detail">
        <span>เฉลี่ยต่อข้อ (เร็วที่สุด)</span>
        <span>{{ summary.fastest_time }}</span>
      </div>
      <div class="stat-exam-detail">
        <span>เฉลี่ยต่อข้อ (ช้าที่สุด)</span>
        <span>{{ summary.slowest_time }}</span>
      </div>
    </div>
  </div>

  <app-history-exam-table
    [params]="form.value"
    (daysFilter)="updateSummary($event)"
    [findMode]="findMode"
  ></app-history-exam-table>
</div>
