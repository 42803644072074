import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { ParentCourse, StudentInCourse } from 'src/app/_store/parent-course/parent-course.model';
import { ParentCourseService } from 'src/app/_store/parent-course/parent-course.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-manage-course',
  templateUrl: './dialog-manage-course.component.html',
  styleUrls: ['./dialog-manage-course.component.scss'],
})
export class DialogManageCourseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  host = environment.apiUrl.replace('/api', '');
  studentId = new FormControl();
  amount: number;
  change_flag: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DialogManageCourseComponent>,
    private notifierService: NotifierService,
    private parentCourseService: ParentCourseService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: ParentCourse
  ) {}

  ngOnInit(): void {
    this.initDialog();
    this.initData();
    // this.getlistStudent();
    // this.amount = this.data.amount;
  }

  ngOnDestroy(): void {
    this.close();
  }

  initDialog(): void {
    this.form = this.fb.group({
      student: this.fb.array([]),
    });
  }

  initData(): void {
    this.data.student.forEach((s) => {
      if (s.student_course_id) {
        const form = this.fb.group({
          student_course_id: s.student_course_id,
          student_name: s.student_full_name,
          student_image_url: s.student_image_url,
        });
        this.studentList.push(form);
      }
    });
  }

  get studentList(): FormArray {
    return this.form.get('student') as FormArray;
  }

  removeGiveList(student: StudentInCourse, i: number): void {
    this.parentCourseService
      .cancelGive(student.student_course_id)
      .toPromise()
      .then((res) => {
        if (res) {
          this.change_flag = true;
          this.notifierService.notify('success', 'สำเร็จ!');
          this.studentList.removeAt(i);
        }
      });
  }

  close(): void {
    if (this.change_flag) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close();
    }
  }
}
