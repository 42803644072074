import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() page: number;
  @Input() totalPage: number;

  @Output() pageChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  next(): void {
    if (this.page < this.totalPage) {
      this.page++;
    }
    this.pageChange.emit(this.page);
  }

  previous(): void {
    if (this.page > 1) {
      this.page--;
    }
    this.pageChange.emit(this.page);
  }

  selected(page: number): void {
    this.page = page;
    this.pageChange.emit(page);
  }
}
