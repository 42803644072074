import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { UserService } from 'src/app/_store/user/state/user.service';
import { environment } from 'src/environments/environment';
import { UnderParentingService } from 'src/app/_store/under-parenting/under-parenting.service';
import { UnderParentingQuery } from 'src/app/_store/under-parenting/under-parenting.query';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { SetError } from 'src/app/utils/set-error.validator';
import { ParentInteractionType } from 'src/app/_store/under-parenting/under-parenting.enum';

@Component({
  selector: 'app-manage-student',
  templateUrl: './manage-student.component.html',
  styleUrls: ['./manage-student.component.scss'],
})
export class ManageStudentComponent implements OnInit, OnDestroy {
  host = environment.apiUrl.replace('/api', '');
  form: FormGroup;
  error: any;

  constructor(
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private userService: UserService,
    private underParentingService: UnderParentingService,
    public underParentingQuery: UnderParentingQuery
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.underParentingService.get().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(): void {}

  initForm(): void {
    this.form = this.fb.group({
      link: ['', Validators.required],
    });
  }

  addStudent(): void {
    this.error = {};
    if (this.form.invalid) {
      this.error = SetError(this.form);
      return;
    }

    const code = this.form.value.link;
    const value = {
      code,
      parent_interaction_type_id: ParentInteractionType.PARENTING_ID,
    };
    this.userService
      .addStudentUnderParenting(value)
      .toPromise()
      .then((res) => {
        if (res) {
          this.notifierService.notify('success', 'เพิ่มนักเรียนสำเร็จ');
          this.form.reset();
        }
      })
      .catch((err) => {
        if (err) {
          this.notifierService.notify('error', 'เพิ่มนักเรียนไม่สำเร็จ');
        }
      });
  }
}
