<div class="auth-dialog">
  <div class="auth-dialog-bg">
    <div class="bg-content">
      <div class="bg-title">{{ "AUTH.RESET_PASSWORD.TITLE" | translate }}</div>
      <div class="bg-title">{{ "APP_TITLE" | translate }}</div>
      <div class="bg-line"></div>
      <div class="bg-description">{{ "AUTH.RESET_PASSWORD.DESCRIPTION" | translate }}</div>
    </div>
  </div>
  <div class="auth-dialog-content">
    <div class="forgot-page">
      <p>{{ "AUTH.RESET_PASSWORD.SUGGESTION" | translate }}</p>
      <ng-container></ng-container>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <app-input
              label="รหัสผ่านใหม่"
              type="password"
              placeholder="รหัสผ่านใหม่"
              formControlName="password"
              [error]="error.password"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <app-input
              label="ยืนยันรหัสผ่านใหม่"
              type="password"
              placeholder="ยืนยันรหัสผ่านใหม่"
              formControlName="password_confirmation"
              [error]="error.password_confirmation"
            ></app-input>
          </div>
        </div>
      </form>
      <div class="reset-password-footer">
        <button class="btn-reset-password" (click)="changePassword()">
          {{ "AUTH.RESET_PASSWORD.CHANGE_PASSWORD" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
