import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParentProfileRoutingModule } from './parent-profile-routing.module';
import { ParentProfileComponent } from './parent-profile.component';
import { IconModule } from 'src/app/utils/icon/icon.module';
import { PersonalInfomationComponent } from './personal-infomation/personal-infomation.component';
import { PasswordComponent } from './password/password.component';
import { NotificationComponent } from './notification/notification.component';
import { ManageStudentComponent } from './manage-student/manage-student.component';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { StudentItemComponent } from './manage-student/student-item/student-item.component';
import { AddressInfoComponent } from './personal-infomation/address-info/address-info.component';

@NgModule({
  declarations: [
    ParentProfileComponent,
    PersonalInfomationComponent,
    PasswordComponent,
    NotificationComponent,
    ManageStudentComponent,
    ManageAccountComponent,
    StudentItemComponent,
    AddressInfoComponent,
  ],
  imports: [CommonModule, ParentProfileRoutingModule, IconModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class ParentProfileModule {}
