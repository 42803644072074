import { debounceTime } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { StudentExamResultService } from 'src/app/_store/student-exam-result/student-exam-result.service';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { Router } from '@angular/router';
import { StudentCourseService } from 'src/app/_store/student-course/state/student-course.service';
import { NotifierService } from 'angular-notifier';
import { StudentQuery } from 'src/app/_store/student/state/student.query';
import { ExamFilters } from '../../examination.component';
import { ActiveStatusIds } from 'src/app/_store/user/state/user.enum';

@Component({
  selector: 'app-history-exam-table',
  templateUrl: './history-exam-table.component.html',
  styleUrls: ['./history-exam-table.component.scss'],
})
export class HistoryExamTableComponent implements OnInit, OnDestroy {
  form: FormGroup;
  examStatData = [];
  curPage = 1;
  curFilter = 7;
  result;

  subscriptions: Subscription[] = [];

  @Input()
  set params(value) {
    if (!this.form) {
      this.initForm();
    }

    if (value) {
      this.exactMode(value.mode);
      this.form.patchValue(value);
    }
  }

  @Input() findMode = false;

  @Output() daysFilter = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private studentExamResultService: StudentExamResultService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private studentCourseSevice: StudentCourseService,
    private notifierService: NotifierService,
    private studentQuery: StudentQuery
  ) {}

  ngOnInit(): void {
    this.getDataTable(this.form.value);
    this.form.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe((change) => {
      if (change) {
        this.examStatData = [];
        setTimeout((_) => {
          this.spinnerService.hide();
        }, 800);
        this.getDataTable(change);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  getDataTable(params: any): void {
    this.studentExamResultService
      .getHistoryStatisticsResult(params)
      .toPromise()
      .then((res) => {
        this.result = res;
        this.curPage = res.page;
        res.data.forEach((item, index) => {
          this.examStatData.push({
            no: ++index,
            id: item.id,
            date: item.created_at,
            exam: item.exam_name,
            exam_id: item.exam_id,
            course: item.course_name ? item.course_name : 'คลังข้อสอบ',
            course_id: item.course_id,
            score: `${item.score_result}/${item.exam_full_score}`,
            total_time: this.dataTime(item.duration_time),
            pass: item.score_result / item.exam_full_score > item.exam_pass_score / 100,
            course_exam_type_id: item.course_exam_type_id,
            exam_storage_id: item.exam_storage_id,
          });
        });
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      student_id: null,
      course_id: null,
      page: 1,
      days: 7,
      exam_course: true,
      exam_storage: null,
      course_exam_type_id: null,
    });
  }

  dataTime(value: any): string {
    const duration = moment.duration(Number(value), 'seconds');
    const hours = Math.floor(duration.asHours());
    const h = ('0' + hours).slice(-3);
    const m = ('0' + duration.minutes()).slice(-2);
    const s = ('0' + duration.seconds()).slice(-2);
    return `${h}:${m}:${s}`;
  }

  dateFormat(value: any): string {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  arrayNumber(i): any {
    return new Array(i);
  }

  nextPage(): void {
    this.spinnerService.show();
    this.curPage++;
    this.form.patchValue({ page: this.curPage });
  }

  prevPage(): void {
    this.spinnerService.show();
    this.curPage--;
    this.form.patchValue({ page: this.curPage });
  }

  goToPage(i: number): void {
    this.spinnerService.show();
    this.curPage = i;
    this.form.patchValue({ page: this.curPage });
  }

  filterDays(i: number): void {
    this.spinnerService.show();
    this.curFilter = i;
    this.form.patchValue({ days: i });
    this.daysFilter.emit({ days: i });
  }

  async gotoAnalyze(value: any): Promise<void> {
    if (this.studentQuery.getStudentCurrentData.active_status_id !== ActiveStatusIds.ACTIVE) {
      return;
    }

    this.sessionStorageService.setSessionItem('exam-data');
    try {
      if (value.exam_storage_id) {
        this.sessionStorageService.updateSession({ exam_storage_id: value.exam_storage_id, history: true });
      } else {
        this.studentCourseSevice
          .isExpireCourse(value.course_id)
          .toPromise()
          .then((res) => {
            const params = {
              token: this.authService.getToken(),
              exam_id: value.exam_id,
              course_exam_type_id: value.course_exam_type_id,
              course_id: value.course_id,
              student_course_id: res.student_course_id,
              expire: res.expire,
              history: true,
            };
            this.sessionStorageService.setSession(params);
          });
      }
      this.router.navigate(['exam', value.id, 'result']);
    } catch (e) {
      this.notifierService.notify('error', e.message);
    }
  }

  exactMode(mode: number): void {
    switch (mode) {
      case ExamFilters.EXAM_COURSE:
        this.form.patchValue({
          exam_course: true,
          exam_storage: null,
        });
      case ExamFilters.EXAM_STORAGE:
        this.form.patchValue({
          exam_course: null,
          exam_storage: true,
        });
      case ExamFilters.ALL:
        this.form.patchValue({
          exam_course: null,
          exam_storage: null,
        });
    }
  }
}
