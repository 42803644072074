import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParentIndexComponent } from './parent-index.component';

const routes: Routes = [
  {
    path: '',
    component: ParentIndexComponent,
    children: [
      {
        path: 'student-info-monitor',
        loadChildren: () =>
          import('./student-info-monitor/student-info-monitor.module').then((m) => m.StudentInfoMonitorModule),
      },
      {
        path: 'course-storage',
        loadChildren: () => import('./courses-storage/courses-storage.module').then((m) => m.CoursesStorageModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./parent-profile/parent-profile.module').then((m) => m.ParentProfileModule),
      },

      { path: '', redirectTo: 'student-info-monitor', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ParentIndexRoutingModule {}
