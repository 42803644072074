import { PolicyComponent } from './policy/policy.component';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SetError } from 'src/app/utils/set-error.validator';
import { MustMatch } from 'src/app/utils/must-match.validator';
import { ValidateThaiNationalID } from 'src/app/utils/thaiNationalId-validator';
import { NotifierService } from 'angular-notifier';
import { SignUpRoleIds, AuthStateId } from 'src/app/_store/auth/state/auth.enum';
import { Registration } from 'src/app/_store/auth/state/auth.model';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { BehaviorSubject } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  image$ = new BehaviorSubject(null);

  form: FormGroup;
  error = {} as Registration;
  onSubmit$ = new BehaviorSubject<boolean>(false);

  roleId = SignUpRoleIds.STUDENT;
  SignUpRoleIds = SignUpRoleIds;
  is_accept_policy: boolean = false;

  roleList = [
    { id: SignUpRoleIds.STUDENT, name: 'ลงทะเบียนนักเรียน' },
    { id: SignUpRoleIds.PARENT, name: 'ลงทะเบียนผู้ปกครอง' },
  ];

  @Input()
  set errors(value: Registration) {
    if (value) {
      this.error = value;
      this.onSubmit$.next(false);
    }
  }

  @Output() stateChanged = new EventEmitter<AuthStateId>();
  @Output() submitted = new EventEmitter<Registration>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notifierService: NotifierService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.form.controls.image.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === 'delete!' || value === null) {
        this.error = { ...this.error, image: 'require' } as unknown as Registration;
        return;
      }
    });
  }

  ngOnDestroy(): void {
    this.image$.next(null);
  }

  initForm(): void {
    this.form = this.fb.group(
      {
        image: [null],
        first_name: [null, Validators.required],
        last_name: [null, Validators.required],
        // identification: [null, [Validators.required, ValidateThaiNationalID]],
        email: [null, [Validators.required, Validators.email]],
        phone: [null, Validators.required],
        birthdate: [null, Validators.required],
        academy: [null],
        nickname: [null],
        password: [null, Validators.required],
        password_confirmation: [null, Validators.required],
      },
      { validator: MustMatch('password', 'password_confirmation') }
    );
  }

  setValidateFormByRole(): void {
    switch (this.roleId) {
      case SignUpRoleIds.PARENT:
        // this.form.removeControl('identification');
        break;
      case SignUpRoleIds.STUDENT:
        // this.form.addControl('identification', new FormControl('', [Validators.required, ValidateThaiNationalID]));
        break;
    }
    this.form.updateValueAndValidity();
    this.form.reset();
  }

  onSelectedRole(roldId: SignUpRoleIds): void {
    this.roleId = roldId;
    this.error = {} as Registration;
    this.setValidateFormByRole();
  }

  selectImage(): void {
    document.getElementById('image-input').click();
  }

  async onSignup(): Promise<void> {
    this.error = {} as Registration;
    if (this.form.invalid) {
      this.error = SetError(this.form);
      this.notifierService.notify('error', 'กรุณากรอกข้อมูลให้ครบ');
      return;
    }
    this.onSubmit$.next(true);

    await this.authService.setRegistration({ ...this.form.value, roleId: this.roleId });
    this.submitted.emit(this.authService.getRegistration());
  }

  valueChangepPolicy(): void {
    this.is_accept_policy = !this.is_accept_policy
  }

  onReadPolicy(): void {
    const dialogRef = this.dialog.open(PolicyComponent, {
      width: '700px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(async (result) => {
    });
  }
}
