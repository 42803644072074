export const environment = {
  production: false,
  // apiUrl: 'http://localhost:3001/api',
  // apiUrl: 'http://localhost:3000/api',
  // apiUrl: 'http://192.168.1.103:3000/api',
  // apiUrl: "https://demo.wise.riseplus.tech/api"
  // apiUrl: 'http://dev.wise.riseplus.tech/api',
  // apiUrl: 'https://rboonplus.com/api',
  apiUrl: 'https://www.eazycal.in.th/api',
};

// https://rboonplus.com/api

// apiUrl: 'http://192.168.1.103:3000/api',
