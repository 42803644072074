import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AddressState, AddressStore } from './address.store';
import { Observable } from 'rxjs';
import { Address, SubDistrict } from './address.model';
import * as queryString from 'qs';
import { action } from '@datorama/akita';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AddressService {
  constructor(private addressStore: AddressStore, private http$: HttpClient) {}

  getDataByPostCode(code: number): Observable<SubDistrict[]> {
    return this.http$.get<SubDistrict[]>(`/sub_districts?post_code=${code}`);
  }

  getSubDistrict(id: number): Observable<SubDistrict> {
    return this.http$.get<SubDistrict>(`/sub_districts/${id}`);
  }

  @action('Add address')
  addAddress(payload: any): Observable<Address> {
    return this.http$.post<Address>(`/addresses`, { address: payload }).pipe(
      tap((res) => {
        if (res) {
          this.getAddresses().subscribe();
        }
      })
    );
  }

  @action('Get address')
  getAddresses(params?: any): Observable<Address[]> {
    return this.http$.get<Address[]>(`/addresses/current?${queryString.stringify(params)}`).pipe(
      tap((res) => {
        if (res) {
          this.addressStore.update((stateRes: AddressState) => ({
            ...stateRes,
            list: {
              isLoading: false,
              error: null,
              data: res,
            },
          }));
        }
      })
    );
  }

  @action('Update address')
  updateAddress(id: number, payload: any): Observable<Address> {
    return this.http$.put<Address>(`/addresses/${id}`, { address: payload }).pipe(
      tap((res) => {
        if (res) {
          this.getAddresses().subscribe();
        }
      })
    );
  }

  @action('Delete address')
  deleteAddress(id: number): Observable<Address> {
    return this.http$.delete<Address>(`/addresses/${id}`).pipe(
      tap((res) => {
        if (res) {
          this.getAddresses().subscribe();
        }
      })
    );
  }
}
