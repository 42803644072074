<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
</ng-container>

<ng-template #default>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-3 col-sm-12 input-label" [for]="name">{{ label | translate }}</label>
    <div class="col-md-9 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template  #inputContent>
  <ng-select
    [ngClass]="{ 'is-invalid': isError }"
    [items]="options$ | async"
    bindLabel="{{ bindLabel }}"
    bindValue="{{ bindValue }}"
    [formControl]="form"
    [hideSelected]="hideSelected"
    [placeholder]="placeholder"
    [loading]="isLoading"
    [typeahead]="search$"
    (change)="onSelect($event)"
    (clear)="onClear()"
    [searchable]="searchable"
    [clearable]="clearable"
    class="custom-default"
    dropdownPosition="auto"
  >
  </ng-select>
  <div class="invalid-feedback">{{ errorMessage | translate }}</div>
</ng-template>
