import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SetError } from 'src/app/utils/set-error.validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthStateId } from 'src/app/_store/auth/state/auth.enum';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { NotifierService } from 'angular-notifier';
import { DialogService } from 'src/app/services/dialog.service';
import { MustMatch } from 'src/app/utils/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  subscriptions: Subscription[] = [];
  error = {} as any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private notifierService: NotifierService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initForm();
    this.tokenRecieve();
  }

  initForm(): void {
    this.form = this.fb.group(
      {
        password: [null, Validators.required],
        password_confirmation: [null, Validators.required],
        token: [null, Validators.required],
      },
      { validator: MustMatch('password', 'password_confirmation') }
    );
  }

  tokenRecieve(): void {
    const token = this.route.snapshot.queryParams.token;

    if (token) {
      this.form.patchValue({ token: token });
    }
  }

  async changePassword() {
    this.error = {} as any;
    if (this.form.invalid) {
      this.error = SetError(this.form);
      return;
    }

    await this.dialogService.onSubmit('แน่ใจ?', 'ยืนยันการเปลี่ยนรหัสผ่าน');

    const value = this.form.value;
    this.timeout();
    this.authService
      .resetPassword(value)
      .toPromise()
      .then(async (res) => {
        this.notifierService.notify('success', 'สำเร็จ!');
        this.router.navigate(['']);
        this.submitted.emit(true);
      })
      .catch((err) => {
        if (typeof err.error.error === 'object') {
          this.error = SetError(this.form, err.error.error);
          this.notifierService.notify('error', err.errors);
        } else {
          this.router.navigate(['']);
          this.notifierService.notify('error', err.error.error);
        }
        this.submitted.emit(true);
      });
  }

  timeout() {
    return new Promise<void>((resolve) => {
      this.spinnerService.show();
      setTimeout((res) => {
        this.spinnerService.hide();
        resolve();
      }, 2000);
    });
  }
}
