<ng-container *ngIf="isActive; else">
  <div class="course-current">
    <div *ngIf="courseLasted$ | async as courseLatest">
      <div class="course-current-title">ที่เพิ่งเรียนล่าสุด</div>
      <div class="course-current-content">
        <div class="tumb-video">
          <img [src]="host + courseLatest.student_course.course_image_url" />
        </div>
        <div class="content">
          <div class="content-title">
            บท {{ courseLatest.course_chapter_group_name }} Chapter {{ courseLatest.course_chapter_name }} (ล่าสุดเมื่อ
            {{ courseLatest.updated_at | timeInterval }})
          </div>
          <div class="content-subtitle">{{ courseLatest.student_course.course_name }}</div>
          <div>สอนโดย</div>
          <div class="content-footer">
            <div class="tutor-group">
              <div class="tutor" *ngFor="let item of courseLatest.course_tutors">
                <img *ngIf="item.tutor.image_url" [src]="host + item.tutor.image_url" />
                <img *ngIf="!item.tutor.image_url" src="assets/images/default.jpg" />

                <span>{{ item.tutor.name }}</span>
              </div>
            </div>
            <div class="let-stated" *ngIf="!findMode">
              <button (click)="onRoute(courseLatest.student_course)">
                <img class="icon" src="assets/images/icon/play.svg" />เริ่มเรียนต่อ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-title">คอร์สเรียนของฉัน</div>

  <div class="search">
    <app-search
      [searchTextPlacholder]="'ค้นหาใน &#8220;คอร์สเรียนของฉัน&#8221;'"
      [filterCourseDisable]="true"
      (searchChange)="onSearch($event)"
    ></app-search>
  </div>

  <div class="course-group">
    <ng-container *ngFor="let item of studentCourse; index as i">
      <div class="course-group-list">
        <div class="course-box" *ngIf="item.is_activated" (click)="onRoute(item)">
          <div class="course-item" [ngClass]="{ parent: findMode }">
            <img [alt]="'course-' + i" [src]="host + item.course_image_url" />
            <div class="course-detail">
              <div class="detail-title">{{ item.course_name }}</div>

              <div class="detail-line"></div>
              <div class="detail-footer">
                <div class="content">
                  <div class="content-title">ความคืบหน้า</div>
                  <div class="content-progress">
                    <mat-progress-bar
                      *ngIf="item.progress <= 34"
                      class="success"
                      mode="determinate"
                      [value]="item.progress"
                    ></mat-progress-bar>
                    <mat-progress-bar
                      *ngIf="item.progress > 34 && item.progress <= 68"
                      class="warning"
                      mode="determinate"
                      [value]="item.progress"
                    ></mat-progress-bar>
                    <mat-progress-bar
                      *ngIf="item.progress > 68"
                      class="danger"
                      mode="determinate"
                      [value]="item.progress"
                    ></mat-progress-bar>
                  </div>

                  <div class="content-description">
                    <div>เวลาทั้งหมด</div>
                    <div>{{ item.expire_duration_video | durationVideo }}</div>
                  </div>
                  <div class="content-description">
                    <div>เวลาที่ใช้ไป</div>
                    <div>{{ item.duration_spent | durationVideo }}</div>
                  </div>
                  <div class="content-description">
                    <div>วันหมดอายุ</div>
                    <div>{{ item.expire_date ? (item.expire_date | customDate) : "ไม่จำกัด" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="course-box" *ngIf="!item.is_activated && !findMode">
          <div class="course-item">
            <img [alt]="'course-' + i" [src]="host + item.course_image_url" />
            <div class="course-detail">
              <div class="detail-title">{{ item.course_name }}</div>
              <div class="detail-line"></div>
              <div class="detail-footer">
                <div class="course-open" (click)="openCourse(item)">เปิดใช้งานคอร์สเรียน</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="col-lg-12" *ngIf="studentCourse.length === 0">
      <div class="load-more">ไม่พบรายการ</div>
    </div>
  </div>

  <div class="load-more" *ngIf="isLoadMore" (click)="loadMore()">
    <button>ดูเพิ่มเติม</button>
  </div>
</ng-container>

<ng-container *ngIf="!isActive">
  <div class="text-empty">ยังไม่มีใช้งานได้ขณะนี้ กรุณาติดต่อแอดมิน</div>
</ng-container>
