import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuList, MenuParentConfig } from 'src/app/config/layouts/menu.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CartQuery } from 'src/app/_store/cart/cart.query';
import { LogoConfig } from 'src/app/_models/layout.model';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-parent',
  templateUrl: './header-parent.component.html',
  styleUrls: ['./header-parent.component.scss'],
})
export class HeaderParentComponent implements OnInit {
  menuList$ = new BehaviorSubject<MenuList[]>([]);
  isExceptDesktop: boolean;
  screenWidth: number;
  config: LogoConfig;

  host = environment.apiUrl.replace('/api', '');

  constructor(
    private deviceDetectService: DeviceDetectorService,
    public cartQuery: CartQuery,
    private layoutConfigService: LayoutConfigService
  ) {}

  ngOnInit(): void {
    this.initLayout();
    this.menuList$.next(MenuParentConfig);
    this.isExceptDesktop = !this.deviceDetectService.isDesktop();
    this.onResize();
  }

  initLayout(): void {
    this.config = this.layoutConfigService.getSavedConfig('config.logo_pic');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
