<ng-container [formGroup]="form">
  <div class="content-header">
    <div class="title">จัดการบัญชี</div>
    <div class="btn-group">
      <button class="btn btn-light">ยกเลิก</button>
      <button class="btn btn-primary btn-submit">บันทึกการเปลี่ยนแปลง</button>
    </div>
  </div>
  <div class="content-body">
    <div class="form">
      <div class="row">
        <app-input class="col-md-12 mb-3" formControlName="uid" label="UID" [isDisabled]="true"></app-input>
        <!-- <app-input class="col-md-6 mb-3" formControlName="username" label="ชื่อผู้ใช้งาน"></app-input> -->
        <app-input class="col-md-12 mb-3" formControlName="email" label="อีเมล" [isDisabled]="true"></app-input>
        <!-- <p class="email-confirmation">
          การเปลี่ยนแปลงที่อยู่อีเมลจำเป็นจะต้องมีการยืนยันอีเมลใหม่ เพื่อให้แน่ใจว่าที่อยู่อีเมลใหม่ของคุณถูกต้องกรุณา
          ตรวจสอบความถูกต้องก่อนบันทึกการเปลี่ยนแปลง
        </p> -->
      </div>
    </div>
    <hr />
    <div class="connection">
      <div class="title mb-3">การเชื่อมต่อ</div>
      <div class="social">
        <span class="btn facebook-btn">
          <img src="assets/images/social/f_logo_RGB-White_250.png" class="img-social" alt="social-facebook" />
          Facebook
        </span>
        <div class="connected">
          เชื่อมต่อแล้ว <strong> &lt;NAME&gt; • </strong><span class="cancel-connection"> ยกเลิกการเชื่อมต่อ</span>
        </div>
        <div class="btn connect" [hidden]="true">เชื่อมต่อ</div>
      </div>
      <div class="social">
        <span class="btn google-btn">
          <img src="assets/images/social/google.svg" class="img-social" alt="social-google" />
          Google
        </span>
        <div class="connected" [hidden]="true">
          เชื่อมต่อแล้ว <strong> &lt;NAME&gt; • </strong><span class="cancel-connection"> ยกเลิกการเชื่อมต่อ</span>
        </div>
        <div class="btn connect">เชื่อมต่อ</div>
      </div>
    </div>
  </div>
</ng-container>
