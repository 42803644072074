import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParentingInteraction, UnderParenting } from 'src/app/_store/under-parenting/under-parenting.model';
import { UnderParentingService } from 'src/app/_store/under-parenting/under-parenting.service';
import { DialogService } from 'src/app/services/dialog.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotifierService } from 'angular-notifier';
import { ParentInteractionType } from 'src/app/_store/under-parenting/under-parenting.enum';

@Component({
  selector: 'app-student-item',
  templateUrl: './student-item.component.html',
  styleUrls: ['./student-item.component.scss'],
})
export class StudentItemComponent implements OnInit, OnDestroy {
  @Input() data: UnderParenting;
  host = environment.apiUrl.replace('/api', '');

  constructor(
    private underParentingService: UnderParentingService,
    private dialogService: DialogService,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async disconnect(): Promise<void> {
    await this.dialogService.onSubmit('ยืนยันหรือไม่?', 'ยืนยันการยกเลิกการเชื่อมต่อ');
    const value = ({
      parent_interaction_type_id: ParentInteractionType.CANCEl_ID,
      student_id: this.data.student_id,
    } as unknown) as ParentingInteraction;
    this.underParentingService
      .remove(value)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.notifierService.notify('success', 'ยกเลิกเชื่อมต่อสำเร็จ');
        }
      });
  }
}
