import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CountdownComponent, CountdownItem } from 'ngx-countdown';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountDownComponent implements OnInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  // tslint:disable: variable-name
  @Input()
  set datatime(data) {
    if (data) {
      this.config = { leftTime: data };
    }
  }

  @Output() timesUp = new EventEmitter<any>();

  form: FormControl = new FormControl('');
  config = {};
  data: any;

  constructor() {}

  ngOnInit(): void {}

  triggerEvent(event: any): void {
    if (event.action === 'done') {
      this.timesUp.emit(event);
    }
  }

  getTimeLeft(): CountdownItem {
    this.countdown.resume();
    return this.countdown.i;
  }
}
