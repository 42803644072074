import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'students',
    loadChildren: () => import('./students/students.module').then((m) => m.StudentsModule),
  },
  {
    path: 'parents',
    loadChildren: () => import('./parents/parents.module').then((m) => m.ParentsModule),
  },
  {
    path: 'exam',
    loadChildren: () => import('./exams/exams.module').then((m) => m.ExamsModule),
  },
  { path: 'cart', loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule) },
  { path: 'my-order', loadChildren: () => import('./my-order/my-order.module').then((m) => m.MyOrderModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
