<label *ngIf="label">{{ label }}</label>

<textarea
  [ngClass]="isError ? 'invalid' : ''"
  type="text"
  [name]="name"
  [formControl]="form"
  class="form-control"
  [placeholder]="placeholder"
  tabindex="-1"
  [maxlength]="maxlength"
></textarea>
<span class="text-danger error-message">{{ errorMessage }}</span>
<span *ngIf="maxlength" class="d-flex align-items-end flex-column">{{ countText }} / {{ maxlength }}</span>
