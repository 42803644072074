<div class="stat-exam-table" *ngIf="result">
  <div class="stat-exam-header">
    <div class="stat-exam-title">ประวัติการสอบ</div>
    <div class="stat-exam-filters">
      <button class="filters-day active" [ngClass]="{ active: curFilter === 7 }" (click)="filterDays(7)">7 วัน</button>
      <button class="filters-day" [ngClass]="{ active: curFilter === 30 }" (click)="filterDays(30)">30 วัน</button>
      <button class="filters-day" [ngClass]="{ active: curFilter === 50000 }" (click)="filterDays(50000)">
        ทั้งหมด
      </button>
    </div>
  </div>
  <div class="exam-history">
    <div class="stat-exam-table">
      <table>
        <tr>
          <th>#</th>
          <th>วันที่/เวลา</th>
          <th class="text-left">ข้อสอบ</th>
          <th class="text-left">คอร์สเรียน</th>
          <th>คะแนนที่ได้</th>
          <th>เวลารวม (ชม.)</th>
          <th>ผล</th>
        </tr>
        <ng-container *ngIf="examStatData; else no_data">
          <ng-container *ngFor="let data of examStatData; index as i">
            <tr>
              <td>{{ data.no }}</td>
              <td>{{ data.date | date: "d/MM/yyyy HH:mm:ss" }}</td>
              <td class="text-left">
                <!-- <span class="link">{{ data.exam }}</span> -->
                <span class="link" (click)="gotoAnalyze(data)">{{ data.exam }}</span>
              </td>
              <td class="text-left" [ngClass]="{ 'exam-storage': !data.course_id }">
                {{ data.course }}
              </td>
              <td>{{ data.score }}</td>
              <td>{{ data.total_time }}</td>
              <td>
                <span class="badge pass" *ngIf="data.pass">ผ่าน</span>
                <span class="badge redo" *ngIf="!data.pass">ทำใหม่</span>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container>
          <ng-template #no_data> ไม่มีข้อมูล </ng-template>
        </ng-container>
      </table>
    </div>

    <div class="stat-exam-table-footer">
      <div class="paginator" *ngIf="curPage !== 1" (click)="prevPage()">
        <feather-icon class="icon" name="chevron-left"></feather-icon>
      </div>
      <ng-container *ngFor="let page of arrayNumber(result.total_page); index as i">
        <div class="page-number" *ngIf="curPage" [ngClass]="{ active: curPage === i + 1 }" (click)="goToPage(i + 1)">
          {{ i + 1 }}
        </div>
      </ng-container>
      <div class="paginator" *ngIf="curPage < result.total_page" (click)="nextPage()">
        <feather-icon class="icon" name="chevron-right"></feather-icon>
      </div>
    </div>
  </div>
</div>
