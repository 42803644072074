import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { MustMatch } from 'src/app/utils/must-match.validator';
import { SetError } from 'src/app/utils/set-error.validator';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { UserQuery } from 'src/app/_store/user/state/user.query';
import { UserService } from 'src/app/_store/user/state/user.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  form: FormGroup;
  error: any = {};
  loading = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private notifierService: NotifierService,
    private authService: AuthService,
    private userQuery: UserQuery,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group(
      {
        old_password: [null, Validators.required],
        new_password: [null, Validators.required],
        new_password_confirmation: [null, Validators.required],
      },
      { validator: MustMatch('new_password', 'new_password_confirmation') }
    );
  }

  async resetRequest(): Promise<void> {
    // this.notifierService.notify('default', 'ระบบยังไม่พร้อมใช้งาน');
    await this.timeout();
    const email = this.userQuery.getCurrent.email;
    this.authService
      .fotgotPassword(email)
      .toPromise()
      .then(async (res) => {
        this.notifierService.notify('success', 'สำเร็จ!');
      })
      .catch(async (err) => {
        this.notifierService.notify('error', err.error.errors);
      });
  }

  async changeRequest(): Promise<void> {
    this.error = {};
    if (this.form.invalid) {
      this.error = SetError(this.form);
      return;
    }

    this.loading = true;

    try {
      const value = this.form.value;
      await this.userService.changePassword(value).toPromise();
      this.notifierService.notify('success', 'เปลี่ยนรหัสผ่านสำเร็จ');
      this.resetForm();
    } catch (e) {
      this.showError(e.error.code);
    }
    this.loading = false;
  }

  resetForm(): void {
    this.form.patchValue({ old_password: '', new_password: '', new_password_confirmation: '' });
  }

  showError(code: string): void {
    switch (code) {
      case 'CP03':
        this.notifierService.notify('error', 'ไม่สามารถใช้รหัสผ่านใหม่ที่เหมือนกับรหัสผ่านเก่า');
        break;
      case 'CP04':
        this.notifierService.notify('error', 'รหัสผ่านเก่าไม่ถูกต้อง');
        break;
      default:
        this.notifierService.notify('error', 'มีข้อผิดพลาดกรุณาตรวจสอบ');
    }
  }

  timeout() {
    return new Promise<void>((resolve) => {
      this.spinnerService.show();
      setTimeout((res) => {
        this.spinnerService.hide();
        resolve();
      }, 5000);
    });
  }
}
