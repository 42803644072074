<div class="dialog-page" [formGroup]="form">
  <div class="dialog-title">ให้คอร์เรียน</div>
  <div class="dialog-amoung-course">
    จำนวนคอร์ส <span>{{ amount }}</span>
  </div>

  <ng-container *ngIf="getGaveList">
    <ng-container *ngFor="let student of getGaveList.controls; index as i">
      <div>ผู้เรียนที่ {{ i + 1 }}</div>
      <div class="student-item">
        <div class="student-item-content">
          <img *ngIf="!student.value.student_image_url" src="/assets/images/default.jpg" />
          <img *ngIf="student.value.student_image_url" [src]="host + student.value.student_image_url" />
          <div class="student-detail">
            <div>{{ student.value.student_name }}</div>
            <div class="role">นักเรียน</div>
          </div>
        </div>
        <div class="item-action" (click)="removeGaveList(i)">ลบออก</div>
      </div>
      <div class="devide-line-default"></div>
    </ng-container>
  </ng-container>

  <div class="student-select-input">
    <app-select
      class="input-item"
      placeholder="เลือกนักเรียน"
      [formControl]="studentId"
      [options]="studentList"
    ></app-select>
    <button class="btn btn-choose" (click)="giveCourse()" [disabled]="!studentId.value || amount === 0">เลือก</button>
  </div>
  <div class="devide-line-default"></div>
  <div class="dialog-footer">
    <div class="btn btn-cancel" (click)="close()">ยกเลิก</div>
    <button class="btn btn-active" (click)="confirm()" [disabled]="getGaveList.length === 0">ให้คอร์สเรียน</button>
  </div>
</div>
