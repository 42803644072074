import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddressService } from 'src/app/_store/address/address.service';
import { Address } from 'src/app/_store/address/address.model';
import { SetError } from 'src/app/utils/set-error.validator';
import { NotifierService } from 'angular-notifier';
import { AddressQuery } from 'src/app/_store/address/address.query';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-into.component.scss'],
})
export class AddressInfoComponent implements OnInit, OnDestroy {
  form: FormGroup;
  error: any = {};
  isCreate = false;

  isUpdateArr: boolean[] = [];
  addresssesForm: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    private addressService: AddressService,
    private addressQuery: AddressQuery,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initAddressesForm();
    this.subscriptions.push(this.getAddresses(), this.formChange());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  getAddresses(): Subscription {
    return this.addressService.getAddresses().subscribe((res: Address[]) => {
      if (res) {
        res.forEach((val) => {
          this.addValueAddress(val);
        });
      }
    });
  }

  formChange(): Subscription {
    return (
      this.form.controls.province_id.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((val) => {
        this.form.patchValue({ district_id: null });
      }),
      this.form.controls.district_id.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((val) => {
        this.form.patchValue({ sub_district_id: null });
      }),
      this.form.controls.sub_district_id.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((val) => {
          if (val) {
            this.addressService
              .getSubDistrict(val)
              .toPromise()
              .then((res) => {
                if (res) {
                  this.form.patchValue({ post_code: res.post_code });
                }
              });
          } else {
            this.form.patchValue({ post_code: null });
          }
        })
    );
  }

  /**
   *
   *
   * @InitForm
   */
  initForm(): void {
    this.form = this.formField();
  }

  initAddressesForm(): void {
    this.addresssesForm = this.fb.group({
      addresses: this.fb.array([]),
    });
  }

  get addressArray(): FormArray {
    return this.addresssesForm.get('addresses') as FormArray;
  }

  addItem(item: Address): void {
    const length = this.addressArray.length;
    this.addressArray.push(this.formField());
    this.addressArray.controls[length].patchValue(item);
  }
  removeItem(index: number): void {
    this.addressArray.removeAt(index);
  }

  formField(): FormGroup {
    return this.fb.group({
      id: [null],
      name: [null, Validators.required],
      address: [null, Validators.required],
      province_id: [null, Validators.required],
      district_id: [null, Validators.required],
      sub_district_id: [null, Validators.required],
      post_code: [null, Validators.required],
    });
  }

  addValueAddress(val: Address): void {
    this.isUpdateArr.push(false);
    const address = {
      ...val,
      province_id: val.province.id,
      district_id: val.district.id,
      post_code: val.sub_district.post_code,
    } as Address;
    this.addItem(address);
  }

  /**
   *
   *
   * @Action
   */
  onEdit(index: number): void {
    this.isUpdateArr[index] = true;
  }

  async onDelete(index: number): Promise<void> {
    await this.dialogService.onSubmit(null, 'ยืนยันการลบที่อยู่');

    const value = this.addressArray.controls[index].value;
    this.addressService
      .deleteAddress(value.id)
      .toPromise()
      .then((res) => {
        this.notifierService.notify('success', 'บันทึกข้อมูลสำเร็จ');
        this.isUpdateArr.splice(index, 1);
        this.removeItem(index);
      })
      .catch((err) => {
        this.notifierService.notify('error', 'มีข้อผิดพลาดกรุณาตรวจสอบ');
        console.error(err);
      });
  }

  onCancel(index: number): void {
    const address = this.addressQuery.getListData;
    const value = {
      ...address[index],
      province_id: address[index].province.id,
      district_id: address[index].district.id,
      post_code: address[index].sub_district.post_code,
    };
    this.isUpdateArr[index] = false;
    this.addressArray.controls[index].patchValue(value);
  }

  async update(index: number): Promise<void> {
    if (this.addressArray.controls[index].invalid) {
      return;
    }
    const value = this.addressArray.controls[index].value;
    this.addressService
      .updateAddress(value.id, value)
      .toPromise()
      .then((res) => {
        if (res) {
          this.notifierService.notify('success', 'บันทึกข้อมูลสำเร็จ');
          this.isUpdateArr[index] = false;
        }
      })
      .catch((err) => {
        this.notifierService.notify('error', 'มีข้อผิดพลาดกรุณาตรวจสอบ');
        console.error(err);
      });
  }

  async add(): Promise<void> {
    this.error = {};
    if (this.form.invalid) {
      this.error = SetError(this.form);
      return;
    }

    const value = this.form.value;

    this.addressService
      .addAddress(value)
      .toPromise()
      .then((res) => {
        if (res) {
          this.addValueAddress(res);
          this.notifierService.notify('success', 'บันทึกข้อมูลสำเร็จ');
          this.form.reset();
          this.isCreate = false;
        }
      })
      .catch((err) => {
        this.notifierService.notify('error', 'มีข้อผิดพลาดกรุณาตรวจสอบ');
        console.error(err);
      });
  }
}
