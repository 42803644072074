import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FacebookService, UIParams, UIResponse, InitParams } from 'ngx-facebook';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { BehaviorSubject } from 'rxjs';
import { Config } from 'src/app/_models/layout.model';
import { environment } from 'src/environments/environment';
import { AppFacebookService } from 'src/app/services/facebook.service';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent implements OnInit {
  @ViewChild(MatTooltip) myTooltip;
  config$ = new BehaviorSubject<Config>(null);
  apiUrl = environment.apiUrl.replace('/api', '');

  constructor(
    private fb: FacebookService,
    private layoutConfigService: LayoutConfigService,
    private appFacebookService: AppFacebookService,
    private clipboard: Clipboard
  ) {
    const initParams: InitParams = {
      appId: '1497364580661825',
      version: 'v10.0',
    };

    fb.init(initParams);
  }

  ngOnInit(): void {
    this.initLayout();
  }

  initLayout(): void {
    this.config$.next(this.layoutConfigService.getSavedConfig('config'));
  }

  shareFacebook(): void {
    this.appFacebookService.setFacebookTags(
      // window.location.origin,
      'https://www.eazycal.in.th',
      'EazyCal The easy way of Calculus',
      'คําตอบสุดท้ายที่จะช่วยให้น้องผ่านแคลคูลัส ทดลองได้ด้วยตัวคุณเอง',
      '/assets/images/icon/512.png'
      // this.config$.value.landing_page.banner.title,
      // this.config$.value.landing_page.banner.description,
      // this.apiUrl + this.config$.value.logo_pic.logo
    );
    // const title = this.config$.value.landing_page.banner.title;
    // console.log(title);

    const params: UIParams = {
      method: 'share_open_graph',
      action_type: 'og.shares',
      action_properties: JSON.stringify({
        object: {
          // 'og:url': window.location.origin,
          'og:url': 'https://www.eazycal.in.th',
          'og:title': 'EazyCal The easy way of Calculus',
          'og:description': 'คําตอบสุดท้ายที่จะช่วยให้น้องผ่านแคลคูลัส ทดลองได้ด้วยตัวคุณเอง',
          'og:image': '/assets/images/icon/512.png'
          // 'og:url': 'https://www.eazycal.in.th',
          // 'og:title': this.config$.value.landing_page.banner.title,
          // 'og:description': this.config$.value.landing_page.banner.description,
          // 'og:image': this.apiUrl + this.config$.value.logo_pic.logo,
        },
      }),
    };

    console.log(window.location.origin);

    this.fb
      .ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.error(e));
  }

  onYoutube():void {
    window.open("https://www.youtube.com/user/toeybj008", '_blank');
  }

  showTooltip() {
    this.myTooltip.show();
    let object = window.location.origin
    console.log(object);
    this.clipboard.copy(object);
  }

  onFacebook():void {
    window.open("http://facebook.com/eazycal", '_blank');
  }

  onLine():void {
    window.open("https://line.me/R/ti/p/@eazycal", '_blank');
  }
}
