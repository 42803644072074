import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CourseCategoryStore } from './course-category.store';
import { CourseCategory } from './course-category.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { resetStores } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class CourseCategoryService {
  constructor(private courseCategoryStore: CourseCategoryStore, private http: HttpClient) {}

  get(): Observable<CourseCategory[]> {
    return this.http.get<CourseCategory[]>('/course_categories').pipe(
      tap((entities) => {
        this.courseCategoryStore.set(entities);
      })
    );
  }

  find(id: number): Observable<CourseCategory> {
    return this.http.get<CourseCategory>(`/course_categories/${id}`).pipe(
      tap((res) => {
        this.courseCategoryStore.update({ find: res });
      })
    );
  }

  clearState(): void {
    this.courseCategoryStore.update((state) => ({
      ...state,
      find: null,
    }));
  }
}
