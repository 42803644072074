import { Injectable } from '@angular/core';
import { Student, StudentCourseLatest } from './student.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface StudentState extends EntityState<Student> {
  current: Student;
  find: Student;
  courseLasted: StudentCourseLatest;
}

export function createInitialState(): StudentState {
  return {
    current: null,
    find: null,
    courseLasted: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'student' })
export class StudentStore extends EntityStore<StudentState> {
  constructor() {
    super(createInitialState());
  }
}
