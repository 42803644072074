import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { TranslationService } from '../services/translation.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_store/auth/state/auth.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  apiUrl = '';
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private authService: AuthService
  ) {}

  getApiUrl(): string {
    if (this.apiUrl) {
      return this.apiUrl;
    }
    this.apiUrl = environment.apiUrl;
    return this.apiUrl;
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return req.clone({ setHeaders: { Authorization: token } });
    } else {
      return req.clone({});
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/assets/') > -1) {
      return next.handle(req);
    }
    if (req.url.indexOf('http') > -1) {
      return next.handle(req);
    }

    const url = this.getApiUrl();
    const reqUrl = (url + req.url).replace('/api/api', '/api');

    req = req.clone({
      url: reqUrl,
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Set-Cookie': 'flavor=choco; SameSite=None; Secure',
      },
    });
    return next.handle(this.addToken(req, this.authService.getToken())).pipe(
      map((event: any) => {
        return event;
      }),
      catchError((err, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            console.log('Auth Error');
            this.authService.signout();
            this.router.navigateByUrl('/');
          }
          return throwError(err);
        }
      })
    );
  }
}
