import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MenuList, MenuConfig } from 'src/app/config/layouts/menu.config';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { LogoConfig } from 'src/app/_models/layout.model';
import { Auth } from 'src/app/_store/auth/state/auth.model';
import { AuthQuery } from 'src/app/_store/auth/state/auth.query';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { CartQuery } from 'src/app/_store/cart/cart.query';
import { CartService } from 'src/app/_store/cart/cart.service';
import { User } from 'src/app/_store/user/state/user.model';
import { UserQuery } from 'src/app/_store/user/state/user.query';
import { UserService } from 'src/app/_store/user/state/user.service';
import { environment } from 'src/environments/environment';
import { RoleIds } from 'src/app/_store/user/state/user.enum';
import { BlogServiceCategoryID } from 'src/app/services/blog.service';
import { Router } from '@angular/router';
import { CourseCategoryService } from 'src/app/_store/course-category/state/course-category.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  user$: Observable<User>;
  RoleIds = RoleIds;
  menuList$ = new BehaviorSubject<MenuList[]>([]);
  host = environment.apiUrl.replace('/api', '');
  config: LogoConfig;
  submenu_check: boolean = false
  Blog_Categories: any;
  isShowMenu = false;
  subscriptions: Subscription[] = [];

  isShowCourseCategory: boolean = false;
  isShowBlogCategory: boolean = false;

  course_categories: any = [];

  constructor(
    public cartQuery: CartQuery,
    private authService: AuthService,
    private cartService: CartService,
    private authQuery: AuthQuery,
    private layoutConfigService: LayoutConfigService,
    private userService: UserService,
    private userQuery: UserQuery,
    private BlogService: BlogServiceCategoryID,
    private route: Router,
    private courseCategoriesService: CourseCategoryService,
  ) { }

  ngOnInit(): void {
    this.menuList$.next(MenuConfig);
    this.initLayout();
    this.initUser();
    this.initAuth();
    // this.cartService.get().subscribe((res) => {
    //   if (res) {
    //     this.cdr.detectChanges();
    //   }
    // });
    this.getCourseCategories();
    this.getAllCategoryBlog();
  }
  toggle_submenu() {
    // this.submenu_check = !this.submenu_check;
  }

  ngOnDestroy(): void {
    // this.renderer.removeClass(document.body, 'hidden-scroll');
  }

  initLayout(): void {
    this.config = this.layoutConfigService.getSavedConfig('config.logo_pic');
  }

  initAuth(): void {
    const isAuthenticate$ = this.authQuery.selectIsAuthenticate$;
    isAuthenticate$.pipe(untilDestroyed(this)).subscribe((isAuth) => {
      this.isAuthenticated = isAuth;
      this.loadUser();
    });
  }

  initUser(): void {
    this.user$ = this.userQuery.current$;
  }

  loadUser(): void {
    if (this.isAuthenticated) {
      this.userService.getCurrent().subscribe();
    }
  }

  getCourseCategories() {
    const idOrder = [8, 6, 7, 9, 10];
    this.subscriptions.push(
      this.courseCategoriesService.get().subscribe(response => {
        response.sort((a: any, b: any) => idOrder.indexOf(a.id) - idOrder.indexOf(b.id));
        this.course_categories = response;
      })
    )
  }

  getAllCategoryBlog() {
    this.subscriptions.push(
      this.BlogService.getAllCategoryBlog().subscribe(response => {
        this.Blog_Categories = response;
        // console.log(this.Blog_Categories);

      })
    )
  }
  sendSelectedItemAndNavigate(selectedItem: any) {
    const selectedData = selectedItem;


    this.BlogService.setData(selectedData);
    this.route.navigate(['/blogs'])

  }
  toggleShowMenu(): void {
    this.isShowMenu = !this.isShowMenu;

    setTimeout((_) => {
      if (this.isShowMenu) {
        // document.body.classList.add('hidden-scroll');
        // this.renderer.addClass(document.body, 'hidden-scroll');
      } else {
        // this.renderer.removeClass(document.body, 'hidden-scroll');
      }
    });
  }

  closeMenu(): void {
    this.isShowMenu = false;
    this.isShowCourseCategory = false;
    this.isShowBlogCategory = false;
    // this.renderer.removeClass(document.body, 'hidden-scroll');
  }
}
