import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.scss'],
})
export class DialogsComponent implements OnInit {
  form: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<DialogsComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {}

  ngOnInit(): void {
    this.initDialog();

    if (this.data) {
      this.form.patchValue(this.data);
    }
  }

  initDialog(): void {
    this.form = this.fb.group({
      title: ['Are you sure?'],
      content: ['Dialog test!!'],
      decisionBtn: [false],
      closeBtn: [false],
    });
  }

  // tslint:disable-next-line: typedef
  get dialogValue() {
    return this.form.value;
  }

  close(): void {
    this.dialogRef.close();
  }
}
