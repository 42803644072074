import { Injectable } from '@angular/core';
import { action, ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ParentCourseStore } from './parent-course.store';
import { GiveCouse, ParentCourse } from './parent-course.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as queryString from 'qs';

@Injectable({ providedIn: 'root' })
export class ParentCourseService {
  constructor(private parentCourseStore: ParentCourseStore, private http: HttpClient) {}

  @action('Get Parent course')
  get(params?): Observable<ParentCourse[]> {
    return this.http.get<ParentCourse[]>(`/parent_courses?${queryString.stringify(params)}`).pipe(
      tap((entities: ParentCourse[]) => {
        this.parentCourseStore.update({ list: entities });
      })
    );
  }

  @action('Get list parenting student course')
  getListUnderParenting(params?): Observable<ParentCourse[]> {
    return this.http.get<ParentCourse[]>(`/parent_courses/student?${queryString.stringify(params)}`);
  }

  giveCourse(payload: GiveCouse): Observable<GiveCouse> {
    return this.http.post<GiveCouse>('/give_courses', { give_course: payload });
  }

  cancelGive(id: number): Observable<ParentCourse> {
    return this.http.delete<ParentCourse>(`/parent_courses/cancel_give/${id}`);
  }
}
