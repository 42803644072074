<ng-container [ngSwitch]="pattern">
  <ng-container *ngSwitchCase="'withAvatar'">
    <div class="preview" (click)="onClick()">
      <ng-container [ngTemplateOutlet]="input"></ng-container>
      <img [src]="imageUrl$ | async" class="img-responsive img-fit-cover" *ngIf="imageUrl$ | async" />
      <img *ngIf="!(imageUrl$ | async)" src="assets/images/default.jpg" />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'withName'">
    <ng-container [ngTemplateOutlet]="input"></ng-container>
    <div class="preview" (click)="onClick()">
      <div class="btn select-image" [ngClass]="{ invalid: isError }">เลือกไฟล์รูปภาพ</div>
    </div>
    <div class="image-name">
      <i-feather name="paperclip" class="icon"></i-feather>
      {{ imageName$ | async }}
    </div>
    <div class="invalid-feedback">{{ errorMessage | translate }}</div>
  </ng-container>

  <!-- <ng-container *ngSwitchCase="'withSign'">
    <div class="preview" (click)="onClick()">
      <ng-container [ngTemplateOutlet]="input"></ng-container>
      <img [src]="imageUrl$ | async" class="img-responsive img-fit-cover" *ngIf="imageUrl$ | async" />
      <div *ngIf="!(imageUrl$ | async)">
        <div>
          <ii-feather class="la la-cloud-upload"></ii-feather>
        </div>
        <div>
          {{ "Upload image" }}
        </div>
      </div>
    </div>
    <span *ngIf="(imageUrl$ | async) && removable" class="remove-image-icon" (click)="removeImage()">
      <i-feather class="fa fa-times-circle text-danger"></i-feather>
    </span>
  </ng-container> -->
</ng-container>

<ng-template #input>
  <input
    #imageInput
    id="image-input"
    type="file"
    name="image"
    accept="image/*"
    [hidden]="true"
    (change)="processFile(imageInput)"
  />
</ng-template>
