import { Component, OnInit, Input, forwardRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LabelMode } from '../_enums/label-mode.enum';
import { GenerateRandom } from 'src/app/utils/rendom';
import { ErrorMessage } from 'src/app/utils/error-message';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    this.disabled = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  @Input()
  set textGroup(value: string) {
    this.isInputGroup = true;
    this.inputGroupMessage = value;
  }

  @Input()
  set iconGroup(value: string) {
    this.isInputGroupIcon = true;
    this.inputGroupIcon = value;
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() passwordToggle: boolean = false;
  @Input() showPassword: boolean = false;
  @Input() readonly = false;
  @Input() label: string;
  @Input() negativeNumber = false;
  @Input() customMask: string;
  @Input() max: number;
  @Input() min: number;

  @Input()
  set customNgClass(value: 'signup' | 'default') {
    if (!value) {
      return;
    }
    if (typeof value === 'object') {
      const obj = value as object;
      for (const key in obj) {
        if (obj[key]) {
          this.customInputClass += ` ${key}`;
        }
      }
    }
    switch (value) {
      case 'signup':
        this.customInputClass = 'signup';
        this.customFormClass = 'form-shadow';
        break;
      case 'default':
        this.customInputClass = null;
        this.customFormClass = 'form-border';
        break;
      default:
        if (typeof value !== 'object') {
          this.customInputClass = value;
        }
    }
  }

  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }
  @Input() valueType: 'string' | 'number' = 'string';
  @Input() icon: string;
  @Input() isPhone: boolean;
  @Input() customPattern: any;

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();
  isInputGroup = false;
  isInputGroupIcon = false;
  inputGroupMessage = '';
  inputGroupIcon = '';
  subscription: Subscription;
  errorMessage = '';
  isRequired = false;
  isError = false;
  // customInputClass: any = {
  //   active: false,
  //   wrong: false,
  //   correct: false,
  // };
  customInputClass = '';
  customFormClass = 'form-border';
  disabled = false;

  onChange = (value: string | number) => {};
  onTouched = (value: string) => {};

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      this.setTrimString();
      this.setMaximum();
      this.setMinimum();

      this.onChange(this.setValueType(this.form.value));
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  /**
   *
   * @Validate
   */

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isMaximum(value: number): boolean {
    return this.max && value > this.max;
  }

  isMinimum(value: number): boolean {
    return this.min && value < this.min;
  }

  isNotNull(): boolean {
    return this.form.value;
  }

  /**
   *
   * @SetValue
   */

  setTrimString(): void {
    if (this.isString(this.form.value)) {
      // this.form.patchValue(this.form.value.trim());
    }
  }

  setMaximum(): void {
    if (this.isNotNull() && this.isMaximum(this.form.value)) {
      this.form.patchValue(this.max);
    }
  }

  setMinimum(): void {
    if (this.isNotNull() && this.isMinimum(this.form.value)) {
      this.form.patchValue(this.min);
    }
  }

  setValueType(value: string): string | number {
    switch (this.valueType) {
      case 'string':
        return String(value);
      case 'number':
        return Number(value);
    }
  }

  toggleView(): void {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
