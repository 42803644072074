<div class="address">
  <div class="title mb-3">ที่อยู่</div>

  <ng-container *ngIf="isCreate">
    <div class="create-box">
      <form [formGroup]="form">
        <app-input
          label="ชื่อที่อยู่"
          formControlName="name"
          placeholder="บ้าน/โรงเรียน"
          [error]="error?.name"
        ></app-input>

        <app-textarea
          label="ที่อยู่"
          formControlName="address"
          placeholder="123 หมู่บ้าน หมู่ ถนน"
          autocomplete="shipping street-address"
          [error]="error?.address"
          [maxlength]="500"
        ></app-textarea>

        <div class="row">
          <app-select
            class="col-md-4"
            label="จังหวัด"
            formControlName="province_id"
            placeholder="จังหวัด"
            autocomplete="shipping locality"
            bindLabel="name_th"
            url="provinces"
            [error]="error?.province_id"
          ></app-select>

          <app-select
            class="col-md-4"
            label="อำเภอ/เขต"
            formControlName="district_id"
            placeholder="อำเภอ/เขต"
            autocomplete="shipping region"
            bindLabel="name_th"
            url="districts"
            [params]="{ province_id: form.value.province_id }"
            [isDisabled]="!form.value.province_id"
            [error]="error?.district_id"
          ></app-select>

          <app-select
            class="col-md-4"
            label="ตำบล/แขวง"
            formControlName="sub_district_id"
            placeholder="ตำบล/แขวง"
            bindLabel="name_th"
            url="sub_districts"
            [params]="{ district_id: form.value.district_id }"
            autocomplete="shipping sub-district"
            [isDisabled]="!form.value.district_id"
            [error]="error?.sub_district_id"
          ></app-select>
        </div>

        <app-input
          label="รหัสไปรษณีย์"
          formControlName="post_code"
          placeholder="รหัสไปรษณีย์"
          customMask="00000"
          autocomplete="shipping postal-code"
          [error]="error?.post_code"
        ></app-input>
      </form>

      <div class="action-box">
        <button class="btn btn-primary" (click)="isCreate = !isCreate">ยกเลิก</button>
        <button class="btn btn-light ml-2" (click)="add()">เพิ่ม</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isCreate">
    <div class="btn btn-address" (click)="isCreate = !isCreate">
      <feather-icon name="plus" class="icon"></feather-icon>
      <span>เพิ่มที่อยู่</span>
    </div>
  </ng-container>

  <hr />

  <ng-container [formGroup]="addresssesForm">
    <ng-container formArrayName="addresses" *ngFor="let address of addressArray.controls; let i = index">
      <div [formGroupName]="i">
        <div class="create-box" *ngIf="!isUpdateArr[i]">
          <div class="update-panel">
            <div class="header-panel">
              <h4>{{ address.controls.name.value }}</h4>
              <div class="actions">
                <feather-icon name="edit" class="action-icon primary" (click)="onEdit(i)"></feather-icon>
                <feather-icon name="trash" class="action-icon danger" (click)="onDelete(i)"></feather-icon>
              </div>
            </div>

            <div class="details">
              <p>{{ address.controls.address.value }}</p>
            </div>
          </div>
        </div>

        <div class="create-box" *ngIf="isUpdateArr[i]">
          <ng-container>
            <app-input
              label="ชื่อที่อยู่"
              formControlName="name"
              placeholder="บ้าน/โรงเรียน"
              [error]="address.controls.name.errors"
            ></app-input>

            <app-textarea
              label="ที่อยู่"
              formControlName="address"
              placeholder="123 หมู่บ้าน หมู่ ถนน"
              autocomplete="shipping street-address"
              [error]="address.controls.address.errors"
              [maxlength]="500"
            ></app-textarea>

            <div class="row">
              <app-select
                class="col-md-4"
                label="จังหวัด"
                formControlName="province_id"
                placeholder="จังหวัด"
                autocomplete="shipping locality"
                bindLabel="name_th"
                url="provinces"
                [error]="address.controls.province_id.errors"
              ></app-select>

              <app-select
                class="col-md-4"
                label="อำเภอ/เขต"
                formControlName="district_id"
                placeholder="อำเภอ/เขต"
                autocomplete="shipping region"
                bindLabel="name_th"
                url="districts"
                [params]="{ province_id: address.value.province_id }"
                [isDisabled]="!address.value.province_id"
                [error]="address.controls.district_id.errors"
              ></app-select>

              <app-select
                class="col-md-4"
                label="ตำบล/แขวง"
                formControlName="sub_district_id"
                placeholder="ตำบล/แขวง"
                bindLabel="name_th"
                url="sub_districts"
                [params]="{ district_id: address.value.district_id }"
                autocomplete="shipping sub-district"
                [isDisabled]="!address.value.district_id"
                [error]="address.controls.sub_district_id.errors"
              ></app-select>
            </div>

            <app-input
              label="รหัสไปรษณีย์"
              formControlName="post_code"
              placeholder="รหัสไปรษณีย์"
              customMask="00000"
              autocomplete="shipping postal-code"
              [error]="address.controls.post_code.errors"
            ></app-input>
          </ng-container>

          <div class="action-box">
            <button class="btn btn-primary" (click)="onCancel(i)">ยกเลิก</button>
            <button class="btn btn-light ml-2" (click)="update(i)">แก้ไข</button>
          </div>
        </div>

        <hr />
      </div>
    </ng-container>
  </ng-container>
</div>
