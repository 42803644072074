<div class="dialog-page" [formGroup]="form">
  <div class="dialog-title">จัดการคอร์สนักเรียน</div>
  <div class="dialog-course-unactivated">คอร์สที่ยังไม่เปิดใช้งาน</div>

  <ng-container *ngIf="studentList">
    <ng-container *ngFor="let student of studentList.controls; index as i">
      <div>ผู้เรียนที่ {{ i + 1 }}</div>
      <div class="student-item">
        <div class="student-item-content">
          <img *ngIf="!student.value.student_image_url" src="/assets/images/default.jpg" />
          <img *ngIf="student.value.student_image_url" [src]="host + student.value.student_image_url" />
          <div class="student-detail">
            <div>{{ student.value.student_name }}</div>
            <div class="role">นักเรียน</div>
          </div>
        </div>
        <div class="item-action" (click)="removeGiveList(student.value, i)">ลบออก</div>
      </div>
      <div class="devide-line-default"></div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="studentList.length === 0"> ไม่มีข้อมูล </ng-container>

  <div class="dialog-footer">
    <div class="btn btn-cancel" (click)="close()">ปิด</div>
  </div>
</div>
