<div class="menu">
  <div class="filter">
    <div class="filter-title">
      <feather-icon name="sliders" class="icon"></feather-icon>
      <span>ตัวกรอง</span>
    </div>
  </div>
  <div class="filter">
    <app-radio-button [formControl]="mode" [options]="modeOptions" labelMode="noLabel"></app-radio-button>
  </div>

  <ng-container *ngIf="modeValue === modeExam.EXAM_COURSE" [formGroup]="form">
    <div class="filter">
      <div class="filter-select">
        <app-select
          formControlName="course_exam_type_id"
          placeholder="เทอมที่เรียน"
          url="course_exam_types"
        ></app-select>
      </div>
    </div>
  </ng-container>

  <div class="filter">
    <span class="clear-filter" (click)="clearParams()">ล้างตัวกรอง</span>
  </div>
</div>
