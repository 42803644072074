import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './headers/header/header.component';
import { FooterComponent } from './footers/footer/footer.component';
import { FooterLicenceComponent } from './footers/footer-licence/footer-licence.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { IconModule } from '../utils/icon/icon.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthModule } from '../auth/auth.module';
import { HeaderStudentComponent } from './headers/header-student/header-student.component';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderMobileComponent } from './headers/header/header-mobile/header-mobile.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderStudentMobileComponent } from './headers/header-student/header-student-mobile/header-student-mobile.component';
import { UserAuthComponent } from './user-auth/user-auth.component';
import { HeaderExamComponent } from './headers/header-exam/header-exam.component';
import { HeaderParentComponent } from './headers/header-parent/header-parent.component';
import { HeaderParentMobileComponent } from './headers/header-parent/header-parent-mobile/header-parent-mobile.component';
import { CartComponent } from './cart/cart.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderExamComponent,
    FooterComponent,
    FooterLicenceComponent,
    ScrollTopComponent,
    NavBarComponent,
    SocialMediaComponent,
    UserAuthComponent,
    BreadcrumbComponent,
    HeaderStudentComponent,
    HeaderMobileComponent,
    HeaderStudentMobileComponent,
    HeaderParentComponent,
    HeaderParentMobileComponent,
    CartComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
    AuthModule,
    MatMenuModule,
    SharedModule,
    MatTooltipModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FooterLicenceComponent,
    ScrollTopComponent,
    BreadcrumbComponent,
    HeaderStudentComponent,
    HeaderParentComponent,
    HeaderExamComponent,
  ],
})
export class LayoutsModule {}
