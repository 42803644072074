import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { StudentCourseService } from 'src/app/_store/student-course/state/student-course.service';
import { StudentExamResultParams } from 'src/app/_store/student-exam-result/student-exam-result.model';
import { Student } from 'src/app/_store/student/state/student.model';
import { StudentService } from 'src/app/_store/student/state/student.service';
import { UnderParenting } from 'src/app/_store/under-parenting/under-parenting.model';
import { UnderParentingService } from 'src/app/_store/under-parenting/under-parenting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-info-monitor',
  templateUrl: './student-info-monitor.component.html',
  styleUrls: ['./student-info-monitor.component.scss'],
})
export class StudentInfoMonitorComponent implements OnInit, OnDestroy {
  host = environment.apiUrl.replace('/api', '');
  form: FormGroup;
  tabIndex = 1;
  params: StudentExamResultParams;

  tabStatistics = [
    {
      id: 1,
      name: 'ความคืบหน้า',
      path: '/parents/student-info-monitor/',
    },
    {
      id: 2,
      name: 'เวลาเรียน',
      path: '/parents/student-info-monitor/',
    },
    {
      id: 3,
      name: 'การสอบ',
      path: '/parents/student-info-monitor/',
    },
  ];

  courseFilterOptions = [];

  studentList = [];
  student$: Observable<Student>;

  constructor(
    private fb: FormBuilder,
    private underParentingService: UnderParentingService,
    private spinnerService: NgxSpinnerService,
    private studentService: StudentService,
    private studentCourseService: StudentCourseService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.initForm();
    this.getlistStudent();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.spinnerService.show();
        setTimeout((_) => {
          this.spinnerService.hide();
        }, 500);
        if (res.course_id === 0) {
          this.params = { ...this.params, course_id: null };
        } else {
          this.params = { ...this.params, ...res };
        }
      }
    });
  }

  ngOnDestroy(): void {}

  initForm(): void {
    this.form = this.fb.group({
      student_id: [null],
      course_id: [null],
    });
  }

  getlistStudent(): void {
    this.underParentingService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe((res: UnderParenting[]) => {
        if (res) {
          setTimeout((_) => {
            this.spinnerService.hide();
          }, 800);
          const data = res.map((item) => {
            return { student_id: item.student.id, name: item.student.full_name };
          });
          this.studentList = this.studentList.concat(data);

          this.getStudent(this.studentList[0]);
        }
      });
  }

  getStudent(value): void {
    if (value) {
      this.spinnerService.show();
      this.studentService
        .getByID(value.student_id)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (res) {
            setTimeout((_) => {
              this.spinnerService.hide();
            }, 800);
            this.student$ = of(res);
            this.form.patchValue({ student_id: res.id, course_id: 0 });
            // User id user for find examination statistic
            this.params = this.form.value;
            this.getCourseList(res.id);
          }
        });
    }
  }

  getCourseList(student_id: number): void {
    this.courseFilterOptions = [
      {
        id: 0,
        name: 'ทั้งหมด',
      },
    ];
    this.courseFilterOptions.splice(1, this.courseFilterOptions.length);
    this.studentCourseService
      .getSelectCourseList({ student_id, is_activated: true })
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          setTimeout((_) => {
            this.spinnerService.hide();
          }, 800);
          const data = res.map((item) => {
            return { id: item.course_id, name: item.course_name };
          });
          this.courseFilterOptions = this.courseFilterOptions.concat(data);
        }
      });
  }

  changeTab(i: number): void {
    this.tabIndex = i;
  }
}
