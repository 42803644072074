<div class="header">
  <!-- <app-header-mobile *ngIf="isExceptDesktop"></app-header-mobile> -->
  <app-header-mobile></app-header-mobile>
  <div class="contrainer">
    <div class="brand">
      <img src="./assets/images/icon/LOGO_EAZY_CAL_Final-01.svg" class="logo" routerLink="/" />
    </div>

    <div class="nav-bar">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>
</div>