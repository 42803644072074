import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStore } from './auth.store';
import { Auth, Login, Registration } from './auth.model';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignUpRoleIds } from './auth.enum';
import { action } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class AuthService {
  registration$ = new BehaviorSubject<Registration>(null);
  login$ = new BehaviorSubject<Login>(null);
  currentToken: string;

  constructor(private authStore: AuthStore, private http: HttpClient) {
    this.currentToken = localStorage.getItem('token');
    this.authStore.update({ isAuthenticate: this.isAuthenticated() });
  }

  setRegistration(value: Registration): void {
    this.registration$.next(value);
  }

  getRegistration(): Registration {
    return this.registration$.value;
  }

  getLogin(): Login {
    return this.login$.value;
  }

  setLogin(value: Login): void {
    this.login$.next(value);
  }

  getToken(): string {
    return this.currentToken;
  }

  isAuthenticated(): boolean {
    return this.currentToken ? true : false;
  }

  setToken(token: string): void {
    this.currentToken = token;
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }

  @action('Signin')
  signin(payload: { email: string; password: string }): Observable<Auth> {
    return this.http.post('/sessions', payload).pipe(
      tap((response: Auth) => {
        this.setToken(response.access_token);
        this.authStore.update({ isAuthenticate: true });
      })
    );
  }

  @action('Signout')
  signout(): void {
    this.authStore.update({ isAuthenticate: false });
    this.setToken(null);
  }

  singup(payload: Registration): Observable<Registration> {
    const formData = new FormData();
    switch (payload.roleId) {
      case SignUpRoleIds.STUDENT:
        for (const key in payload) {
          if (payload[key]) {
            formData.append(`student[${key}]`, payload[key]);
          }
        }
        return this.http.post<Registration>('/students', formData);
      case SignUpRoleIds.PARENT:
        for (const key in payload) {
          if (payload[key]) {
            formData.append(`parent[${key}]`, payload[key]);
          }
        }
        return this.http.post<Registration>('/parents', formData);
    }
  }

  verifyUser(token: string): Observable<any> {
    return this.http.post(`/${'activate_users/activate'}`, { token });
  }

  resentVerifyUser(email: string): Observable<any> {
    return this.http.post(`/${'activate_users/resent_activate'}`, { email });
  }

  fotgotPassword(email: string): Observable<any> {
    return this.http.post(`/${'forgot_passwords'}`, { email });
  }

  resetPassword(value: any): Observable<any> {
    return this.http.post(`/${'forgot_passwords/reset_password'}`, value);
  }
}
