import { VerifyId } from './../verify-email/verify-email.component';
import { CartService } from 'src/app/_store/cart/cart.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { AuthComponent } from '../../auth.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthStateId } from 'src/app/_store/auth/state/auth.enum';
import { AuthService } from 'src/app/_store/auth/state/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  @Output() stateChanged = new EventEmitter<AuthStateId>();
  @Output() submitted = new EventEmitter();
  @Output() verifyStatus = new EventEmitter<VerifyId>();

  form: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private authService: AuthService,
    private cartService: CartService,
    private dialogRef: MatDialogRef<AuthComponent>
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  initForm(): void {
    this.form = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  onSignup(): void {
    this.stateChanged.emit(AuthStateId.SIGN_UP);
  }

  onWarning(): void {
    this.notifierService.notify('default', 'ระบบยังไม่พร้อมใช้งาน');
  }

  async onSignin(): Promise<void> {
    if (this.form.invalid) {
      this.notifierService.notify('error', 'กรุณากรอกอีเมลและรหัสผ่าน');
      return;
    }

    try {
      const user = await this.authService.signin(this.form.value).toPromise();
      this.notifierService.notify('success', 'ยินดีต้อนรับเข้าสู่ระบบ');
      // this.dialogRef.close(true);
      this.dialogRef.close(user);
    } catch (err) {
      switch (err.error.errors) {
        case 'Please wait for approve.':
          this.verifyStatus.emit(VerifyId.RESENT);
          this.stateChanged.emit(AuthStateId.VERIFY);
          this.authService.setLogin(this.form.value);
          break;
        default:
          this.notifierService.notify('error', err.error.errors);
      }
    }
  }

  forgotPassword() {
    this.stateChanged.emit(AuthStateId.FORGOT_PASSWORD);
  }
}
