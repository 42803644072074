import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StudentQuery } from 'src/app/_store/student/state/student.query';
import { StudentService } from 'src/app/_store/student/state/student.service';
import { NotifierService } from 'angular-notifier';
import { UserService } from 'src/app/_store/user/state/user.service';
import { UserQuery } from 'src/app/_store/user/state/user.query';
import { User } from 'src/app/_store/user/state/user.model';

@Component({
  selector: 'app-personal-infomation',
  templateUrl: './personal-infomation.component.html',
  styleUrls: ['./personal-infomation.component.scss'],
})
export class PersonalInfomationComponent implements OnInit, OnDestroy {
  image$ = new BehaviorSubject(null);

  sideTabIndex = 1;
  host = environment.apiUrl.replace('/api', '');
  form: FormGroup;

  subscriptions: Subscription[] = [];
  loading = false;
  now = new Date();

  constructor(
    private userService: UserService,
    private userQuery: UserQuery,
    private fb: FormBuilder,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.userService.getCurrent().subscribe((res: User) => {
      if (res) {
        this.form.patchValue(res.data);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this.image$.next(null);
  }

  initForm(): void {
    this.form = this.fb.group({
      id: [null],
      image: [null],
      image_url: [null],
      first_name: [null],
      last_name: [null],
      phone: [null],
    });
  }

  onClickInterested(form: AbstractControl): void {
    let value = form.value;
    value = !value;
    form.patchValue(value);
  }

  selectImage(): void {
    document.getElementById('image-input').click();
  }

  async saveChange(): Promise<void> {
    const value = this.form.value;
    this.loading = true;

    try {
      await this.userService.updateParent(value).toPromise();
      this.userService.getCurrent().subscribe();
      this.notifierService.notify('success', 'บันทึกข้อมูลสำเร็จ');
    } catch (error) {
      this.notifierService.notify('error', 'มีข้อผิดพลาดกรุณาตรวจสอบ');
    }

    this.loading = false;
  }

  cancel(): void {
    const value = this.userQuery.getCurrent;
    this.form.reset();
    setTimeout((_) => {
      this.form.patchValue(value);
    });
  }
}
