import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

class MetaTag {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppFacebookService {
  private urlMeta = 'og:url';
  private titleMeta = 'og:title';
  private descriptionMeta = 'og:description';
  private imageMeta = 'og:image';

  constructor(private metaService: Meta) {}

  public setFacebookTags(url: string, title: string, description: string, image: string): void {
    const tags = [
      new MetaTag(this.urlMeta, url),
      new MetaTag(this.titleMeta, title),
      new MetaTag(this.descriptionMeta, description),
      new MetaTag(this.imageMeta, image),
    ];
    this.setTags(tags);
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach((siteTag) => {
      this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
    });
  }
}
