import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DatatableStudentActivityHistory,
  ParamsStudentActivityHistory,
  Student,
  StudentResultLesson,
} from 'src/app/_store/student/state/student.model';
import { StudentService } from 'src/app/_store/student/state/student.service';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

interface ChartDuratoinLesson {
  name: string;
  series: {
    name: string;
    value: number;
  }[];
}

@Component({
  selector: 'app-study-time',
  templateUrl: './study-time.component.html',
  styleUrls: ['./study-time.component.scss'],
})
export class StudyTimeComponent implements OnInit, OnDestroy {
  @Input()
  set params(value: { student_id: number; course_id: number }) {
    if (value) {
      this.studentId = value.student_id;
      this.ngOnInit();
    }
  }

  @Input() findMode = false;

  constructor(private studentService: StudentService, private cdr: ChangeDetectorRef) {}

  colorScheme1 = {
    domain: ['#018BD4'],
  };
  colorScheme2 = {
    domain: ['#018BD4', '#78B72A', '#FED500'],
  };

  singledata = [
    {
      name: '00',
      value: 0,
    },
    {
      name: '01',
      value: 0,
    },
    {
      name: '02',
      value: 0,
    },
    {
      name: '03',
      value: 0,
    },
    {
      name: '04',
      value: 0,
    },
    {
      name: '05',
      value: 0,
    },
    {
      name: '06',
      value: 0,
    },
    {
      name: '07',
      value: 0,
    },
    {
      name: '08',
      value: 60,
    },
    {
      name: '09',
      value: 60,
    },
    {
      name: '10',
      value: 60,
    },
    {
      name: '11',
      value: 32,
    },
    {
      name: '12',
      value: 0,
    },
    {
      name: '13',
      value: 0,
    },
    {
      name: '14',
      value: 30,
    },
    {
      name: '15',
      value: 0,
    },
    {
      name: '16',
      value: 0,
    },
    {
      name: '17',
      value: 0,
    },
    {
      name: '18',
      value: 0,
    },
    {
      name: '19',
      value: 30,
    },
    {
      name: '20',
      value: 0,
    },
    {
      name: '21',
      value: 30,
    },
    {
      name: '22',
      value: 60,
    },
    {
      name: '23',
      value: 60,
    },
  ];

  multiData = [];

  filterBackDateList = [
    { name: '7 วัน', back_date: 7 },
    { name: '30 วัน', back_date: 30 },
    { name: 'ทั้งหมด', back_date: null },
  ];

  activityHistory$: Observable<DatatableStudentActivityHistory>;
  resultLesson$: Observable<StudentResultLesson>;

  chartDurationLessonList: ChartDuratoinLesson[] = [];

  studentId: number;
  page = 1;
  limit = 10;
  indexFIlterActivityHistory = 0;
  indexFIlterResultLesson = 0;
  indexFIlterDuratoinLesson = 0;

  async ngOnInit(): Promise<void> {
    this.onFilterActivityHistory(this.indexFIlterActivityHistory);
    this.onFilterResultLesson(this.indexFIlterResultLesson);
    await this.onFilterDurationLesson(this.indexFIlterDuratoinLesson);
    this.cdr.detectChanges();
    this.multiData = [{ name: 'test', series: [{ name: 'A', value: 5 }] }];
  }

  ngOnDestroy(): void {}

  loadActivityHistory(params: { page: number; back_date?: number }): Observable<DatatableStudentActivityHistory> {
    return this.studentService.getActivityHostory(params, this.studentId);
  }

  onPageActivityHistoryChange(page: number): void {
    this.page = page;
    this.activityHistory$ = this.loadActivityHistory(this.paramActivityHistory);
  }

  /**
   *
   * @Action
   */

  onFilterActivityHistory(index: number): void {
    this.indexFIlterActivityHistory = index;
    this.activityHistory$ = this.loadActivityHistory(this.paramActivityHistory);
  }

  get paramActivityHistory(): ParamsStudentActivityHistory {
    return {
      page: this.page,
      back_date: this.filterBackDateList[this.indexFIlterActivityHistory].back_date,
    };
  }

  onFilterResultLesson(index: number): void {
    this.indexFIlterResultLesson = index;
    const back_date = this.filterBackDateList[this.indexFIlterActivityHistory].back_date;
    this.resultLesson$ = this.studentService.getResultLesson({ back_date }, this.studentId);
  }

  async onFilterDurationLesson(index: number): Promise<void> {
    this.indexFIlterDuratoinLesson = index;
    const back_date = this.filterBackDateList[this.indexFIlterDuratoinLesson].back_date;
    const durationLessonList = await this.studentService.getDurationLesson({ back_date }, this.studentId).toPromise();
    const results = [];
    for (const durationLesson of durationLessonList) {
      results.push({
        name: moment(new Date(durationLesson.created_at)).add(543, 'year').format('DD/MM/YYYY'),
        series: [
          { name: 'เวลาที่เรียนรวม', value: this.getTime(durationLesson.total_duration) },
          { name: 'เวลาเรียนต่อเนื่องสูงสุด', value: this.getTime(durationLesson.max) },
          { name: 'เวลาเรียนต่อเนื่องต่ำสุด', value: this.getTime(durationLesson.min) },
        ],
      });
    }

    this.chartDurationLessonList = [...results];
  }

  getTime(time: number): number {
    return Number((time / 3600.0).toFixed(2));
  }
}
