<div class="header-mobile">
  <div class="nav-bar">
    <img *ngIf="!isShowMenu" alt="menu-list" src="assets/images/icon/list.svg" (click)="toggleShowMenu()" />
    <img
      *ngIf="isShowMenu"
      class="list-close"
      alt="menu-list-close"
      src="assets/images/icon/x.svg"
      (click)="toggleShowMenu()"
    />
  </div>
  <div class="brand">
    <!-- <div class="logo" routerLink="/parents" (click)="closeMenu()"></div> -->
    <img src="./assets/images/icon/LOGO_EAZY_CAL_Final-01.png" class="logo" routerLink="/" />
    <!-- <img class="logo" [src]="host + config.logo_2x" routerLink="/parents" (click)="closeMenu()" /> -->
    <div class="parent-site">
      <div class="icon">
        <img src="assets/images/icon/home.svg" alt="home-icon" />
      </div>
      <div class="content-text">{{ "LAYOUTS.HEADERS.HEADER_PARENT.CLASSROOM" | translate }}</div>
    </div>
  </div>
  <app-user-auth [isStudentSite]="true" [isMobile]="true"></app-user-auth>
</div>
<ng-container [ngTemplateOutlet]="menuNav"></ng-container>

  <ng-template #menuNav>
    <div class="menu-nav" [ngClass]="isShowMenu ? 'show' : ''">
      <a class="menu-item" *ngFor="let menu of menuList$ | async">
        <div class="menu-content" (click)="selectMenu(menu)" [ngClass]="{ submenu: menu.toggle }">
          <span>{{ menu.name | translate }}</span>
          <div class="sub-icon" *ngIf="menu.isRoot">
            <feather-icon [name]="!menu.toggle ? 'chevron-down' : 'chevron-up'" style="transition: 0.3s"></feather-icon>
          </div>
        </div>

        <div *ngIf="menu.toggle" class="menu-submenu">
          <a class="menu-item" *ngFor="let subMenu of menu?.subMenuList">
            <div class="menu-content" (click)="selectMenu(subMenu)" [ngClass]="{ submenu: subMenu.toggle }">
              <span class="sub-menu-text">{{ subMenu.name | translate }}</span>
              <div class="sub-icon" *ngIf="subMenu.isRoot">
                <feather-icon [name]="!subMenu.toggle ? 'chevron-down' : 'chevron-up'" style="transition: 0.3s"></feather-icon>
              </div>
            </div>

            <div *ngIf="subMenu.toggle" class="menu-submenu">
              <div class="submenu" *ngFor="let subMenu2 of subMenu?.subMenuList" (click)="selectMenu(subMenu2)">
                <feather-icon name="chevron-right" class="icon"></feather-icon>
                <span class="sub2-menu-text">{{ subMenu2.name }}</span>
              </div>
            </div>
          </a>
        </div>
      </a>

      <div class="menu-shared">
        <app-social-media></app-social-media>
      </div>
    </div>
  </ng-template>

