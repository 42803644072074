import { Injectable } from '@angular/core';
import { UnderParenting } from './under-parenting.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface UnderParentingState extends EntityState<UnderParenting> {
  list: UnderParenting[];
}

export function createInitialState(): UnderParentingState {
  return {
    list: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'under_parenting' })
export class UnderParentingStore extends EntityStore<UnderParentingState> {
  constructor() {
    super(createInitialState());
  }
}
