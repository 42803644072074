import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { AuthStateId } from '../_store/auth/state/auth.enum';
import { Registration } from '../_store/auth/state/auth.model';
import { AuthService } from '../_store/auth/state/auth.service';
import { VerifyId } from './components/verify-email/verify-email.component';

export interface DialogParams {
  stateId: AuthStateId;
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  STATE = AuthStateId;
  verifyStatus = VerifyId.WATING;
  currentAuthStateId: AuthStateId = AuthStateId.SIGN_IN;
  failure: Registration;
  spinnerService: any;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<AuthComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { stateId: AuthStateId }
  ) {}

  ngOnInit(): void {
    if (this.data.stateId) {
      this.onStageChanged(this.data.stateId);
    }
  }

  onStageChanged(stateId: AuthStateId): void {
    this.currentAuthStateId = stateId;
  }

  async onSubmit(): Promise<void> {
    const value = this.authService.getRegistration();

    try {
      await this.authService.singup(value).toPromise();
      this.notifierService.notify('success', 'สมัครบัญชีสำเร็จ');
      this.authService.setLogin(value);
      this.verifyStatus = VerifyId.RESENT;
      this.onStageChanged(AuthStateId.VERIFY);
    } catch (errors) {
      this.failure = errors.error;
      this.notifierService.notify('error', 'มีข้อผิดพลาด กรุณาตรวจสอบ');
    }
  }

  changeVerifyStatus(value: VerifyId): void {
    this.verifyStatus = value;
  }

  close(): void {
    this.dialogRef.close();
  }
}
