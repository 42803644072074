<div class="header">
  <div class="contrainer">
    <div class="menu-back">
      <a href="javascript:;" (click)="getBack()" class="text-primary">
        {{ "LAYOUTS.HEADERS.HEADER_EXAM.BACKWARD" | translate }}</a
      >
    </div>
    <div class="brand">
      <!-- <div class="logo" routerLink="/"></div> -->
      <img src="./assets/images/icon/LOGO_EAZY_CAL_Final-01.png" class="logo" routerLink="/" />
      <!-- <img [src]="host + config.logo_2x" class="logo" routerLink="/" /> -->
    </div>

    <div class="nav-bar">
      <app-user-auth></app-user-auth>
    </div>
  </div>
</div>
