<ng-container [ngSwitch]="pattern">
  <ng-container *ngSwitchCase="'withIcon'">
    <div class="form-group">
      <label>{{ label }}</label>
      <div class="input-group input-date">
        <img src="assets/images/icon/calendar.svg" />
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'withoutIcon'">
    <div class="input-date" [ngClass]="{ invalid: isError }">
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>
    <div class="invalid-feedback">{{ errorMessage | translate }}</div>
  </ng-container>
</ng-container>

<ng-template #input>
  <input
    [id]="name"
    [name]="name"
    [matDatepicker]="picker"
    [placeholder]="placeholder | translate"
    [formControl]="form"
    [max]="maxDate"
    (click)="picker.open()"
    (keydown)="onKeyDown($event)"
  />
  <mat-datepicker [scrollStrategy]="scrollStrategy" #picker></mat-datepicker>
  <span class="text-danger error-message">{{ errorMessage | translate }}</span>
</ng-template>
