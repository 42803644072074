import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesStorageRoutingModule } from './courses-storage-routing.module';
import { CoursesStorageComponent } from './courses-storage.component';
import { DialogGiveCourseComponent } from './dialog-give-course/dialog-give-course.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogManageCourseComponent } from './dialog-manage-course/dialog-manage-course.component';

@NgModule({
  declarations: [CoursesStorageComponent, DialogGiveCourseComponent, DialogManageCourseComponent],
  imports: [CommonModule, CoursesStorageRoutingModule, MatDialogModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class CoursesStorageModule {}
