import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserStore, UserState } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends QueryEntity<UserState> {
  current$ = this.select('current');

  constructor(protected store: UserStore) {
    super(store);
  }

  get getCurrent() {
    return this.store.getValue().current;
  }
}
