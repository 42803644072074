import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { StudentCourse, StudentCourseParams } from 'src/app/_store/student-course/state/student-course.model';
import { StudentCourseQuery } from 'src/app/_store/student-course/state/student-course.query';
import { StudentCourseService } from 'src/app/_store/student-course/state/student-course.service';
import { Student, StudentCourseLatest } from 'src/app/_store/student/state/student.model';
import { StudentQuery } from 'src/app/_store/student/state/student.query';
import { StudentService } from 'src/app/_store/student/state/student.service';
import { ActiveStatusIds } from 'src/app/_store/user/state/user.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Input()
  set find(value: Observable<Student>) {
    if (value) {
      this.findMode = true;
      this.student$ = value;
      this.student$.pipe(untilDestroyed(this)).subscribe((res) => {
        if (!res) {
          return;
        }

        this.initCourseLasted(res.id);
        this.isLoading = true;
        this.studentCourse = [];
        this.params = { ...this.params, student_id: res.id, is_activated: true };
        this.studentCourseService.getStudentCourseList(this.params).subscribe();
      });
    }
  }

  @Input() findMode = false;
  student$: Observable<Student>;
  courseLasted$: Observable<StudentCourseLatest>;
  studentCourse: StudentCourse[] = [];

  searchForm = new FormControl();

  host = environment.apiUrl.replace('/api', '');
  params: StudentCourseParams = {
    limit: 8,
    offset: 0,
  } as StudentCourseParams;
  isLoadMore = false;
  isLoading = true;
  isActive = false;

  subscriptions: Subscription[] = [];
  ActiveStatusIds = ActiveStatusIds;

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private studentQuery: StudentQuery,
    private studentService: StudentService,
    private studentCourseService: StudentCourseService,
    private studentCourseQuery: StudentCourseQuery,
    private notifierService: NotifierService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.findMode) {
      this.initStudent();
      this.initCourseLasted();
    }
    this.initStudentCourse();
  }

  ngOnDestroy(): void {}

  /**
   *
   *
   * @InitValue
   */
  initStudentCourse(): void {
    const studentCourse$ = this.studentCourseQuery.selectAll();
    studentCourse$.pipe(untilDestroyed(this), skip(1)).subscribe((res) => {
      if (!this.isLoading) {
        return;
      }
      this.studentCourse = [...this.studentCourse, ...res];

      this.isLoadMore = res.length < this.params.limit ? false : true;
      this.isLoading = false;
    });
    // Loading
    this.studentCourseLoading();
  }

  studentCourseLoading(): void {
    const isLoading$ = this.studentCourseQuery.selectLoading();
    isLoading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      if (loading) {
        this.spinnerService.show('primary');
      } else {
        this.spinnerService.hide('primary', 500);
      }
    });
  }

  initStudent(): void {
    this.student$ = this.studentQuery.current$;
    this.student$.pipe(untilDestroyed(this)).subscribe((res: Student) => {
      if (!res) {
        return;
      }

      if (res.active_status_id === ActiveStatusIds.ACTIVE || res.active_status_id === ActiveStatusIds.REGISTERED) {
        this.isActive = true;
      }
      this.isLoading = true;
      this.studentCourse = [];
      this.params = { ...this.params, student_id: res.id };
      this.studentCourseService.getStudentCourseList(this.params).subscribe();
    });
  }

  initCourseLasted(studentId?: number): void {
    this.courseLasted$ = this.studentQuery.courseLasted$;
    if (!this.findMode) {
      this.studentService.getCourseLasted().subscribe();
    } else {
      this.studentService.getCourseLastedById(studentId).subscribe();
    }
  }

  onSearch(params): void {
    this.isLoading = true;
    this.params = { ...this.params, ...params, offset: 0 };
    this.studentCourse = [];
    this.studentCourseService.getStudentCourseList(this.params).subscribe();
  }

  /**
   *
   *
   * @Action
   */
  loadMore(): void {
    this.isLoading = true;
    this.params = { ...this.params, offset: this.params.offset + this.params.limit };
    this.studentCourseService.getStudentCourseList(this.params).subscribe();
  }

  onRoute(item: StudentCourse): void {
    if (this.findMode) {
      return;
    }
    if (item.is_expire) {
      this.notifierService.notify('error', 'เวลาเรียนของท่านหมดลงแล้ว');
      return;
    }
    this.router.navigate(['students', item.id, 'watch']);
  }

  async openCourse(item: StudentCourse): Promise<void> {
    await this.dialogService.onSubmit(null, 'ยืนยันการเปิดใช้งาน');
    const activated = await this.studentCourseService.activatedCourse(item.id).toPromise();
    if (activated) {
      this.params = { ...this.params, offset: 0 };
      this.studentCourseService
        .getStudentCourseList(this.params)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (res) {
            this.studentCourse = res;
          }
        });
    }
  }
}
