<div class="content-header">
  <div class="title">จัดการข้อมูลส่วนตัว</div>
  <div class="btn-group-custom">
    <button class="btn btn-light" (click)="cancel()" [disabled]="loading">ยกเลิก</button>
    <button class="btn btn-primary btn-submit" (click)="saveChange()" [disabled]="loading">บันทึกการเปลี่ยนแปลง</button>
  </div>
</div>
<div class="content-body">
  <ng-container [formGroup]="form">
    <div class="profile">
      <app-upload-image [imageValue]="form.value.image_url" formControlName="image"></app-upload-image>
    </div>
    <!-- <div class="upload-hint">** ใช้รูปจริงเท่านั้น</div> -->
    <button class="btn btn-light change-image" (click)="selectImage()">เปลี่ยน</button>

    <div class="form info-form">
      <div class="row">
        <app-input
          formControlName="first_name"
          class="col-md-12 col-lg-6"
          label="ชื่อ (ภาษาไทย)"
          placeholder="ชื่อ (ภาษาไทย)"
        ></app-input>
        <app-input
          formControlName="last_name"
          class="col-md-12 col-lg-6"
          label="นามสกุล (ภาษาไทย)"
          placeholder="นามสกุล (ภาษาไทย)"
        ></app-input>
        <app-input
          class="col-md-12 col-lg-6"
          label="โทรศัพท์"
          placeholder="โทรศัพท์"
          formControlName="phone"
          [customMask]="'0{10}'"
          customNgClass=""
          [icon]="true"
          [isPhone]="true"
        ></app-input>
      </div>
    </div>
  </ng-container>

  <hr />
  <!-- <div class="interested">
      <div class="title mb-3">ความสนใจ</div>
      <div class="interested-education-level">
        <span>มัธยมศึกษาตอนต้น</span>
        <div class="row mt-3 mb-3 mr-0 ml-0">
          <div
            class="btn checkbox"
            [ngClass]="{ active: form.value.edu1 }"
            (click)="onClickInterested(form.controls.edu1)"
          >
            <feather-icon [hidden]="form.value.edu1" class="icon" name="circle"></feather-icon>
            <img class="icon" [hidden]="!form.value.edu1" src="assets/images/icon/checked.svg" />
            <span class="">มัธยมศึกษาปีที่ 1</span>
          </div>
          <div
            class="btn checkbox"
            [ngClass]="{ active: form.value.edu2 }"
            (click)="onClickInterested(form.controls.edu2)"
          >
            <feather-icon [hidden]="form.value.edu2" class="icon" name="circle"></feather-icon>
            <img class="icon" [hidden]="!form.value.edu2" src="assets/images/icon/checked.svg" />
            <span class="">มัธยมศึกษาปีที่ 2</span>
          </div>
          <div
            class="btn checkbox"
            [ngClass]="{ active: form.value.edu3 }"
            (click)="onClickInterested(form.controls.edu3)"
          >
            <feather-icon [hidden]="form.value.edu3" class="icon" name="circle"></feather-icon>
            <img class="icon" [hidden]="!form.value.edu3" src="assets/images/icon/checked.svg" />
            <span class="">มัธยมศึกษาปีที่ 3</span>
          </div>
        </div>
      </div>
      <div class="interested-education-level">
        <span>มัธยมศึกษาตอนปลาย</span>
        <div class="row mt-3 mb-3 mr-0 ml-0">
          <div
            class="btn checkbox"
            [ngClass]="{ active: form.value.edu4 }"
            (click)="onClickInterested(form.controls.edu4)"
          >
            <feather-icon [hidden]="form.value.edu4" class="icon" name="circle"></feather-icon>
            <img class="icon" [hidden]="!form.value.edu4" src="assets/images/icon/checked.svg" />
            <span class="">มัธยมศึกษาปีที่ 4</span>
          </div>
          <div
            class="btn checkbox"
            [ngClass]="{ active: form.value.edu5 }"
            (click)="onClickInterested(form.controls.edu5)"
          >
            <feather-icon [hidden]="form.value.edu5" class="icon" name="circle"></feather-icon>
            <img class="icon" [hidden]="!form.value.edu5" src="assets/images/icon/checked.svg" />
            <span class="">มัธยมศึกษาปีที่ 5</span>
          </div>
          <div
            class="btn checkbox"
            [ngClass]="{ active: form.value.edu6 }"
            (click)="onClickInterested(form.controls.edu6)"
          >
            <feather-icon [hidden]="form.value.edu6" class="icon" name="circle"></feather-icon>
            <img class="icon" [hidden]="!form.value.edu6" src="assets/images/icon/checked.svg" />
            <span class="">มัธยมศึกษาปีที่ 6</span>
          </div>
        </div>
      </div>
    </div> -->
  <div class="addresses">
    <app-address-info></app-address-info>
  </div>
</div>
