<ng-container [formGroup]="form">
  <div class="page">
    <ng-container *ngIf="studentList.length === 0; else showInfo">
      <div class="text-empty">
        ยังไม่มีข้อมูลนักเรียน <span routerLink="/parents/profile/student">คลิกเพื่อที่นี่เพิ่มนักเรียน</span>
      </div>
    </ng-container>

    <ng-template #showInfo>
      <div class="row">
        <div class="col-md-5 mt-3">
          <app-select
            label="เลือกนักเรียน"
            labelMode="inline"
            formControlName="student_id"
            bindValue="student_id"
            [clearable]="false"
            [options]="studentList"
            (valueChange)="getStudent($event)"
          ></app-select>
        </div>
      </div>

      <div class="information-content">
        <div class="content-header">
          <div class="student">
            <ng-container *ngIf="student$ | async as student">
              <img *ngIf="!student.image_url" src="assets/images/default.jpg" class="img-student" alt="student" />
              <img *ngIf="student.image_url" [src]="host + student.image_url" class="img-student" alt="student" />
              <div class="student-detail">
                <div class="student-name">{{ student?.full_name }}</div>
                <!-- <div class="student-recently-online">ออนไลน์เมื่อ {{ student.recently_online }} ชั่วโมงที่แล้ว</div> -->
              </div>
            </ng-container>
          </div>
          <div class="statistice-tab">
            <ng-container *ngFor="let tab of tabStatistics; index as i">
              <button class="btn tab-btn" [ngClass]="{ active: tabIndex === tab.id }" (click)="changeTab(tab.id)">
                {{ tab.name }}
              </button>
            </ng-container>
          </div>
        </div>
        <div class="devide-line-default"></div>

        <div [ngTemplateOutlet]="content"></div>
      </div>
    </ng-template>
  </div>

  <ng-template #content>
    <div *ngIf="tabIndex === 1">
      <app-home [find]="student$" [findMode]="true"></app-home>
    </div>
    <div *ngIf="tabIndex === 2">
      <div [ngTemplateOutlet]="courseList"></div>
      <app-study-time [params]="params" [findMode]="true"></app-study-time>
    </div>
    <div *ngIf="tabIndex === 3">
      <div [ngTemplateOutlet]="courseList"></div>
      <app-examination [params]="params" [findMode]="true"></app-examination>
    </div>
  </ng-template>

  <ng-template #courseList>
    <div class="statistics-courses col-md-6">
      <app-select
        label="คอร์สเรียน"
        labelMode="inline"
        formControlName="course_id"
        [clearable]="false"
        [options]="courseFilterOptions"
      ></app-select>
    </div>
  </ng-template>
</ng-container>
