<ng-container [ngSwitch]="currentAuthStateId">
  <ng-template [ngSwitchCase]="STATE.SIGN_IN">
    <app-sign-in (stateChanged)="onStageChanged($event)" (verifyStatus)="changeVerifyStatus($event)"></app-sign-in>
  </ng-template>
  <ng-template [ngSwitchCase]="STATE.SIGN_UP">
    <app-sign-up [errors]="failure" (stateChanged)="onStageChanged($event)" (submitted)="onSubmit()"></app-sign-up>
  </ng-template>
  <ng-template [ngSwitchCase]="STATE.SIGN_UP_COURSE">
    <app-sign-up-course (stateChanged)="onStageChanged($event)"></app-sign-up-course>
  </ng-template>
  <ng-template [ngSwitchCase]="STATE.FORGOT_PASSWORD">
    <app-forgot-password (submitted)="onSubmit()"></app-forgot-password>
  </ng-template>
  <ng-template [ngSwitchCase]="STATE.RESET_PASSWORD">
    <app-reset-password (submitted)="close()"></app-reset-password>
  </ng-template>
  <ng-template [ngSwitchCase]="STATE.VERIFY">
    <app-verify-email (submitted)="close()" [status]="verifyStatus"></app-verify-email>
  </ng-template>
</ng-container>
