export enum RoleIds {
  STUDENT = 2,
  PARENT = 4,
}

export enum ActiveStatusIds {
  ACTIVE = 1,
  IDLE = 2,
  INACTIVE = 3,
  REGISTERED = 4,
}
