<div class="parent-page" *ngIf="parent$ | async as parent">
  <div class="container">
    <div class="parent-header">
      <div class="profile">
        <img *ngIf="!parent.data.image_url" src="assets/images/default.jpg" />
        <img *ngIf="parent.data.image_url" [src]="host + parent.data.image_url" />
        <div class="profile-title">
          <span>ยินดีต้อนรับ</span>{{ parent.data.first_name }}
          <!-- <div class="profile-education">{{ parent.data.educatoin_level_name }} </div> -->
          <div class="profile-education">ผู้ปกครอง</div>
        </div>
      </div>
    </div>

    <ul class="tab-menu">
      <li class="tab-header" *ngFor="let tab of tabMenuList">
        <a routerLinkActive="active" [routerLink]="tab.route">{{ tab.name }}</a>
      </li>
    </ul>

    <router-outlet></router-outlet>
  </div>
</div>
