import { Injectable } from '@angular/core';
import { action, ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { StudentStore } from './student.store';
import {
  DatatableStudentActivityHistory,
  Student,
  StudentActivityHistory,
  StudentCourseLatest,
  StudentDurationtLesson,
  StudentResultLesson,
} from './student.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as queryString from 'qs';

@Injectable({ providedIn: 'root' })
export class StudentService {
  constructor(private studentStore: StudentStore, private http: HttpClient) { }

  @action('Get Course Lasted')
  getCourseLasted(): Observable<StudentCourseLatest> {
    return this.http.get<StudentCourseLatest>('/students/course_lasted').pipe(
      tap((response: StudentCourseLatest) => {
        this.studentStore.update({ courseLasted: response });
      })
    );
  }

  @action('Get Course Lasted By ID')
  getCourseLastedById(student_id: number): Observable<StudentCourseLatest> {
    return this.http.get<StudentCourseLatest>(`/students/course_lasted/${student_id}`).pipe(
      tap((response: StudentCourseLatest) => {
        this.studentStore.update({ courseLasted: response });
      })
    );
  }

  @action('Get Current')
  getCurrent(): Observable<Student> {
    return this.http.get<Student>('/students/current').pipe(
      tap((response: Student) => {
        this.studentStore.update({ current: response });
      })
    );
  }

  @action('Get By ID')
  getByID(id: number): Observable<Student> {
    return this.http.get<Student>(`/students/${id}`).pipe(
      tap((response: Student) => {
        this.studentStore.update({ find: response });
      })
    );
  }

  @action('Update Current')
  updateStudent(payload: Student): Observable<Student> {
    const formData = new FormData();
    for (const key in payload) {
      if (payload[key]) {
        formData.append(`student[${key}]`, payload[key]);
      }
    }
    return this.http.put<Student>(`/students/${payload.id}`, formData).pipe(
      tap((response: Student) => {
        this.studentStore.update({ current: response });
      })
    );
  }

  @action('Clear Current')
  clearCurrent(): void {
    this.studentStore.update({ current: null });
  }

  getActivityHostory(
    params: { page: number; back_date?: number },
    student_id?: number
  ): Observable<DatatableStudentActivityHistory> {
    return this.http.get<DatatableStudentActivityHistory>(
      `/students/activity_history${student_id ? '/' + student_id : ''}?${queryString.stringify(params)}`
    );
  }

  getResultLesson(params?: { back_date?: number }, student_id?: number): Observable<StudentResultLesson> {
    return this.http.get<StudentResultLesson>(
      `/students/result_lesson${student_id ? '/' + student_id : ''}?${queryString.stringify(params)}`
    );
  }

  getDurationLesson(params?: { back_date?: number }, student_id?: number): Observable<StudentDurationtLesson[]> {
    return this.http.get<StudentDurationtLesson[]>(
      `/students/duration_lesson${student_id ? '/' + student_id : ''}?${queryString.stringify(params)}`
    );
  }

  getStudentCoursesList(course_id: number): Observable<Student[]> {
    return this.http.get<Student[]>(`/students/no_course/${course_id}`);
  }

  checkHaveAlreadyCourse(course_id: number, student_id?: number): Observable<{ exist: boolean; messages: string }> {
    return this.http.get<{ exist: boolean; messages: string }>(
      `/students/have_course?course_id=${course_id}${student_id ? '&student_id=' + student_id : ''}`
    );
  }
}
