import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SetError } from 'src/app/utils/set-error.validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthStateId } from 'src/app/_store/auth/state/auth.enum';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { NotifierService } from 'angular-notifier';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  subscriptions: Subscription[] = [];
  error = {} as any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private notifierService: NotifierService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  async resetRequest() {
    await this.dialogService.onSubmit('ยืนยัน?', 'ยืนยันการส่งของรหัสผ่านใหม่');
    this.error = {} as any;
    if (this.form.invalid) {
      this.error = SetError(this.form);
      return;
    }

    const value = this.form.value;
    await this.timeout();
    this.authService
      .fotgotPassword(value.email)
      .toPromise()
      .then(async (res) => {
        this.notifierService.notify('success', 'สำเร็จ!');
      })
      .catch(async (err) => {
        this.error.email = err.error.errors;
        this.notifierService.notify('error', this.error.email);
      });
  }

  timeout() {
    return new Promise<void>((resolve) => {
      this.spinnerService.show();
      setTimeout((res) => {
        this.spinnerService.hide();
        resolve();
      }, 3000);
    });
  }
}
