import { StudentQuery } from 'src/app/_store/student/state/student.query';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, skip } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudentSummaryResult } from 'src/app/_store/student-exam-result/student-exam-result.model';
import { StudentExamResultService } from 'src/app/_store/student-exam-result/student-exam-result.service';
import { Student } from 'src/app/_store/student/state/student.model';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { ColorConfig } from 'src/app/_models/layout.model';

export enum ExamFilters {
  EXAM_COURSE = 1,
  EXAM_STORAGE = 2,
  ALL = 3,
}

@Component({
  selector: 'app-examination',
  templateUrl: './examination.component.html',
  styleUrls: ['./examination.component.scss'],
})
export class ExaminationComponent implements OnInit, OnDestroy {
  form: FormGroup;
  summary: StudentSummaryResult;
  student: Student;

  color: ColorConfig;

  get examFilters(): typeof ExamFilters {
    return ExamFilters;
  }

  @Input()
  set params(value) {
    if (!this.form) {
      this.initForm();
    }

    if (value) {
      this.form.patchValue(value);
    }
  }

  @Input() findMode = false;

  constructor(
    private fb: FormBuilder,
    private studentExamResultService: StudentExamResultService,
    private spinnerService: NgxSpinnerService,
    private studentQuery: StudentQuery,
    private layoutConfigservice: LayoutConfigService
  ) {}

  ngOnInit(): void {
    this.initLayout();
    if (this.findMode) {
      this.getCurrentStudent();
    }
    this.getSummary(this.form.value);
    this.filter();
  }

  initLayout(): void {
    this.color = this.layoutConfigservice.getSavedConfig('config.color');
  }

  ngOnDestroy(): void {}

  async getSummary(params: any): Promise<void> {
    if (this.findMode) {
      if (this.student) {
        this.studentExamResultService
          .getSummaryResultById(this.student.id, params)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            if (res) {
              this.summary = {
                ...res,
                duration_time: this.dataTime(res.duration_time),
                fastest_time: this.dataTime(res.fastest_time),
                slowest_time: this.dataTime(res.slowest_time),
              };
            }
          });
      }
    } else {
      this.studentExamResultService
        .getSummaryResult(params)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.summary = {
            ...res,
            duration_time: this.dataTime(res.duration_time),
            fastest_time: this.dataTime(res.fastest_time),
            slowest_time: this.dataTime(res.slowest_time),
          };
        });
    }
  }

  getCurrentStudent(): void {
    const student$ = this.studentQuery.find$;
    student$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.student = value;
    });
  }

  filter(): void {
    this.form.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe((change) => {
      if (change) {
        setTimeout((_) => {
          this.spinnerService.hide();
        }, 800);
        this.getSummary(change);
      }
    });
  }

  dataTime(value: any): string {
    const duration = moment.duration(Number(value), 'seconds');
    const hours = Math.floor(duration.asHours());
    const h = ('0' + hours).slice(-3);
    const m = ('0' + duration.minutes()).slice(-2);
    const s = ('0' + duration.seconds()).slice(-2);
    return `${h}:${m}:${s}`;
  }

  initForm(): void {
    this.form = this.fb.group({
      course_id: null,
      student_id: null,
      days: 7,
      exam_course: true,
      exam_storage: null,
      course_exam_type_id: null,
    });
  }

  get mode(): number {
    return this.form.value.mode;
  }

  updateSummary(value): void {
    this.form.patchValue(value);
  }

  filterExam(val: any): void {
    this.form.patchValue({ mode: val });
  }

  onFilter(value: any): void {
    this.form.patchValue(value);
  }
}
