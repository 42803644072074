import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { IconModule } from 'src/app/utils/icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MonitorRoutingModule } from './monitor-routing.module';
import { StudyTimeComponent } from './study-time/study-time.component';
import { ExaminationComponent } from './examination/examination.component';
import { HistoryExamTableComponent } from './examination/components/history-exam-table/history-exam-table.component';
import { MonitorComponent } from './monitor.component';
import { FilterExamComponent } from './examination/components/filter-exam/filter-exam.component';

@NgModule({
  declarations: [
    StudyTimeComponent,
    ExaminationComponent,
    HistoryExamTableComponent,
    MonitorComponent,
    FilterExamComponent,
  ],
  imports: [
    CommonModule,
    MonitorRoutingModule,
    MatProgressBarModule,
    IconModule,
    ReactiveFormsModule,
    PipesModule,
    SharedModule,
    NgCircleProgressModule.forRoot({}),
    NgxChartsModule,
  ],
  exports: [ExaminationComponent, StudyTimeComponent],
})
export class MonitorModule {}
