<!-- <div class="status-band">
  <p>Development Server</p>
</div> -->
<router-outlet></router-outlet>
<ngx-spinner bdOpacity="0.1" bdColor="rgba(0,0,0,0.67)" size="default" color="#ffffff" type="cube-transition" [fullScreen]="true">
</ngx-spinner>

<ngx-spinner name="manual" bdOpacity="0.1" bdColor="rgba(0,0,0,0.67)" size="default" color="#ffffff" type="cube-transition" [fullScreen]="true">
</ngx-spinner>

<notifier-container></notifier-container>