import { FormGroup, FormArray } from '@angular/forms';

export function SetError(form: FormGroup, apiError?: any) {
  const error: any = {};
  const errorArray: any = {};

  if (apiError) {
    Object.keys(apiError).forEach(k => {
      error[k] = apiError[k][0];
    });
  } else {
    Object.keys(form.value).forEach(k => {
      const object = form.controls[k];
      if (object instanceof FormArray) {
        error[k] = SetErrorArray(object);
        errorArray[k] = QueryErrorArray(object);
      } else {
        if (object.errors) {
          error[k] = object.errors;
        }
      }
    });
  }
  // Scroll to error element
  ScrollToError(error, errorArray);

  return error;
}

function SetErrorArray(form: FormArray) {
  const errors = [];

  form.controls.forEach((control: FormGroup) => {
    const error = {};
    Object.keys(control.value).forEach(key => {
      if (control.controls[key].errors) {
        error[key] = control.controls[key].errors;
      }
    });
    errors.push(error);
  });

  return errors;
}

function QueryErrorArray(form: FormArray) {
  const errors = [];

  form.controls.forEach((control: FormGroup) => {
    const error = {};
    Object.keys(control.value).forEach((key, index) => {
      if (control.controls[key].errors) {
        error[key] = control.controls[key].errors;
        errors.push(error);
      }
    });
  });

  return errors;
}

function ScrollToError(error, errorArray) {
  let el: Element;
  Object.keys(errorArray).forEach(k => {
    if (errorArray[k].length === 0) {
      delete errorArray[k];
    }
  });

  const key = Object.keys(error)[0];
  if (!Array.isArray(error[key])) {
    el = document.querySelector('[name=' + Object.keys(error)[0] + ']');
  } else {
    if (Object.keys(errorArray).length !== 0) {
      const k = Object.keys(errorArray)[0];
      for (let i = 0; i <= error[k].length; i++) {
        if (Object.keys(error[key][i]).length > 0) {
          el = document.querySelector(
            '[name=' + k + '_' + Object.keys(error[k])[i] + '_' + Object.keys(error[k][i])[0] + ']'
          );
          // if (!el) {
          //   el = document.querySelector('#' + k + '_' + Object.keys(error[k])[i] + '_' + Object.keys(error[k][i])[0]);
          // }
          break;
        }
      }
    }
  }

  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
