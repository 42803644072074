import { Injectable } from '@angular/core';
import { ParentCourse } from './parent-course.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ParentCourseState extends EntityState<ParentCourse> {
  list: ParentCourse[];
}

export function createInitialState(): ParentCourseState {
  return {
    list: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'parent_course' })
export class ParentCourseStore extends EntityStore<ParentCourseState> {
  constructor() {
    super(createInitialState());
  }
}
