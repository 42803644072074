import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageAccountComponent } from './manage-account/manage-account.component';

import { ManageStudentComponent } from './manage-student/manage-student.component';
import { NotificationComponent } from './notification/notification.component';
import { ParentProfileComponent } from './parent-profile.component';
import { PasswordComponent } from './password/password.component';
import { PersonalInfomationComponent } from './personal-infomation/personal-infomation.component';

const routes: Routes = [
  {
    path: '',
    component: ParentProfileComponent,
    children: [
      { path: 'info', component: PersonalInfomationComponent },
      { path: 'account', component: ManageAccountComponent },
      { path: 'notification', component: NotificationComponent },
      { path: 'password', component: PasswordComponent },
      { path: 'student', component: ManageStudentComponent },

      { path: '', redirectTo: 'info', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ParentProfileRoutingModule {}
