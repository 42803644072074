import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
moment.locale('th');

@Pipe({
  name: 'timeInterval',
})
export class TimeIntervalPipe implements PipeTransform {
  transform(value: string, options?: 'Date'): unknown {
    if (!value) {
      return;
    }

    switch (options) {
      case 'Date':
        value = moment(value).format('YYYY-MM-DD');
        break;
    }

    const val = moment(value).fromNow();

    return val;
  }
}
