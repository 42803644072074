import { Injectable } from '@angular/core';
import { action, ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { UnderParentingStore } from './under-parenting.store';
import { ParentingInteraction, UnderParenting } from './under-parenting.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Student } from '../student/state/student.model';

@Injectable({ providedIn: 'root' })
export class UnderParentingService {
  constructor(private underParentingStore: UnderParentingStore, private http: HttpClient) {}

  @action('Get Under Parenting')
  get(): Observable<UnderParenting[]> {
    return this.http.get<UnderParenting[]>('/under_parentings').pipe(
      tap((entities) => {
        this.underParentingStore.update({ list: entities });
      })
    );
  }

  @action('Remove Under Parenting')
  remove(payload: ParentingInteraction): Observable<ParentingInteraction> {
    return this.http
      .post<ParentingInteraction>(`/parenting_interactions`, { parenting_interaction: payload })
      .pipe(
        tap((res) => {
          this.get().subscribe();
        })
      );
  }
}
