import { Injectable } from '@angular/core';
import { CourseCategory } from './course-category.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CourseCategoryState extends EntityState<CourseCategory> {
  find: CourseCategory;
}

export function createInitialState(): CourseCategoryState {
  return {
    find: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'course-category', resettable: true })
export class CourseCategoryStore extends EntityStore<CourseCategoryState> {
  constructor() {
    super(createInitialState());
  }
}
