import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { DefaultCourseImage } from 'src/app/_models/layout.model';
import { ParentCourse } from 'src/app/_store/parent-course/parent-course.model';
import { ParentCourseService } from 'src/app/_store/parent-course/parent-course.service';
import { environment } from 'src/environments/environment';
import { DialogGiveCourseComponent } from './dialog-give-course/dialog-give-course.component';
import { DialogManageCourseComponent } from './dialog-manage-course/dialog-manage-course.component';

@Component({
  selector: 'app-courses-storage',
  templateUrl: './courses-storage.component.html',
  styleUrls: ['./courses-storage.component.scss'],
})
export class CoursesStorageComponent implements OnInit, OnDestroy {
  form: FormGroup;
  host = environment.apiUrl.replace('/api', '');
  defaultImage$ = new BehaviorSubject<DefaultCourseImage>(null);
  courseList: ParentCourse[] = [];
  isLoadMore = false;

  constructor(
    private parentCourseService: ParentCourseService,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService
  ) {}

  ngOnInit(): void {
    this.initFormSearch();
    this.initParentCourse();
    this.initFilter();
  }

  ngOnDestroy(): void {}

  get coursePreview(): string {
    return this.defaultImage$.value ? this.host + this.defaultImage$.value.blue : '';
  }

  initLayout(): void {
    this.defaultImage$.next(this.layoutConfigService.getSavedConfig('config.app.default_course_image'));
  }

  initFilter(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((res: { keywords: string }) => {
        if (res) {
          this.initParentCourse(res);
        }
      });
  }

  initFormSearch(): void {
    this.form = this.fb.group({
      keywords: [''],
    });
  }

  initParentCourse(params?: { keywords: string }): void {
    this.parentCourseService
      .get(params)
      .pipe(untilDestroyed(this))
      .subscribe(async (res) => {
        if (res) {
          this.courseList = res.filter((f) => f.amount > 0);
          const studentCourse = await this.parentCourseService.getListUnderParenting(params).toPromise();
          if (studentCourse) {
            this.courseList = this.courseList.concat(studentCourse);
          }
        }
      });
  }

  giveCourse(data: ParentCourse): void {
    const dialogRef = this.dialog.open(DialogGiveCourseComponent, {
      minWidth: '40vw',
      maxHeight: '70vh',
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.notifierService.notify('success', 'สำเร็จ!');
        this.initParentCourse();
      }
    });
  }

  manageCourse(data: ParentCourse): void {
    const dialogRef = this.dialog.open(DialogManageCourseComponent, {
      minWidth: '40vw',
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.initParentCourse();
      }
    });
  }
}
