<div class="page">
  <div class="sticky">
    <app-header-parent></app-header-parent>
  </div>

  <router-outlet></router-outlet>

  <div class="footer">
    <app-footer-licence></app-footer-licence>
  </div>

  <app-scroll-top></app-scroll-top>
</div>
