import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArray',
})
export class JoinArrayPipe implements PipeTransform {
  transform(value: [], key: string): unknown {
    const result = value.map((val) => val[key]).join(', ');

    return result;
  }
}
