import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  forwardRef,
  ViewEncapsulation,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription, Observable, Subject, concat, of, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GenerateRandom } from 'src/app/utils/rendom';
import { LabelMode } from '../_enums/label-mode.enum';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() submitted: boolean;
  @Input() required: boolean;
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() hideSelected = true;
  @Input() limit = 20;
  @Input() isCustomSubDistrict = false;
  @Input() customWidth = false;
  @Input() customTemplate: TemplateRef<any>;
  @Input() name = GenerateRandom(6);

  @Input()
  set multiple(value) {
    this.isMultiple = value;
  }
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  isMultiple: boolean;
  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  @Input()
  set params(value: any) {
    this.apiParams = value;
    this.setTypeahead(this.form.value);
  }

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set options(value: Array<any>) {
    this.options$ = of(value);
  }

  @Input()
  set url(value: string) {
    this.isTypeahead = true;
    this.apiUrl = value;
  }

  @Input()
  set value(value: number) {
    this.setTypeahead(value);
    this.form.setValue(value);
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];
      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = value;
      }
    }
  }

  @Output() valueChange = new EventEmitter();
  @Output() subDistrictChange = new EventEmitter();
  @Output() valueClear = new EventEmitter();

  form: FormControl = new FormControl('');
  subscription: Subscription;

  isRequired = false;
  isLoading = false;
  isTypeahead = false;
  isError = false;
  options$: Observable<any> = of([]);
  search$ = new Subject();
  errorMessage = '';
  apiUrl = '';
  apiParams: any = {};

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      this.onChange(this.form.value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    // this.apiParams = {};
    delete this.apiParams.id;
    this.setTypeahead(value);
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onSelect(value): void {
    this.valueChange.emit(value);
  }

  setTypeahead(defaultId: number): void {
    if (!this.isTypeahead) {
      return;
    }

    this.options$ = concat(
      this.setDefault(defaultId), // default items
      this.search$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap((keyword: string) =>
          this.search(keyword).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.isLoading = false))
          )
        )
      )
    );
  }

  search(keyword: string = ''): Observable<any> {
    let filters = {
      keywords: keyword ? keyword : '',
      limit: this.limit,
    };
    if (this.apiParams) {
      filters = Object.assign({}, filters, this.apiParams);
    }
    return this.getHttp(filters);
  }

  getHttp(params?): Observable<any> {
    return this.http.get('/' + this.apiUrl, { params }).pipe(catchError(() => of([])));
  }

  async setDefault(id: any): Promise<any[]> {
    let results = [];
    results = await this.search().toPromise();

    if (id) {
      const idIsArray: boolean = id instanceof Array;
      if (!idIsArray) {
        const isAlready: boolean = results.map((obj) => obj.id).indexOf(id) > -1;
        if (!isAlready) {
          this.apiParams.id = id;
          const defaultResult: Array<any> = await this.search().toPromise();
          const value = defaultResult ? defaultResult[0] : null;

          results.push(value);
        }
      } else {
        const ids = id;
        for (const item of ids) {
          const isAlready: boolean = results.map((obj) => obj.id).indexOf(item) > -1;
          if (!isAlready) {
            this.apiParams.id = item;
            const defaultResult: Array<any> = await this.search().toPromise();
            const value = defaultResult ? defaultResult[0] : null;

            results.push(value);
          }
        }
      }
    }

    return results;
  }

  reloadData(): void {
    this.getHttp();
  }

  onClear(): void {
    this.valueClear.emit();
  }
}
