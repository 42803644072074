import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { MenuList, MenuParentConfig } from 'src/app/config/layouts/menu.config';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { LogoConfig } from 'src/app/_models/layout.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-parent-mobile',
  templateUrl: './header-parent-mobile.component.html',
  styleUrls: ['./header-parent-mobile.component.scss'],
})
export class HeaderParentMobileComponent implements OnInit, OnDestroy {
  menuList$ = new BehaviorSubject<MenuList[]>([]);

  isShowMenu = false;
  isExceptDesktop = false;
  config: LogoConfig;

  host = environment.apiUrl.replace('/api', '');
  constructor(
    private detectDeviceService: DeviceDetectorService,
    private router: Router,
    private layoutConfigService: LayoutConfigService
  ) { }

  ngOnInit(): void {
    this.initLayout();
    this.isExceptDesktop = !this.detectDeviceService.isDesktop();
    this.menuList$.next(MenuParentConfig);
  }

  initLayout(): void {
    this.config = this.layoutConfigService.getSavedConfig('config.logo_pic');
  }

  ngOnDestroy(): void {
    // this.renderer.removeClass(document.body, 'hidden-scroll');
  }

  toggleShowMenu(): void {
    this.isShowMenu = !this.isShowMenu;

    setTimeout((_) => {
      if (this.isShowMenu) {
        // document.body.classList.add('hidden-scroll');
        // this.renderer.addClass(document.body, 'hidden-scroll');
      } else {
        // this.renderer.removeClass(document.body, 'hidden-scroll');
      }
    });
  }

  closeMenu(): void {
    this.isShowMenu = false;
    // this.renderer.removeClass(document.body, 'hidden-scroll');
  }

  selectMenu(menu: MenuList): void {
    if (menu.isRoot) {
      menu.toggle = !menu.toggle;
      return;
    }

    this.isShowMenu = false;
    this.router.navigate([menu.path]);
  }
}
