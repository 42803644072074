import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'durationVideo',
})
export class DurationVideoPipe implements PipeTransform {
  transform(time: number, format?: string): unknown {
    const duration = moment.duration(Number(time), 'seconds');
    const h = Math.floor(duration.asHours());
    const m = Math.floor(duration.minutes());
    const s = Math.floor(duration.seconds());

    let result = '';
    if (format === 'HH:mm:ss') {
      const formatting =
        h > 0
          ? [('0' + h).slice(-2), ('0' + m).slice(-2), ('0' + s).slice(-2)]
          : [('0' + m).slice(-2), ('0' + s).slice(-2)];
      result = formatting.join(':');
    } else {
      result = h > 0 ? `${h}ชม. ${m}น.` : `${m}น.`; // Th
    }

    switch (format) {
      case 'HH:mm:ss':
        const formatting =
          h > 0
            ? [('0' + h).slice(-2), ('0' + m).slice(-2), ('0' + s).slice(-2)]
            : [('0' + m).slice(-2), ('0' + s).slice(-2)];
        result = formatting.join(':');
        break;
      case 'HH.mm':
        const formatting2 = h > 0 ? [`${h}`.slice(-2), ('0' + m).slice(-2)] : [('0' + m).slice(-2)];
        result = formatting2.join('.');
        break;
      case 'fullTime':
        result = h > 0 ? `${h}ชม. ${m}น.` : m > 0 ? `${m}น.` : `${s}ว.`; // Th
        break;
      default:
        result = h > 0 ? `${h}ชม. ${m}น.` : `${m}น.`; // Th
    }

    return result;
  }
}
