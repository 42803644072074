<div class="auth-dialog">
  <div class="auth-dialog-bg">
    <div class="bg-content">
      <div class="bg-title">{{ "AUTH.VERIFY_EMAIL.TITLE" | translate }}</div>
      <div class="bg-title">{{ "APP_TITLE" | translate }}</div>
      <div class="bg-line"></div>
      <div class="bg-description">{{ "AUTH.VERIFY_EMAIL.DESCRIPTION" | translate }}</div>
    </div>
  </div>
  <div class="auth-dialog-content">
    <div class="forgot-page">
      <ng-container [ngSwitch]="verifyStatus">
        <ng-template [ngSwitchCase]="verifyId.VERIFIED">
          <feather-icon name="check-circle" class="success"> </feather-icon>
          <div class="text-content">{{ "AUTH.VERIFY_EMAIL.STATUS.VERIFIED" | translate }}</div>
        </ng-template>
        <ng-template [ngSwitchCase]="verifyId.CANCEL">
          <feather-icon name="x-circle" class="danger"> </feather-icon>
          <div class="text-content">{{ "AUTH.VERIFY_EMAIL.STATUS.CANCEL" | translate }}</div>
          <div class="text-content">{{ "AUTH.VERIFY_EMAIL.STATUS.RETRY" | translate }}</div>
          <app-input [formControl]="email" [error]="error.email" placeholder="@mail.com"></app-input>
          <button class="btn btn-resent" (click)="resentRequest()">{{ "AUTH.VERIFY_EMAIL.SEND_REQUEST" | translate }}</button>
        </ng-template>
        <ng-template [ngSwitchCase]="verifyId.WATING">
          <feather-icon name="loader" class="loader"> </feather-icon>
          <div class="text-content">{{ "AUTH.VERIFY_EMAIL.STATUS.WATING" | translate }}</div>
        </ng-template>
        <ng-template [ngSwitchCase]="verifyId.DONE">
          <feather-icon name="alert-circle" class="success"> </feather-icon>
          <div class="text-content">{{ "AUTH.VERIFY_EMAIL.STATUS.DONE" | translate }}</div>
        </ng-template>
        <ng-template [ngSwitchCase]="verifyId.RESENT">
          <feather-icon name="alert-circle"> </feather-icon>
          <div class="text-content">{{ "AUTH.VERIFY_EMAIL.STATUS.RESENT" | translate }}</div>
          <div>{{ "AUTH.VERIFY_EMAIL.REMIND" | translate }}</div>
          <app-input [formControl]="email" [error]="error.email" placeholder="@mail.com"></app-input>
          <button *ngIf="onWaiting" class="btn btn-resent" disabled>
            {{ "AUTH.VERIFY_EMAIL.CLICK_AGAIN" | translate }} {{ this.waitingTime }} {{ "GLOBAL.UNIT_TIME.SECOND" | translate }}
          </button>
          <button *ngIf="!onWaiting" class="btn btn-resent" (click)="resentRequest()">
            {{ "AUTH.VERIFY_EMAIL.SEND_REQUEST" | translate }}
          </button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
