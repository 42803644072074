import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentInfoMonitorRoutingModule } from './student-info-monitor-routing.module';
import { StudentInfoMonitorComponent } from './student-info-monitor.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MonitorModule } from 'src/app/students/student-index/monitor/monitor.module';
import { StudyProgressComponent } from './components/study-progress/study-progress.component';
import { StudentHomeModule } from 'src/app/students/student-index/student-home/student-home.module';
@NgModule({
  declarations: [StudentInfoMonitorComponent, StudyProgressComponent],
  imports: [
    CommonModule,
    StudentInfoMonitorRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MonitorModule,
    StudentHomeModule,
  ],
})
export class StudentInfoMonitorModule {}
