import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AuthQuery } from 'src/app/_store/auth/state/auth.query';
import { CartQuery } from 'src/app/_store/cart/cart.query';
import { ActiveStatusIds, RoleIds } from 'src/app/_store/user/state/user.enum';
import { UserQuery } from 'src/app/_store/user/state/user.query';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  isAuthenticated = false;

  constructor(
    public cartQuery: CartQuery,
    private authQuery: AuthQuery,
    private userQuery: UserQuery,
    private router: Router,
    private notifierService: NotifierService
  ) { }

  ngOnInit(): void {
    this.initAuth();
  }

  ngOnDestroy(): void { }

  initAuth(): void {
    const isAuthenticate$ = this.authQuery.selectIsAuthenticate$;
    isAuthenticate$.pipe(untilDestroyed(this)).subscribe((isAuth) => {
      this.isAuthenticated = isAuth;
    });
  }

  onClick(): void {
    this.isActive() && this.router.navigate(['cart']);
  }

  isActive(): boolean {
    try {
      const user = this.userQuery.getCurrent;

      if (
        (
          (user.data.active_status_id === ActiveStatusIds.REGISTERED ||
            user.data.active_status_id === ActiveStatusIds.ACTIVE ||
            user.data.active_status_id === ActiveStatusIds.IDLE
          ) && user.role.id === RoleIds.STUDENT
        )
        || user.role.id === RoleIds.PARENT
      ) {
        return true;
      }
    } catch (err) {
      // console.error(err);
      return false;
    }
    this.notifierService.notify('warning', 'กรุณาติดต่อแอดมิน');
    return false;
  }
}
