import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StudentCourseStore } from './student-course.store';
import { StudentCourse, StudentCourseParams, StudentCourseUpdateTimespent } from './student-course.model';
import { tap } from 'rxjs/operators';
import * as queryString from 'qs';
import { Observable } from 'rxjs';
import { StudentCourseLatest } from '../../student/state/student.model';
import { action } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class StudentCourseService {
  constructor(private studentCourseStore: StudentCourseStore, private http: HttpClient) { }

  @action('Get student course list')
  getStudentCourseList(payload?: StudentCourseParams): Observable<StudentCourse[]> {
    this.studentCourseStore.setLoading(true);
    return this.http.get<StudentCourse[]>(`/student_courses?${queryString.stringify(payload)}`).pipe(
      tap((entities) => {
        this.studentCourseStore.set(entities);
        this.studentCourseStore.setLoading(false);
      })
    );
  }

  @action('Find student course')
  find(id: number): Observable<StudentCourse> {
    return this.http.get<StudentCourse>(`/student_courses/${id}`).pipe(
      tap((response) => {
        this.studentCourseStore.update({ find: response });
      })
    );
  }

  getSelectCourseList(payload?: StudentCourseParams): Observable<StudentCourse[]> {
    return this.http.get<StudentCourse[]>(`/student_courses?${queryString.stringify(payload)}`);
  }

  updateTimeSpent(payload: StudentCourseUpdateTimespent): Observable<StudentCourse> {
    return this.http.put<StudentCourse>(`/student_courses/${payload.id}`, {
      student_course: payload,
    });
  }

  updateCourseLatest(params: StudentCourseLatest): Observable<StudentCourse> {
    return this.http.post<StudentCourse>('/student_course_latests', params);
  }

  activatedCourse(id: number): Observable<StudentCourse> {
    return this.http.put<StudentCourse>(`/student_courses/activated/${id}`, {});
  }

  isExpire(id: number): Observable<{ expire: boolean }> {
    return this.http.get<{ expire: boolean }>(`/student_courses/expire/${id}`);
  }

  isExpireCourse(id: number): Observable<{ expire: boolean, student_course_id: number }> {
    return this.http.get<{ expire: boolean, student_course_id: number }>(`/student_courses/expire_course/${id}`);
  }
}
