import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject } from 'rxjs';
import { MenuConfig, MenuList } from 'src/app/config/layouts/menu.config';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { LayoutConfig } from 'src/app/_models/layout.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  menuList$ = new BehaviorSubject<MenuList[]>([]);
  constructor(private notifierService: NotifierService, private layoutConfigService: LayoutConfigService) { }
  config$ = new BehaviorSubject<LayoutConfig>(null);

  mywindow;
  host = environment.apiUrl.replace('/api', '');

  content: any = {
    address: '88/115 ซอยสุคนธสวัสดิ์ 19 แขวงลาดพร้าว เขตลาดพร้าว กทม',
    tel: '081 587 9998',
    facebook: {
      name: 'Kruptoey - Eazycal',
      url: 'http://facebook.com/eazycal'
    },
    line: {
      name: '@eazycal',
      url: 'https://line.me/R/ti/p/@eazycal'
    },
    youtube: {
      name: 'Kruptoey - Eazycal',
      url: 'https://www.youtube.com/user/toeybj008'
    }
  }

  ngOnInit(): void {
    this.initLayout();
    this.menuList$.next(MenuConfig);
  }

  initLayout(): void {
    this.config$.next(this.layoutConfigService.getSavedConfig('config'))
  }

  onSubscriptionNews(): void {
    this.notifierService.notify('default', 'ระบบยังไม่พร้อมใช้งาน');
  }
}
