import { Injectable } from '@angular/core';
import { StudentCourse } from './student-course.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface StudentCourseState extends EntityState<StudentCourse> {
  find: StudentCourse;
}

export function createInitialState(): StudentCourseState {
  return {
    find: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'student-course' })
export class StudentCourseStore extends EntityStore<StudentCourseState> {
  constructor() {
    super(createInitialState);
  }
}
