import { Injectable } from '@angular/core';
import { Cart } from './cart.model';
import { Store, StoreConfig } from '@datorama/akita';

export interface CartState {
  list: {
    isLoading: boolean;
    error: string;
    data: Cart;
  };
  payment_type: number;
}

export const initializeState = (): CartState => {
  return {
    list: {
      isLoading: false,
      error: null,
      data: null,
    },
    payment_type: null,
  };
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart'})
export class CartStore extends Store<CartState> {
  constructor() {
    super(initializeState());
  }
}
