import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentHomeRoutingModule } from './student-home-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { HomeComponent } from './home/home.component';
import { IconModule } from 'src/app/utils/icon/icon.module';
import { SearchModule } from 'src/app/pages/courses/components/course-list/components/search/search.component';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    StudentHomeRoutingModule,
    PipesModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    IconModule,
    SearchModule,
  ],
  exports: [HomeComponent],
})
export class StudentHomeModule {}
