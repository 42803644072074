import { Injectable } from '@angular/core';
import { action, ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { BankAccount, Cart } from './cart.model';
import { tap } from 'rxjs/operators';
import { CartState, CartStore, initializeState } from './cart.store';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/state/auth.service';

@Injectable({ providedIn: 'root' })
export class CartService {
  constructor(private cartStore: CartStore, private http: HttpClient, private authService: AuthService) {
    if (this.authService.isAuthenticated()) {
      this.get().subscribe();
    }
  }

  @action('Get Cart')
  get(): Observable<Cart> {
    return this.http.get<Cart>('/carts').pipe(
      tap((res) => {
        this.cartStore.update((stateRes: CartState) => ({
          ...stateRes,
          list: {
            isLoading: false,
            error: null,
            data: res,
          },
        }));
      })
    );
  }

  @action('Add Cart item')
  addItem(course_id: number, amount?: number): Observable<Cart> {
    return this.http
      .post<Cart>('/carts', { cart: { course_id, amount } })
      .pipe(
        tap((res) => {
          this.cartStore.update((stateRes: CartState) => ({
            ...stateRes,
            list: {
              isLoading: false,
              error: null,
              data: res,
            },
          }));
        })
      );
  }

  @action('Remove Cart item number')
  removeNumber(course_id: any): Observable<Cart> {
    return this.http
      .delete<Cart>('/carts', { params: { cart: course_id } })
      .pipe(
        tap((res) => {
          this.cartStore.update((stateRes: CartState) => ({
            ...stateRes,
            list: {
              isLoading: false,
              error: null,
              data: res,
            },
          }));
        })
      );
  }

  @action('Remove Cart item')
  removeItem(course_id: number): Observable<Cart> {
    return this.http
      .post<Cart>('/cart_items', { cart: { course_id } })
      .pipe(
        tap((res) => {
          this.cartStore.update((stateRes: CartState) => ({
            ...stateRes,
            list: {
              isLoading: false,
              error: null,
              data: res[0],
            },
          }));
        })
      );
  }

  @action('Clear Cart')
  clearCart(): Observable<Cart> {
    return this.http.get<Cart>('/clear_carts').pipe(
      tap((res) => {
        this.cartStore.update((stateRes: CartState) => ({
          ...stateRes,
          list: {
            isLoading: false,
            error: null,
            data: res,
          },
        }));
      })
    );
  }

  @action('Select payment')
  selectPaymentType(payment_type_id: number): Observable<number> {
    return this.cartStore.update((stateRes: CartState) => ({
      ...stateRes,
      payment_type: payment_type_id,
    }));
  }

  @action('Reset Cart')
  clearCurrent(): void {
    this.cartStore.update(initializeState());
  }

  getBankAccount(): Observable<BankAccount[]> {
    return this.http.get<BankAccount[]>('/bank_accounts');
  }
}
