// Thai
export const locale = {
  lang: 'th',
  data: {
    TRANSLATOR: {
      SELECT: 'กรุณาเลือกภาษา',
    },
    APP_TITLE: 'Eazy Cal',
    AUTH: {
      FORGET_PASSWORD: {
        TITLE: 'ลืมรหัสผ่าน',
        DESCRIPTION: 'ระบุอีเมลที่สมัครเพื่อทำการรีเซ็ตรหัสผ่าน',
        SUGGESTION: '*กรุณากรอกอีเมลที่ใช้สมัคร ระบบจะส่งข้อมูลการรีเซ็ตรหัสผ่านไปยังอีเมลที่ระบุ',
        RESET: 'รีเซ็ตรหัสผ่าน',
      },
      RESET_PASSWORD: {
        TITLE: 'รีเซ็ตรหัสผ่าน',
        DESCRIPTION: 'ระบุอีเมลที่สมัครเพื่อทำการรีเซ็ตรหัสผ่าน',
        SUGGESTION: 'กรุณาตั้งรหัสผ่านใหม่',
        CHANGE_PASSWORD: 'เปลี่ยนรหัสผ่าน',
      },
      SIGN_IN: {
        TITLE: 'ยินดีต้อนรับสู่',
        DESCRIPTION: 'ลงชื่อเข้าใช้เพื่อไปยังบัญชีของคุณและเข้าถึงคอร์สออนไลน์นับพัน',
        FORGET: 'ลืมรหัสผ่าน?',
        NAME: 'เข้าสู่ระบบ',
        LET_ME_STAY: 'ให้ฉันอยู่ในระบบ',
        NO_HAVE_ACCOUNT_YET: 'ยังไม่มีบัญชีใช่หรือไม่?',
        REGISTER: 'ลงทะเบียน',
      },
      SIGN_UP: {
        TITLE: 'สมัครรับบัญชีใช้งาน',
        DESCRIPTION: 'สมัครเพื่อรับคอร์สเรียนฟรีของเราและเรียกดูสิ่งที่คุณสนใจ',
        UPLOAD_IMAGE: 'อัปโหลดรูป',
        UPLOAD_IMAGE_SUGGESTION: 'กรุณาอัปโหลดรูป',
        POLICY_TILE: 'โดยการลงทะเบียนเพื่อสร้างบัญชีฉันยอมรับ',
        TERMS: 'ข้อกำหนดการใช้งาน',
        AND: 'และ',
        POLICY: 'นโยบายความเป็นส่วนตัว',
        POLICY_CONTENT: {
          TITLE: 'นโยบายความเป็นส่วนตัวสำหรับลูกค้า',
          DESCRIPTION: '',
          CLOSE: 'ปิด'
        }
      },
      VERIFY_EMAIL: {
        TITLE: 'ยืนยันตัวตน',
        CLICK_AGAIN: 'กดได้อีกครั้ง',
        DESCRIPTION: 'การยืนยันความถูกต้อง',
        REMIND: 'หากยังไม่ได้รับอีเมล กรุณากรอกอีเมลเพื่อส่งคำร้องใหม่',
        SEND_REQUEST: 'ส่งคำขออีกครั้ง',
        STATUS: {
          VERIFIED: 'ยืนยันการลงทะเบียนสำเร็จ!',
          CANCEL: 'ยืนยันการลงทะเบียนไม่สำเร็จ!',
          RETRY: 'กรุณาลองใหม่อีกครั้ง',
          WATING: 'กำลังตรวจสอบการลงทะเบียน!',
          DONE: 'ยืนยันการลงทะเบียนสำเร็จ!',
          RESENT: 'ยืนยันตัวตนกรุณาตรวจสอบอีเมล!',
        },
      },
    },
    GLOBAL: {
      BACK: 'กลับ',
      BACK_TO_HOME: 'กลับสู่หน้าหลัก',
      CANCEL: 'ยกเลิก',
      CONTINUE: 'ต่อไป',
      CONFIRM: 'ตกลง',
      EDIT: 'แก้ไข',
      DONE: 'เสร็จสิ้น',
      DATE: 'วันที่',
      NEXT: 'ดำเนินการต่อ',
      NO_DATA: 'ไม่ข้อมูล',
      SEARCH: 'ค้นหา',
      SUBMIT: 'ยืนยัน',
      SELECT_ALL: 'เลือกทั้งหมด',
      TIME: 'เวลา',
      UNIT_TIME: {
        SECOND: 'วินาที',
      },
    },
    MENU: {
      COURSE: 'คอร์สเรียน',
      SEARCH_COURSE: 'ค้นหาคอร์สเรียน',
      EXAM_STORAGE: 'คลังข้อสอบ',
      NEWS: 'ข่าวสาร',
      ABOUT: 'เกี่ยวกับเรา',
      BLOG: 'บทความ',
      SUPPORT: 'ช่วยเหลือ',
      MAIN_PAGE: 'เว็บไซต์หลัก',
      BTN_SIGNIN: 'ลงชื่อเข้าใช้',
    },
    MY_ORDER: {
      ADDRESS: 'ที่อยู่',
      ADD_ADDRESS: 'เพิ่มที่อยู่',
      COURSE_LIST: 'รายการคอร์สเรียน',
      CANCEL_ORDER: 'ยกเลิกคำสั่งซื้อ',
      DISCOUNT: 'ส่วนลด',
      PAYMENT_BY: 'ชำระผ่าน',
      PAYMENT_METHOD: {
        BANK: 'ธนาคาร',
        BANK_TRANSFER: 'โอนชำระผ่านธนาคาร',
        ATTACH_PROOF: 'แนบไฟล์รูปภาพ(สลิป)',
      },
      PROOF_PAYMENT: 'หลักฐานการชำระ',
      PRICE_TOTAL: 'ราคารวม',
      STATUS: {
        VERIFIED: 'ตรวจสอบแล้ว',
        CANCELLED: 'ยกเลิกแล้ว',
      },
      TITLE: 'คำสั่งซื้อของฉัน',
      TOTAL: 'รวมทั้งหมด',
      TRANSACTION: {
        ID: 'หมายเลขคำสั่งซื้อ',
        BUY_DATE: 'วันที่สั่งซื้อ',
        STATUS: 'สถานะ',
        AMOUNT: 'จำนวนเงิน',
        PAYMENT_CHANNEL: 'ช่องทางการชำระ',
        VERIFY_PAYMENT: 'ตรวจสอบการชำระ',
      },
      VERIFYING: 'กำลังตรวจสอบหลักฐาน',
    },
    PAGE: {
      NEXT: 'ถัดไป',
      PREVIOUS: 'ก่อนหน้า',
    },
    LANDING_PAGE: {
      COURSE: {
        TITLE: 'หมวดหมู่คอร์สเรียน',
        DESCRIPTION: 'ถ้ายังไม่สามารถตัดสินใจได้ว่าจะเลือกคอร์สเรียนออนไลน์ไหน เรามีตัวเลือกหมวดหมู่คอร์สเรียนที่เหมาะกับคุณ',
        SHOW_ALL: 'ดูคอร์สทั้งหมด',
      },
      NEWS: {
        TITLE: 'ข่าวสารจากเรา',
        DESCRIPTION: 'ติดตามโพสต์ข่าวสารและบทความ ล่าสุดของเรา',
        SHOW_ALL: 'ดูทั้งหมด',
      },
      OBJECTIVE: {
        TITLE_1: 'ทำไมต้องเรียนกับเราที่',
        TITLE_2: 'คอร์สเรียนออนไลน์คณิตศาสตร์',
      },
      REVIEW: {
        TITLE: 'รีวิวจากผู้เรียน',
        DESCRIPTION_1: 'รวมรวบความคิดเห็นประสบการณ์จากผู้ที่เรียนกับ',
        DESCRIPTION_2: 'เพื่อเป็นตัวช่วยให้คุณตัดสินใจเลือกคอร์สเรียนที่ดีที่สุดสำหรับคุณ',
      },
    },
    LAYOUTS: {
      HEADERS: {
        HEADER: {
          MOBILE: { MY_CART: 'ตะกร้าของฉัน' },
        },
        HEADER_EXAM: {
          BACKWARD: 'กลับไปยังคลังข้อสอบ',
        },
        HEADER_PARENT: {
          CLASSROOM: 'ห้องเรียน Classroom',
          MOBILE: {},
        },
        HEADER_STUDENT: {
          CLASSROOM: 'ห้องเรียน Classroom',
        },
      },
      FOOTERS: {
        FOOTER: {
          ADDRESS: '315/4 Klang Muang Road, Amphoe Muang Khon Kaen, Khon Kaen Thailand 40000',
          PHONE: '(+66)89 173 5321',
          EMAIL: 'info@tutor.com',
          SOCIAL_MEDIA: {
            FACEBOOK: 'ไวซ์',
            LINE: 'ไวซ์',
          },
          SUBSCRIPTION: {
            TITLE: 'สมัครรับจดหมายข่าวของเรา',
            DESCRIPTION:
              'เราจะส่งข่าวสารและข้อเสนอดีๆ เพื่อไม่ให้คุณพลาดขอโอกาสต่างๆ จากทาง Toey Tutor สมัครไว้เลย!',
            SUBMIT: 'สมัครรับข่าวสาร',
          },
        },
        FOOTER_LICENCE: {
          NAME: 'Wise',
          PRIVACY: 'Privacy',
          TERMS: 'Terms',
          COOKIE: 'Cookie Policy',
          SITEMAP: 'Sitemap',
        },
      },
    },
    SOCIAL: {
      SHARE: 'แชร์ให้เพื่อน',
    },
    VALIDATOR: {
      EMAIL: 'อีเมลผิดพลาด',
      MAX_LENGTH: 'จำนวนตัวอักษรไม่เกิน',
      MIN_LENGTH: 'จำนวนตัวอักษรขั้นต่ำ',
      MUST_MATCH: 'รหัสผ่านใหม่และยืนยันรหัสผ่านใหม่ต้องเหมือนกัน',
      REQUIRED: 'กรุณากรอกข้อมูล',
      THAI_NATION_ID: 'หมายเลขบัตรประจำตัวประชาชนไม่ถูกต้อง',
    },
  },
};
