import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  StudentExamResult,
  StudentExamResultHistoryDatatable,
  StudentExamResultParams,
  StudentSummaryResult,
} from './student-exam-result.model';
import { StudentExamResultState, StudentExamResultStore } from './student-exam-result.store';
import * as queryString from 'qs';
import { action } from '@datorama/akita';
import { AuthService } from 'src/app/_store/auth/state/auth.service';

@Injectable({ providedIn: 'root' })
export class StudentExamResultService {
  constructor(
    private studentExamResultStore: StudentExamResultStore,
    private http: HttpClient,
    private authService: AuthService
  ) {}
  apiUrl = '/student_exam_results';

  @action('Get studentExamResult list')
  get(): Observable<StudentExamResult[]> {
    return this.http.get<StudentExamResult[]>(this.apiUrl).pipe(
      tap((res) => {
        this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
          ...stateRes,
          list: {
            isLoading: false,
            error: null,
            data: res,
          },
        }));
      })
    );
  }

  @action('Get studentExamResult by id')
  getById(id: number): Observable<StudentExamResult> {
    return this.http.get<StudentExamResult>(`${this.apiUrl}/${this.authService.isAuthenticated() ? '' : 'public/'}${id}`).pipe(
      tap((res) => {
        this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
          ...stateRes,
          find: {
            isLoading: false,
            error: null,
            data: res,
          },
        }));
      })
    );
  }

  @action('Get studentExamResult has already done')
  getDoneExam(examId: number): Observable<StudentExamResult[]> {
    return this.http.get<StudentExamResult[]>(`${this.apiUrl}?exam_id=${examId}`).pipe(
      tap((res) => {
        this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
          ...stateRes,
          list: {
            isLoading: false,
            error: null,
            data: res,
          },
        }));
      })
    );
  }

  @action('Get studentExamResult has already done with course type')
  getDoneExamWithCourseType(examId: number, courseExamTypeId?: number): Observable<StudentExamResult[]> {
    return this.http
      .get<StudentExamResult[]>(
        `${this.apiUrl}?get_detail=true&exam_id=${examId}${
          courseExamTypeId ? '&course_exam_type_id=' + courseExamTypeId : ''
        }`
      )
      .pipe(
        tap((res) => {
          this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
            ...stateRes,
            list: {
              isLoading: false,
              error: null,
              data: res,
            },
          }));
        })
      );
  }

  @action('Save answer')
  saveAnswer(payload: StudentExamResult): Observable<StudentExamResult> {
    return this.http
      .post<StudentExamResult>(`${this.apiUrl}/${this.authService.isAuthenticated() ? '' : 'public_'}save`, {
        student_exam_result: payload,
      })
      .pipe(
        tap((res) => {
          this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
            ...stateRes,
            create: {
              isLoading: false,
              error: null,
              data: res,
            },
          }));
        }),
        catchError((err) => {
          this.studentExamResultStore.setError((stateRes: StudentExamResultState) => ({
            ...stateRes,
            create: {
              isLoading: false,
              error: err,
              data: null,
            },
          }));
          return throwError(err);
        })
      );
  }

  @action('Create Student answer')
  studentAnswer(payload: any): Observable<StudentExamResult> {
    this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
      ...stateRes,
      studentAnswer: payload,
    }));
    return of(payload);
  }

  @action('Clear studentExamResult')
  clearStudentAnswer(): void {
    this.studentExamResultStore.update((stateRes: StudentExamResultState) => ({
      ...stateRes,
      studentAnswer: null,
    }));
  }

  updateResult(payload: any): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/${this.authService.isAuthenticated() ? '' : 'public_'}update/${payload.id}`,
      {
        student_exam_result: payload,
      }
    );
  }

  updateDoneStatusExam(payload: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/update_status/${payload.id}`, payload);
  }

  customGet(params: {}): Observable<any> {
    return this.http.get(`/${'student_exam_results'}`, { params });
  }

  getHistoryStatisticsResult(params?: StudentExamResultParams): Observable<StudentExamResultHistoryDatatable> {
    params = { ...params, statistics: true, datatable: true };
    return this.http.get<StudentExamResultHistoryDatatable>(`/student_exam_results?${queryString.stringify(params)}`);
  }

  getSummaryResult(params?: StudentExamResultParams): Observable<StudentSummaryResult> {
    return this.http.get<StudentSummaryResult>(`/summary_exam_results?${queryString.stringify(params)}`);
  }

  getSummaryResultById(student_id: number, params?: StudentExamResultParams): Observable<StudentSummaryResult> {
    return this.http.get<StudentSummaryResult>(`/summary_exam_results/${student_id}?${queryString.stringify(params)}`);
  }

  getHoldExamResult(examId: number): Observable<StudentExamResult> {
    return this.http.get<StudentExamResult>(`${this.apiUrl}?get_detail=true&exam_id=${examId}&course_exam_type_id=1`);
  }
}
