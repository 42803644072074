import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Address } from './address.model';
import { AddressState, AddressStore } from './address.store';

@Injectable({ providedIn: 'root' })
export class AddressQuery extends Query<AddressState> {
  constructor(protected store: AddressStore) {
    super(store);
  }

  get getListData(): Address[] {
    return this.store.getValue().list.data;
  }
}
