import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  @Input()
  set initialValue(value: number) {
    this.calculateRating(value);
  }

  @Input() total = 5;
  @Input() ratingEnabled = false;

  @Output() valueChange = new EventEmitter<number>();

  starRated = 0;
  startList = [];

  constructor() {}

  ngOnInit() {}

  counter(i: number) {
    return new Array(i);
  }

  calculateRating(value: number): void {
    this.startList = [];
    for (let i = 1; i <= value; i++) {
      this.startList.push(i);
    }
    if (value > this.startList[this.startList.length - 1]) {
      this.startList.push(value);
    }

    this.starRated = value;
  }

  rating(index: number): void {
    if (!this.ratingEnabled) {
      return;
    }
    const rating = index + 1;
    this.valueChange.emit(rating);
    this.calculateRating(rating);
  }

  isRating(index: number): boolean {
    return this.startList[index] <= this.starRated;
  }

  isHalfRating(index: number): boolean {
    return this.startList[index] % 1 >= 0.5;
  }
}
