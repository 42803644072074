export enum AuthStateId {
  SIGN_IN = 1,
  SIGN_UP = 2,
  SIGN_UP_COURSE = 3,
  DONE = 4,
  FORGOT_PASSWORD = 5,
  RESET_PASSWORD = 6,
  VERIFY = 7,
}

export enum SignUpCourseIds {}

export enum SignUpRoleIds {
  STUDENT = 1,
  PARENT = 2,
}
