import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogsComponent } from '../dialogs/dialogs.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogRef;
  constructor(public dialog: MatDialog) {}

  onSubmit(title?: string, content?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.dialogRef = this.dialog.open(DialogsComponent, {
        minWidth: '40vw',
        data: {
          title: title || 'แน่ใจหรือไม่?',
          content: content || 'สิ้นสุดการทำข้อสอบ',
          decisionBtn: true,
        },
      });
      this.dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          resolve();
        }
      });
    });
  }

  timeout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.dialogRef = this.dialog.open(DialogsComponent, {
        minWidth: '40vw',
        data: {
          title: 'หมดเวลาการทำข้อสอบ!',
          content: 'ยืนยันการส่งคำตอบ',
          closeBtn: true,
        },
        disableClose: true,
      });
      this.dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          resolve();
        }
      });
    });
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
