<form [formGroup]="form">
  <div class="search input-group">
    <input class="form-control form-search-course" formControlName="keywords" [placeholder]="searchTextPlacholder" />
    <div class="input-group-append">
      <span class="input-group-text search-text">ค้นหา</span>
    </div>
  </div>
  <div class="menu">
    <div class="filter">
      <div class="filter-title">
        <feather-icon name="sliders" class="icon"></feather-icon>
        <span>ตัวกรอง</span>
      </div>
    </div>
    <ng-container *ngIf="!filterCourseDisable">
      <ng-container #dropdown></ng-container>
    </ng-container>

    <div class="filter">
      <div class="filter-select">
        <app-select
          formControlName="education_id"
          placeholder="ระดับชั้น"
          class="full-width"
          [searchable]="false"
          [clearable]="true"
          bindLabel="description"
          bindValue="id"
          url="educations"
        ></app-select>
      </div>
    </div>

    <div class="filter">
      <div class="filter-select">
        <app-select
          formControlName="semester_id"
          placeholder="เทอมที่เรียน"
          class="full-width"
          [searchable]="false"
          [clearable]="true"
          bindLabel="name"
          bindValue="id"
          url="semesters"
        ></app-select>
      </div>
    </div>

    <div class="filter">
      <span class="clear-filter" (click)="clearParams()">ล้างตัวกรอง</span>
    </div>
  </div>
</form>
