<div class="form-group">
  <label>{{ label }}</label>
  <ng-template
    [ngTemplateOutlet]="icon ? (isPhone ? inputIconPhone : inputIcon) : input"
    [ngTemplateOutletContext]="{ nonClass: false }"
  ></ng-template>
  <span class="text-danger error-message">{{ errorMessage | translate }}</span>
</div>

<ng-template #input let-nonClass="nonClass">
  <input
    [ngClass]="[nonClass ? '' : 'form-control', customInputClass, isError ? 'invalid' : '']"
    [type]="type"
    [formControl]="form"
    [name]="name"
    [placeholder]="placeholder"
    [mask]="customMask"
    [allowNegativeNumbers]="negativeNumber"
    [readOnly]="readonly"
    [patterns]="customPattern"
  />
</ng-template>

<ng-template #inputIcon>
  <div class="form-custom input-group" [ngClass]="[customFormClass, disabled ? 'disabled' : '']">
    <feather-icon class="icon" [name]="icon"></feather-icon>
    <ng-template [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{ nonClass: true }"></ng-template>
    <feather-icon
      *ngIf="passwordToggle && !showPassword"
      class="icon-eye"
      name="eye"
      (click)="toggleView()"
    ></feather-icon>
    <feather-icon
      *ngIf="passwordToggle && showPassword"
      class="icon-eye"
      name="eye-off"
      (click)="toggleView()"
    ></feather-icon>
  </div>
</ng-template>

<ng-template #inputIconPhone>
  <div class="form-custom input-group" [ngClass]="[customFormClass, disabled ? 'disabled' : '']">
    <div class="icon-image-phone">
      <img class="icon-image" src="assets/images/icon/th-flag.svg" />
      <span>+66</span>
    </div>
    <ng-template [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{ nonClass: true }"></ng-template>
  </div>
</ng-template>
