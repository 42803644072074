import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParentIndexRoutingModule } from './parent-index-routing.module';
import { ParentIndexComponent } from './parent-index.component';

@NgModule({
  declarations: [ParentIndexComponent],
  imports: [CommonModule, ParentIndexRoutingModule],
})
export class ParentIndexModule {}
