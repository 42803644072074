import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Student } from 'src/app/_store/student/state/student.model';
import { StudentQuery } from 'src/app/_store/student/state/student.query';
import { StudentService } from 'src/app/_store/student/state/student.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
})
export class ManageAccountComponent implements OnInit {
  student$: Observable<Student>;
  form: FormGroup;

  constructor(private studentQuery: StudentQuery, private studentService: StudentService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    //   this.student$ = this.store.select(StudentSelectors.selectCurrentData);
    //   this.student$.subscribe((value) => {
    //     if (value) {
    //       this.form.patchValue(value);
    //     }
    //   });
    // this.studentService.getCurrent().subscribe((res) => {
    //   if (res) {
    //     this.form.patchValue(res);
    //   }
    // });
  }

  initForm(): void {
    this.form = this.fb.group({
      uid: [''],
      email: ['email_myemail@mail.com'],
    });
  }
}
