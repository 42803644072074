import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountDownComponent } from './countdown/countdown.component';
import { CountdownModule } from 'ngx-countdown';
import { FeatherModule } from 'angular-feather';
import { InputComponent } from './input/input.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { SelectComponent } from './select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { TextareaComponent } from './textarea/textarea.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CenterMatmenuDirective } from './directives/center-matmenu.directive';
import { IconModule } from '../utils/icon/icon.module';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SwitchComponent } from './switch/switch.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { PaginatorComponent } from './paginator/paginator.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { MatRadioModule } from '@angular/material/radio';
@NgModule({
  declarations: [
    CountDownComponent,
    InputComponent,
    StarRatingComponent,
    SelectComponent,
    TextareaComponent,
    CenterMatmenuDirective,
    DatepickerComponent,
    SwitchComponent,
    CheckboxComponent,
    UploadImageComponent,
    PaginatorComponent,
    RadioButtonComponent,
  ],
  imports: [
    CommonModule,
    CountdownModule,
    FeatherModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMaskModule,
    MatTooltipModule,
    IconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
  ],
  exports: [
    CountDownComponent,
    InputComponent,
    StarRatingComponent,
    SelectComponent,
    TextareaComponent,
    CenterMatmenuDirective,
    DatepickerComponent,
    SwitchComponent,
    CheckboxComponent,
    UploadImageComponent,
    PaginatorComponent,
    RadioButtonComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class SharedModule {}
