import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from './utils/http-client.intercepter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotifierModule } from 'angular-notifier';
import { notifierDefaultOptions } from './config/notification';
import { NgxMaskModule } from 'ngx-mask';
import { DialogsModule } from './dialogs/dialogs.module';
import { ParentsModule } from './parents/parents.module';
import { ParentIndexModule } from './parents/parent-index/parent-index.module';
import { StudentInfoMonitorModule } from './parents/parent-index/student-info-monitor/student-info-monitor.module';
import { CoursesStorageModule } from './parents/parent-index/courses-storage/courses-storage.module';
import { ParentProfileModule } from './parents/parent-index/parent-profile/parent-profile.module';
import { LayoutConfigService } from './services/layout-config.service';
import { FacebookService, FacebookModule, InitParams } from 'ngx-facebook';
// import { BlogComponent } from './src/app/pages/blog/blog.component';

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default layout config
  return async () => {
    const versionExist = (await appConfig.isVersionExist()).exist;

    if (appConfig.getSavedConfig() === null || !versionExist) {
      appConfig.loadConfigs();
    }
    appConfig.setSassVariable();
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    NotifierModule.withConfig(notifierDefaultOptions),
    DialogsModule,
    ParentsModule,
    ParentIndexModule,
    StudentInfoMonitorModule,
    CoursesStorageModule,
    ParentProfileModule,
    FacebookModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
