import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Cart, CartCourse } from './cart.model';
import { CartStore, CartState } from './cart.store';

@Injectable({ providedIn: 'root' })
export class CartQuery extends Query<CartState> {
  cartItem$ = this.select((state) => state.list.data.course_list);
  cart$ = this.select((state) => state.list.data);

  constructor(protected store: CartStore) {
    super(store);
  }

  isSomeCourseItem(id: number): boolean {
    const arr = this.store.getValue().list.data.course_list;
    return arr.some((e) => e.course_id === id);
  }

  get getPaymentType(): number {
    return this.store.getValue().payment_type;
  }

  get getCartData(): Cart {
    return this.store.getValue().list.data;
  }

  get getCartItem(): number {
    if (this.store.getValue().list.data) {
      return this.store.getValue().list.data.amount;
    } else {
      return 0;
    }
  }

  get getCartCourseItem(): CartCourse[] {
    if (this.store.getValue().list.data != null) {
      return this.store.getValue().list.data.course_list;
    } else {
      return [];
    }
  }
}
