import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-social-signin',
  templateUrl: './social-signin.component.html',
  styleUrls: ['./social-signin.component.scss'],
})
export class SocialSigninComponent implements OnInit {
  constructor(private notifierService: NotifierService) {}

  ngOnInit(): void {}

  onWarning(): void {
    this.notifierService.notify('default', 'ระบบยังไม่พร้อมใช้งาน');
  }
}
