<ng-container [formGroup]="form">
  <div class="content-header">
    <div class="title">จัดการบัญชี</div>
  </div>
  <div class="content-body">
    <div class="change-password">
      <div class="title">เปลี่ยนรหัสผ่าน</div>
      <div class="row">
        <app-input
          class="col-md-12 col-lg-6"
          formControlName="old_password"
          label="รหัสผ่านปัจจุบัน"
          placeholder="รหัสผ่านปัจจุบัน"
          iconGroup="lock"
          type="password"
          [error]="error.old_password"
          [icon]="'lock'"
        ></app-input>
      </div>
      <div class="row">
        <app-input
          class="col-md-12 col-lg-6"
          formControlName="new_password"
          label="รหัสผ่านใหม่"
          placeholder="รหัสผ่านใหม่"
          iconGroup="lock"
          type="password"
          [error]="error.new_password"
          [icon]="'lock'"
        ></app-input>
        <app-input
          class="col-md-12 col-lg-6"
          formControlName="new_password_confirmation"
          label="ยืนยันรหัสผ่าน"
          placeholder="ยืนยันรหัสผ่าน"
          iconGroup="lock"
          type="password"
          [error]="error.new_password_confirmation"
          [icon]="'lock'"
        ></app-input>
      </div>
      <button class="btn btn-primary btn-submit" (click)="changeRequest()" [disabled]="loading">
        ยืนยันเปลี่ยนรหัสผ่าน
      </button>
    </div>
    <hr />
    <div class="reset-password">
      <div class="title">รีเซ็ตรหัสผ่านใหม่</div>
      <div class="btn reset-btn mb-3" (click)="resetRequest()">
        <img src="assets/images/icon/rotate-ccw.svg" />
        <span>ส่งคำขอรีเซ็ตรหัสผ่าน</span>
      </div>
      <p>อีเมลสำหรับรีเซ็ตรหัสผ่านใหม่จะถูกส่งไปยังอีเมลปัจจุบันของคุณกรุณาตรวจสอบความถูกต้องของอีเมลก่อนดำเนินการ</p>
    </div>
  </div>
</ng-container>
