<div class="header">
  <app-header-student-mobile ></app-header-student-mobile>
  <div class="contrainer">
    <div class="brand">
      <!-- <div class="logo" routerLink="/students"></div> -->
      <!-- <img [src]="host + config.logo_2x" class="logo" routerLink="/students" /> -->
      <img src="./assets/images/icon/LOGO_EAZY_CAL_Final-01.png" class="logo" routerLink="/" />
      <div class="student-site">
        <div class="icon">
          <img src="assets/images/icon/home.svg" alt="home-icon" />
        </div>
        <div class="content-text">{{ "LAYOUTS.HEADERS.HEADER_STUDENT.CLASSROOM" | translate }}</div>
      </div>
    </div>

    <div class="nav-bar">
      <ng-container [ngTemplateOutlet]="menuNav"></ng-container>
    </div>
  </div>
</div>

<ng-template #menuNav>
  <div class="nav-menu">
    <div class="menu">
      <ul>
        <li
          [hidden]="menu.isMobile"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngFor="let menu of menuList$ | async"
        >
          <a [hidden]="!menu.disabled">{{ menu.name | translate }}</a>
          <a [hidden]="menu.disabled" [routerLink]="menu.path">{{ menu.name | translate }}</a>
        </li>
      </ul>
    </div>

    <div class="divider-line"></div>

    <div class="cart">
      <app-cart></app-cart>
    </div>

    <div class="notification">
      <i-feather name="bell" class="icon"></i-feather>
    </div>

    <app-user-auth [isStudentSite]="true" [isMobile]="true"></app-user-auth>
  </div>
</ng-template>
