import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { NotifierService } from 'angular-notifier';
import { DialogService } from 'src/app/services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

export enum VerifyId {
  VERIFIED = 1,
  CANCEL = 2,
  WATING = 3,
  DONE = 4,
  RESENT = 5,
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  @Output() submitted = new EventEmitter();
  @Input()
  set status(value: VerifyId) {
    if (value) {
      this.verifyStatus = value;
    }
  }

  email = new FormControl(null, [Validators.required, Validators.email]);
  subscriptions: Subscription[] = [];
  error = {} as any;
  verifyStatus: VerifyId = VerifyId.WATING;
  token: string;
  waitingTime: number = 120;
  timeSub: Subscription;
  onWaiting = false;

  get verifyId(): typeof VerifyId {
    return VerifyId;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private notifierService: NotifierService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const email = await this.getEmail();
    this.email.patchValue(email);
    if (this.verifyStatus !== VerifyId.RESENT) {
      await this.tokenRecieve();
      this.verifyEmail();
    }
  }

  async getEmail(): Promise<void> {
    return new Promise<void>((resolve) => {
      const email = this.authService.getLogin()?.email || this.route.snapshot.queryParams.email;
      resolve(email);
    });
  }

  async tokenRecieve(): Promise<void> {
    return new Promise<void>((resolve) => {
      const token = this.route.snapshot.queryParams.token;
      if (token) {
        this.token = token;
        resolve();
      } else {
        this.notifierService.notify('error', 'ไม่มีโทเคน');
        this.verifyStatus = this.verifyId.CANCEL;
      }
    });
  }

  verifyEmail(): void {
    this.authService
      .verifyUser(this.token)
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.verifyStatus = this.verifyId.VERIFIED;
          setTimeout((res) => {
            this.router.navigate(['']);
            this.submitted.emit(true);
          }, 3000);
        }
      })
      .catch((err) => {
        if (err) {
          switch (err.error.message) {
            case 'token not found.':
              this.verifyStatus = this.verifyId.CANCEL;
              break;
            case 'token is expire.':
              this.verifyStatus = this.verifyId.CANCEL;
              break;
            case 'user already activate.':
              this.verifyStatus = this.verifyId.DONE;
              setTimeout((res) => {
                this.router.navigate(['']);
                this.submitted.emit(true);
              }, 3000);
          }
        }
      });
  }

  async resentRequest() {
    this.error = {} as any;
    if (this.email.invalid) {
      this.error = { email: this.email.errors };
      return;
    }
    const email = this.email.value;

    await this.timeout();
    this.authService
      .resentVerifyUser(email)
      .toPromise()
      .then(async (res) => {
        this.verifyStatus = this.verifyId.RESENT;
        this.notifierService.notify('success', 'สำเร็จ!');
        this.waitingTime = 120;
        this.countdown();
      })
      .catch(async (err) => {
        this.error.email = err.error.errors;
        this.notifierService.notify('error', this.error.email);
      });
  }

  timeout() {
    return new Promise<void>((resolve) => {
      this.spinnerService.show();
      setTimeout((res) => {
        this.spinnerService.hide();
        resolve();
      }, 3000);
    });
  }

  countdown(): void {
    this.onWaiting = true;
    if (this.waitingTime > 0) {
      this.timeSub = interval(1000).subscribe(() => {
        this.waitingTime -= 1;
      });
    } else {
      this.onWaiting = false;
      this.timeSub.unsubscribe();
    }
  }
}
