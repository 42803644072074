import { ParentingInteraction } from './../../under-parenting/under-parenting.model';
import { Injectable } from '@angular/core';
import { action } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { UserStore } from './user.store';
import { Parent, User } from './user.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UnderParentingService } from '../../under-parenting/under-parenting.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private userStore: UserStore,
    private http: HttpClient,
    private underParentingService: UnderParentingService
  ) {}

  @action('Get Current')
  getCurrent(): Observable<User> {
    return this.http.get<User>('/users/current').pipe(
      tap((user: User) => {
        this.userStore.update({ current: user });
      })
    );
  }

  @action('Update Current Parent')
  updateParent(payload: Parent): Observable<Parent> {
    const formData = new FormData();
    for (const key in payload) {
      if (payload[key]) {
        formData.append(`parent[${key}]`, payload[key]);
      }
    }
    return this.http.put<Parent>(`/parents/${payload.id}`, formData).pipe(
      tap((response: Parent) => {
        this.userStore.update((state) => ({
          current: {
            ...state.current,
            data: response,
          },
        }));
      })
    );
  }

  @action('Clear Current')
  clearCurrent(): void {
    this.userStore.update({ current: null });
  }

  addStudentUnderParenting(payload: ParentingInteraction): Observable<any> {
    return this.http.post(`/parenting_interactions`, { parenting_interaction: payload }).pipe(
      tap((res) => {
        this.underParentingService.get().subscribe();
      })
    );
  }

  getCodeInvitation(): Observable<any> {
    return this.http.get(`/parent_invitations`);
  }

  changePassword(payload: any): Observable<any> {
    return this.http.post(`/change_password`, payload);
  }
}
