import { Component, OnInit, Input, OnDestroy, forwardRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UploadImageComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class UploadImageComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
  imageUrl$ = new BehaviorSubject(null);
  imageName$ = new BehaviorSubject(null);
  host = environment.apiUrl.replace('/api', '');

  @Input()
  set imageValue(data: string) {
    if (!data) {
      return;
    }
    this.imageUrl$.next(`${this.host}${data}`);
  }
  @Input() isDisabled = false;

  @Input() pattern = 'withAvatar';

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];
      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = value;
      }
    }
  }

  isError = false;
  errorMessage = '';
  form = new FormControl();
  subscription: Subscription;

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      const value = this.form.value;
      this.onChange(value);
      !value && this.removeImage();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  processFile(imageInput: any): void {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    if (file) {
      this.form.setValue(file);
      this.imageName$.next(file.name);

      reader.onload = (event) => {
        this.imageUrl$.next(reader.result);
      };

      reader.readAsDataURL(file);
    }
  }

  removeImage() {
    (<HTMLInputElement>document.getElementById('image-input')).value = '';
    this.imageUrl$.next(null);
    this.form.patchValue('delete!');
  }

  onClick(): void {
    if (this.isDisabled) {
      return;
    }
    document.getElementById('image-input').click();
  }
}
