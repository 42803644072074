<div class="auth-dialog">
  <div class="auth-dialog-bg">
    <div class="bg-content">
      <div class="bg-title">{{ "AUTH.FORGET_PASSWORD.TITLE" | translate }}</div>
      <div class="bg-title">{{ "APP_TITLE" | translate }}</div>
      <div class="bg-line"></div>
      <div class="bg-description">{{ "AUTH.FORGET_PASSWORD.DESCRIPTION" | translate }}</div>
    </div>
  </div>
  <div class="auth-dialog-content">
    <div class="forgot-page">
      <div class="text-description">{{ "AUTH.FORGET_PASSWORD.SUGGESTION" | translate }}</div>
      <form [formGroup]="form">
        <div class="row forgot-form">
          <div class="col-lg-12 col-sm-12">
            <app-input label="Email" placeholder="email" formControlName="email" [error]="error.email"></app-input>
          </div>
        </div>
      </form>
      <div class="forgot-footer">
        <button class="btn btn-primary btn-reset" (click)="resetRequest()">
          {{ "AUTH.FORGET_PASSWORD.RESET" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
