<div class="auth-dialog">
  <div class="auth-dialog-bg">
    <div class="bg-content">
      <div class="bg-title">สมัครรับบัญชีใช้งาน {{ "APP_TITLE" | translate }}</div>
      <div class="bg-line"></div>
      <div class="bg-description">สมัครเพื่อรับคอร์สเรียนฟรีของเราและเรียกดูสิ่งที่คุณสนใจ</div>
    </div>
  </div>
  <div class="auth-dialog-content">
    <div class="signup-course-page">
      <div class="title">ระดับชั้นของผู้เรียน</div>
      <div class="body">
        <div class="course-item">
          <div class="course-title">มัธยมศึกษาตอนต้น</div>
          <div class="course-list">
            <div class="course-list-item active">มัธยมศึกษาปีที่ 1</div>
            <div class="course-list-item">มัธยมศึกษาปีที่ 2</div>
            <div class="course-list-item">มัธยมศึกษาปีที่ 3</div>
          </div>
        </div>
        <div class="course-item">
          <div class="course-title">มัธยมศึกษาตอนปลาย</div>
          <div class="course-list">
            <div class="course-list-item">มัธยมศึกษาปีที่ 4</div>
            <div class="course-list-item">มัธยมศึกษาปีที่ 5</div>
            <div class="course-list-item">มัธยมศึกษาปีที่ 6</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button class="btn btn-secondary btn-get-started">เริ่มต้นใช้งาน</button>
      </div>
    </div>
  </div>
</div>
