import {
  Component,
  OnInit,
  NgModule,
  OnDestroy,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  Injector,
  ViewContainerRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from 'src/app/utils/icon/icon.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscriptions: Subscription[] = [];

  isShowDropdown = false;

  @Input() filterCourseDisable = false;
  @Input() searchTextPlacholder = 'ค้นหาคอร์สเรียน';
  @Output() searchChange = new EventEmitter();

  @ViewChild('dropdown', { read: ViewContainerRef }) dropdownContainer: ViewContainerRef;

  constructor(private fb: FormBuilder, private cfr: ComponentFactoryResolver, private injector: Injector) {}

  async ngOnInit(): Promise<void> {
    this.buildForm();

    if (!this.filterCourseDisable) {
      const { DropdownComponent } = await import('../dropdown/dropdown.component');
      const factory = this.cfr.resolveComponentFactory(DropdownComponent);
      const { instance } = this.dropdownContainer.createComponent(factory, null, this.injector);
      instance.countActiveChange.pipe(untilDestroyed(this)).subscribe((ids: string) => {
        this.form.patchValue({ Course_category_id: ids });
      });
    } else {
      // this.form.patchValue({ course_category_ids: 1 });
    }

    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged()).subscribe((val) => {
      this.searchChange.emit(val);
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      keywords: [null],
      id: [null],
      tutor_id: [null],
      Course_category_id: [null],
      education_id:[null],
      semester_id:[null]
    });
  }

  ngOnDestroy(): void {}

  clearParams(): void {
    this.form.reset();
    // this.store.dispatch(CourseActions.ParamsClearAction());
  }
}
@NgModule({
  declarations: [SearchComponent],
  imports: [CommonModule, IconModule, SharedModule, NgSelectModule, ReactiveFormsModule, MatCheckboxModule],
  exports: [SearchComponent],
})
export class SearchModule {}
