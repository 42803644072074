<ng-container *ngIf="{ page: page, totalPage: totalPage } as paginator">
  <div class="paginator">
    <ng-container *ngIf="paginator.totalPage > 0; else notFoundTemplate">
      <ng-container
        [ngTemplateOutlet]="paginatorTemplate"
        [ngTemplateOutletContext]="{ paginator: paginator }"
      ></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #paginatorTemplate let-paginator="paginator">
  <div class="left-arrow" (click)="previous()"><i class="fas fa-chevron-left fa-lg"></i></div>
  <div class="order">
    <ng-container *ngIf="paginator.page > 1">
      <div (click)="selected(1)">1</div>
    </ng-container>

    <ng-container *ngIf="paginator.page - 1 > 1">
      <div (click)="selected(paginator.page - 1)">{{ paginator.page - 1 }}</div>
    </ng-container>

    <div class="active">{{ paginator.page }}</div>

    <ng-container *ngIf="paginator.page + 1 < paginator.totalPage">
      <div (click)="selected(paginator.page + 1)">{{ paginator.page + 1 }}</div>
    </ng-container>

    <ng-container *ngIf="paginator.page + 2 < paginator.totalPage">
      <div>...</div>
    </ng-container>

    <ng-container *ngIf="paginator.page < paginator.totalPage">
      <div (click)="selected(paginator.totalPage)">{{ paginator.totalPage }}</div>
    </ng-container>
  </div>
  <div class="rigth-arrow" (click)="next()"><i class="fas fa-chevron-right fa-lg"></i></div>
</ng-template>

<ng-template #notFoundTemplate> ไม่พบรายการ </ng-template>
