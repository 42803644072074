<ng-container [formGroup]="form">
  <div class="content-header">
    <div class="title">การแจ้งเตือน</div>
    <div class="btn-group">
      <button class="btn btn-light">ยกเลิก</button>
      <button class="btn btn-submit">บันทึกการเปลี่ยนแปลง</button>
    </div>
  </div>
  <div class="content-body">
    <div class="subscription">
      <div class="notify">
        <div class="notify-header">
          <div class="notify-title">การแจ้งเตือนผ่านอีเมล</div>
          <div class="notify-detail">รับข่าวสารและประกาศต่างๆ ผ่านอีเมลที่คุณลงทะเบียนไว้</div>
        </div>
        <app-switch formControlName="notify1"></app-switch>
      </div>
      <div class="notify">
        <div class="notify-header">
          <div class="notify-title">การแจ้งเตือนผ่านบราวเซอร์</div>
          <div class="notify-detail">พุชการแจ้งการผ่านบราวเซอร์ (รองรับ Google Chrome)</div>
        </div>
        <app-switch formControlName="notify2"></app-switch>
      </div>
    </div>

    <hr />

    <div class="notification-recieve">
      <div class="title mb-3">รายการแจ้งเตือน</div>
      <div class="recieve-item">
        <app-checkbox formControlName="notify3"></app-checkbox>
        <div class="recieve-header">
          <div class="recieve-title">อัปเดตต่างๆ จากคณิตบ้านอาบูลย์</div>
          <div class="recieve-detail">ประกาศต่างๆ, กิจกรรม, และอื่นๆ</div>
        </div>
      </div>
      <div class="recieve-item">
        <app-checkbox formControlName="notify4"></app-checkbox>
        <div class="recieve-header">
          <div class="recieve-title">คอร์สแนะนำสำหรับคุณ</div>
          <div class="recieve-detail">แนะนำคอร์สเรียนอื่นๆ ที่คุณอาจจะสนใจ</div>
        </div>
      </div>
      <div class="recieve-item">
        <app-checkbox formControlName="notify5"></app-checkbox>
        <div class="recieve-header">
          <div class="recieve-title">การตอบกลับในคอมเม้นต์คำถาม</div>
          <div class="recieve-detail">เมื่อมีการตอบกลับในคำถามที่คุณตั้งไว้</div>
        </div>
      </div>
      <div class="recieve-item">
        <app-checkbox formControlName="notify6"></app-checkbox>
        <div class="recieve-header">
          <div class="recieve-title">อัปเดตจากติวเตอร์และคอร์สเรียน</div>
          <div class="recieve-detail">
            การอัปเดตเนื้อหา, แก้ไขปรับปรุงเนื้อหาสำหรับคอร์สเรียนที่คุณกำลังเรียนหรือเรียนผ่านไปแล้ว
          </div>
        </div>
      </div>
      <div class="recieve-item">
        <app-checkbox formControlName="notify7"></app-checkbox>
        <div class="recieve-header">
          <div class="recieve-title">อัปเดตคอร์สเรียนของคุณ</div>
          <div class="recieve-detail">เวลาเรียน, วันหมดอายุ, การเพิ่มใหม่จากผู้ปกครอง, และอื่นๆ</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
