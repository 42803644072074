import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AuthComponent } from 'src/app/auth/auth.component';
import { Auth } from 'src/app/_store/auth/state/auth.model';
import { AuthQuery } from 'src/app/_store/auth/state/auth.query';
import { AuthService } from 'src/app/_store/auth/state/auth.service';
import { CartService } from 'src/app/_store/cart/cart.service';
import { StudentService } from 'src/app/_store/student/state/student.service';
import { RoleIds } from 'src/app/_store/user/state/user.enum';
import { User } from 'src/app/_store/user/state/user.model';
import { UserQuery } from 'src/app/_store/user/state/user.query';
import { UserService } from 'src/app/_store/user/state/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-auth',
  templateUrl: './user-auth.component.html',
  styleUrls: ['./user-auth.component.scss'],
})
export class UserAuthComponent implements OnInit, OnDestroy {
  user$: Observable<User>;

  host = environment.apiUrl.replace('/api', '');
  isAuthenticated = false;
  RoleIds = RoleIds;

  @Input() isStudentSite = false;
  @Input() isMobile = false;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService,
    private studentService: StudentService,
    private userQuery: UserQuery,
    private authQuery: AuthQuery,
    private notifierService: NotifierService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initAuth();
    this.initUser();
  }

  ngOnDestroy(): void {}

  initAuth(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.loadUser();

    const isAuthenticate$ = this.authQuery.selectIsAuthenticate$;
    isAuthenticate$.pipe(untilDestroyed(this), skip(1)).subscribe((isAuth) => {
      this.isAuthenticated = isAuth;
      this.loadUser();
    });
  }

  initUser(): void {
    this.user$ = this.userQuery.current$;
  }

  loadUser(): void {
    if (this.isAuthenticated) {
      this.userService.getCurrent().subscribe();
    }
  }

  /**
   *
   *  @Action
   */
  onSignIn(): void {
    const dialogRef = this.dialog.open(AuthComponent, {
      width: '880px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(async (result: Auth) => {
      const routePath = this.getOnlyRoute(this.router.url);
      if (result) {
        await this.cartService.get().toPromise();
        !routePath && this.goToSite(result);
      }
    });
  }

  onSingOut(): void {
    this.authService.signout();
    this.userService.clearCurrent();
    this.studentService.clearCurrent();
    this.cartService.clearCurrent();
    this.notifierService.notify('success', 'ออกจากระบบสำเร็จ');
    this.router.navigateByUrl('/');
  }

  getOnlyRoute(url: string): string {
    try {
      const tree: UrlTree = this.router.parseUrl(url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      const s: UrlSegment[] = g.segments;
      return s[0].path;
    } catch {
      return null;
    }
  }

  goToSite(value: Auth): void {
    switch (value.user.role.id) {
      case RoleIds.STUDENT:
        this.router.navigateByUrl('students');
        break;
      case RoleIds.PARENT:
        this.router.navigateByUrl('parents');
        break;
    }
  }
}
