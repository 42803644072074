import { Component, OnInit, Input, OnDestroy, forwardRef, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LabelMode } from '../_enums/label-mode.enum';
import { DatepickerMode } from '../_enums/datepicker-mode.enum';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  scrollStrategy: ScrollStrategy;

  @Input()
  set isDisabled(value: boolean) {
    this.disabled = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];
      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = value;
      }
    }

  }

  @Input() name = '';
  @Input() placeholder = '';
  @Input() label: string;
  @Input() readonly: boolean;
  @Input() maxDate: Date;

  @Input() pattern = 'withIcon';

  LabelMode = LabelMode;
  DatepickerMode = DatepickerMode;
  mode: LabelMode = LabelMode.DEFAULT;

  constructor(private dateAdapter: DateAdapter<Date>, private readonly sso: ScrollStrategyOptions) {
    this.dateAdapter.setLocale('th');
    this.scrollStrategy = this.sso.noop();//.reposition();
  }

  form: FormControl = new FormControl('');
  subscription: Subscription;
  disabled = false;
  errorMessage = '';
  isRequired = false;
  isError = false;

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      this.onChange(this.form.value ? moment(val).format('YYYY-MM-DD') : this.form.value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value ? new Date(value) : null);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onKeyDown(event): void {
    if (event.keyCode === 8) {
      this.form.setValue(null);
      return;
    }
    event.preventDefault();
  }
}
