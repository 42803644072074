import { result } from 'lodash';
import {
  Component,
  OnInit,
  forwardRef,
  ViewEncapsulation,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { GenerateRandom } from 'src/app/utils/rendom';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { LabelMode } from '../_enums/label-mode.enum';
import { HttpClient } from '@angular/common/http';

export interface RadioOption {
  id: number;
  name: string;
}

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioButtonComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';
    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];

      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = value;
      }
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() readonly = false;
  @Input() label: string;
  @Input() inputLabel: string;
  @Input()
  set options(value: RadioOption[]) {
    this.options$.next(value);
  }
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  @Input()
  set url(value: string) {
    this.apiUrl = value;
    this.setOptions();
  }

  @Input()
  set params(value: any) {
    this.apiParams = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.INLINE;

  form = new FormControl();
  subscription: Subscription;
  errorMessage = '';
  isRequired = false;
  isError = false;
  apiUrl = '';
  apiParams: any = {};
  options$ = new BehaviorSubject([]);

  @Output() valueChange = new EventEmitter();

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      const value = this.form.value;
      this.onChange(value);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelect(res) {
    this.valueChange.emit(res.value);
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  getHttp(params?): Observable<any> {
    return this.http.get('/' + this.apiUrl, { params }).pipe(catchError(() => of([])));
  }

  setOptions(): void {
    this.getHttp().subscribe((res) => {
      if (res) {
        this.options$.next(res);
      }
    });
  }
}
