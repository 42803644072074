<div class="course-page" [formGroup]="form">
  <div class="search input-group">
    <input class="form-control form-search-course" formControlName="keywords" placeholder="ค้นหาคอร์สเรียน" />
    <div class="input-group-append">
      <span class="input-group-text search-text">ค้นหา</span>
    </div>
  </div>

  <!-- <div class="menu">
    <div class="filter">
      <div class="filter-title">
        <i class="fas fa-sliders-h"></i>
        <span>ตัวกรอง</span>
      </div>
    </div>

    <div class="filter">
      <div class="filter-select">
        <app-select
          class="custom-default"
          name="education_id"
          formControlName="education_id"
          [searchable]="false"
          bindLabel="name"
          bindValue="id"
          [placeholder]="'ระดับชั้น'"
          url="educations"
        ></app-select>
      </div>
    </div>

    <div class="filter">
      <div class="filter-select">
        <ng-select
          class="custom-default"
          name="year"
          formControlName="year"
          bindLabel="name"
          bindValue="id"
          [searchable]="false"
          placeholder="ปี"
          [items]="years"
        ></ng-select>
      </div>
    </div>

    <div class="filter">
      <span class="text-danger">ล้างตัวกรอง</span>
    </div>
  </div> -->

  <div class="course-group">
    <div class="course-group-list" *ngFor="let item of courseList; index as i">
      <div class="course-box" *ngIf="item?.amount > 0">
        <div class="course-item">
          <img *ngIf="item.course_image_url" [alt]="item.course_name" [src]="host + item.course_image_url" />
          <img *ngIf="!item.course_image_url" [alt]="item.course_name" [src]="coursePreview" />
          <div class="course-detail">
            <div class="detail-title">{{ item.course_name }}</div>

            <div class="devide-line-default"></div>
            <div class="course-open" (click)="giveCourse(item)">ให้คอร์สเรียน</div>
          </div>
        </div>
      </div>

      <div class="course-box" *ngIf="item.student?.length > 0" (click)="manageCourse(item)">
        <div class="course-item">
          <img *ngIf="item.course_image_url" [alt]="item.course_name" [src]="host + item.course_image_url" />
          <img *ngIf="!item.course_image_url" [alt]="item.course_name" [src]="coursePreview" />
          <div class="course-detail">
            <div class="detail-title">{{ item.course_name }}</div>

            <div class="devide-line-default"></div>
            <div class="course-studying">
              <span>นักเรียนที่กำลังเรียน</span>
              <div class="student-studying">
                <ng-container *ngFor="let student of item.student; index as i">
                  <img
                    *ngIf="student.student_image_url"
                    [alt]="student.student_full_name"
                    [src]="host + student.student_image_url"
                  />
                  <img
                    *ngIf="!student.student_image_url"
                    [alt]="student.student_full_name"
                    src="/assets/images/default.jpg"
                  />
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12" *ngIf="courseList.length === 0">
      <div class="load-more">ไม่พบรายการ</div>
    </div>

    <div class="load-more" *ngIf="isLoadMore">
      <button>ดูเพิ่มเติม</button>
    </div>
  </div>
</div>
