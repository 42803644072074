import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UnderParentingStore, UnderParentingState } from './under-parenting.store';

@Injectable({ providedIn: 'root' })
export class UnderParentingQuery extends QueryEntity<UnderParentingState> {
  list$ = this.select('list');

  constructor(protected store: UnderParentingStore) {
    super(store);
  }

  get getList() {
    return this.store.getValue().list;
  }
}
