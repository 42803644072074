<ng-container [formGroup]="form">
  <div class="content-header">
    <div class="title">จัดการนักเรียน</div>
  </div>
  <div class="content-body">
    <div class="link-invitation">
      <app-input
        [ngClass]="{ invalid: error ? true : false }"
        class="copy-link-text mb-3"
        formControlName="link"
        label="กรอกลิงค์คำเชิญนักเรียน"
        placeholder="ลิงค์คำเชิญ"
        [isDisabled]="false"
        [error]="error?.link"
      ></app-input>
      <div class="copy-link" (click)="addStudent()">
        <button>เพิ่มนักเรียน</button>
      </div>
    </div>
    <hr />

    <div class="students-list">
      <div class="title mb-3">นักเรียนทั้งหมด</div>
      <ng-container *ngIf="underParentingQuery.getList?.length === 0"> ยังไม่มีการเชื่อมต่อกับนักเรียน </ng-container>
      <ng-container *ngFor="let student of underParentingQuery.getList">
        <app-student-item [data]="student"></app-student-item>
      </ng-container>
    </div>
  </div>
</ng-container>
