<div class="social-media">
  <div class="content-youtube">
    <button class="btn btn-outlet btn-primary youtube-content d-flex align-items-center gap-50 min-w-120" (click)="onYoutube()">
      <i-feather name="youtube" class="youtube-icon"></i-feather>Eazycal
    </button>
  </div>
  <div class="content-social">
    <div class="social-title">
      <i-feather name="share-2" class="share-icon"></i-feather>
      <span class="share-text">{{ "SOCIAL.SHARE" | translate }}</span>
    </div>
    <div class="social-icon">
      <div class="social-item" (click)="showTooltip()">
        <i-feather name="copy" class="icon"></i-feather>
      </div>
      <span class="tooltip" matTooltip="คัดลอกแล้ว!" style="margin-left: 15px; margin-top: 30px;">
      </span>
      <!-- <img src="assets/images/social/facebook.png" class="img-social" alt="social-facebook" (click)="onFacebook()" /> -->
      <img src="assets/images/social/facebook.svg" class="img-social" alt="social-facebook" (click)="shareFacebook()" />
      <!-- <img src="assets/images/social/twitter.svg" class="img-social" alt="social-twitter" /> -->
      <img src="assets/images/social/line_social.svg" class="img-social" alt="social-line" (click)="onLine()" />
    </div>
  </div>
</div>