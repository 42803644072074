import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  sessionItem$ = new BehaviorSubject<string>('');

  constructor() {}

  updateSession(value: any): void {
    const session = window.sessionStorage;
    const newValue = {
      ...this.sessionData,
      ...value,
    };
    session.setItem(this.sessionItem, JSON.stringify(newValue));
  }

  setSession(value: any): void {
    const session = window.sessionStorage;
    session.setItem(this.sessionItem, JSON.stringify(value));
  }

  get sessionData(): any {
    const session = window.sessionStorage;
    const obj = JSON.parse(session.getItem(this.sessionItem));
    return obj;
  }

  get sessionItem(): any {
    return this.sessionItem$.getValue();
  }

  setSessionItem(str: string): void {
    this.sessionItem$.next(str);
  }

  reset(): void {
    const session = window.sessionStorage;
    session.clear();
  }
}
