import { ValidationErrors } from '@angular/forms';

export class ErrorMessage {
  getErrorMessage(error: ValidationErrors | any): string {
    const errorKey = Object.keys(error)[0];
    switch (errorKey) {
      case 'required':
        return 'กรุณากรอกข้อมูล';
      case 'minlength':
        return 'จำนวนตัวอักษรขั้นต่ำ ' + error.minlength.requiredLength + ' ตัวอักษร';
      case 'maxlength':
        return 'จำนวนตัวอักษรไม่เกิน ' + error.maxlength.requiredLength + ' ตัวอักษร';
      case 'required':
        return 'VALIDATOR.REQUIRED';
      case 'email':
        return 'VALIDATOR.EMAIL';
      case 'mustMatch':
        return 'VALIDATOR.MUST_MATCH';
      case 'thaiNationalID':
        return 'VALIDATOR.THAI_NATION_ID';
      default:
        return this.getServerErrorMessage(error);
    }
  }

  getServerErrorMessage(value): string {
    if (Array.isArray(value)) {
      const error = this.getErrorMessage(value[0]);
      if (!Array.isArray(error)) {
        return error;
      }
    } else {
      return value;
    }
  }
}
