<ng-template [ngTemplateOutlet]="isMobile ? mobile : default"> </ng-template>

<ng-template #default>
  <ng-container *ngIf="!isAuthenticated">
    <button class="btn btn-primary-linear min-w-120" (click)="onSignIn()">{{ "MENU.BTN_SIGNIN" | translate }}</button>
  </ng-container>

  <ng-container *ngIf="isAuthenticated">
    <div class="user" *ngIf="user$ | async as user">
      <!-- <img
        [center-mat-menu]="menuTrigger"
        [src]="user.data.image_url ? host + user.data.image_url : '/assets/images/default.jpg'"
      /> -->
      <img [matMenuTriggerFor]="menu" [src]="user.data.image_url ? host + user.data.image_url : '/assets/images/default.jpg'" />
    </div>
  </ng-container>
</ng-template>

<ng-template #mobile>
  <div class="d-flex justify-content-end gap-50">
    <button class="btn btn-outlet btn-primary min-w-120 d-none d-md-block" [routerLink]="'/courses'">{{ "MENU.COURSE" | translate }}</button>
    <ng-container *ngIf="!isAuthenticated">
      <div class="text-primary user-auth d-md-none" (click)="onSignIn()">{{ "MENU.BTN_SIGNIN" | translate }}</div>
      <button class="btn btn-primary-linear min-w-120 d-none d-md-block" (click)="onSignIn()">{{ "MENU.BTN_SIGNIN" | translate }}</button>
    </ng-container>
    <ng-container *ngIf="isAuthenticated">
      <div class="user" *ngIf="user$ | async as user">
        <img [matMenuTriggerFor]="menu" [src]="user.data.image_url ? host + user.data.image_url : '/assets/images/default.jpg'" />
      </div>
    </ng-container>
  </div>
</ng-template>

<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
  <mat-menu #menu="matMenu">
    <div class="menu-dropdown" *ngIf="user$ | async as user">
      <div class="menu-title">สวัสดีคุณ, {{ user.data.first_name }}</div>

      <ng-container *ngIf="user.role.id === RoleIds.STUDENT">
        <div class="menu-nav" *ngIf="!isStudentSite" [routerLink]="'/students'">
          <feather-icon name="monitor" class="icon"></feather-icon>
          <span>ห้องเรียน</span>
        </div>
      </ng-container>

      <ng-container *ngIf="user.role.id === RoleIds.PARENT">
        <div class="menu-nav" *ngIf="!isStudentSite" [routerLink]="'/parents'">
          <feather-icon name="users" class="icon"></feather-icon>
          <span>ส่วนของผู้ปกครอง</span>
        </div>
      </ng-container>

      <div class="menu-nav" routerLink="/my-order">
        <feather-icon name="file" class="icon"></feather-icon>
        <span>คำสั่งซื้อของฉัน</span>
      </div>
      <div class="signout" (click)="onSingOut()">
        <span>ออกจากระบบ</span>
      </div>
    </div>
  </mat-menu>
</div>