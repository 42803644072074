import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/_store/user/state/user.model';
import { UserQuery } from 'src/app/_store/user/state/user.query';
import { environment } from 'src/environments/environment';

enum TabMenuParentIds {
  STUDENT_INFO_MONITOR = 1,
  COURSE_STORAGE = 2,
  PROFILE = 3,
}

@Component({
  selector: 'app-parent-index',
  templateUrl: './parent-index.component.html',
  styleUrls: ['./parent-index.component.scss']
})
export class ParentIndexComponent implements OnInit {
  parent$: Observable<User>;
  host = environment.apiUrl.replace('/api', '');

  tabMenuList = [
    { name: 'ข้อมูลนักเรียน', id: TabMenuParentIds.STUDENT_INFO_MONITOR, route: 'student-info-monitor' },
    { name: 'คอร์สเรียน', id: TabMenuParentIds.COURSE_STORAGE, route: 'course-storage' },
    { name: 'บัญชีและโปรไฟล์', id: TabMenuParentIds.PROFILE, route: 'profile' },
  ];

  constructor(private userQuery: UserQuery) { }

  ngOnInit(): void {
    this.initPerentCurent();
  }

  initPerentCurent(): void {
    this.parent$ = this.userQuery.current$;
  }

}
