import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BlogServiceCategoryID {
  
constructor(private http: HttpClient) { }
private reloadSubject = new BehaviorSubject<boolean>(false);
 private storageKey = 'mySessionData';
private Blogid = 'blogid';
 private dataSubject = new BehaviorSubject<string>(sessionStorage.getItem(this.storageKey));

private dataBlogid = new BehaviorSubject<string>(sessionStorage.getItem(this.Blogid));

 getAllCategoryBlog() {
     return this.http.request('GET',`/blog_categories`);
  }
  getBlogOnCategory(id:string) {
     return this.http.request('GET',`/blogs/${id}`);
  }
   getData() {
    return this.dataSubject.asObservable();
  }
    getBlogId() {
    return this.dataBlogid.asObservable();
  }
   setData(data: string) {
    sessionStorage.setItem(this.storageKey, data);
    this.dataSubject.next(data);
    
  }
  setBlogId(data: string){
    sessionStorage.setItem(this.Blogid, data);
    this.dataBlogid.next(data);

}
}
