<div class="course-page">
  <div class="course-current">
    <div>
      <div class="course-current-title">ที่เพิ่งเรียนล่าสุด</div>
      <div class="course-current-content">
        <div class="tumb-video">
          <!-- <img [src]="host + courseLatest.student_course.course_image_url" /> -->
        </div>
        <div class="content">
          <!-- <div class="content-title">
          บทที่ {{ courseLatest.course_chapter_group_order_index + 1 }} Chapter
          {{ courseLatest.course_chapter_order_index + 1 }} (ล่าสุดเมื่อ {{ courseLatest.updated_at | timeInterval }})
        </div> -->
          <div class="content-title">
            <!-- บท {{ courseLatest.course_chapter_group_name }} Chapter {{ courseLatest.course_chapter_name }} (ล่าสุดเมื่อ -->
            <!-- {{ courseLatest.updated_at | timeInterval }}) -->
          </div>
          <!-- <div class="content-subtitle">{{ courseLatest.student_course.course_name }}</div> -->
          <div>สอนโดย</div>
          <div class="content-footer">
            <div class="tutor-group">
              <div class="tutor">
                <!-- <img *ngIf="item.tutor.image_url" [src]="host + item.tutor.image_url" /> -->
                <img src="assets/images/default.jpg" />

                <!-- <span>{{ item.tutor.name }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search input-group">
    <input class="form-control form-search-course" placeholder="ค้นหาคอร์สเรียน" />
    <div class="input-group-append">
      <span class="input-group-text search-text">ค้นหา</span>
    </div>
  </div>

  <div class="menu">
    <div class="filter">
      <div class="filter-title">
        <i class="fas fa-sliders-h"></i>
        <span>ตัวกรอง</span>
      </div>
    </div>

    <div class="filter">
      <div class="filter-select">
        <!-- <app-select
          class="custom-default"
          name="education_id"
          formControlName="education_id"
          [searchable]="false"
          bindLabel="name"
          bindValue="id"
          [placeholder]="'ระดับชั้น'"
          url="educations"
        ></app-select> -->
      </div>
    </div>

    <div class="filter">
      <div class="filter-select">
        <!-- <ng-select
          class="custom-default"
          name="year"
          formControlName="year"
          bindLabel="name"
          bindValue="id"
          [searchable]="false"
          placeholder="ปี"
          [items]="years"
        ></ng-select> -->
      </div>
    </div>

    <div class="filter">
      <span class="text-danger">ล้างตัวกรอง</span>
    </div>
  </div>

  <div class="course-group">
    <div class="course-group-list">
      <div class="course-box">
        <div class="course-item">
          <img />
          <div class="course-detail">
            <!-- <div class="detail-title">{{ item.course_name }}</div> -->

            <div class="detail-line"></div>
            <div class="detail-footer">
              <div class="content">
                <div class="content-title">ความคืบหน้า</div>
                <div class="content-progress">
                  <!-- <mat-progress-bar
                  *ngIf="item.progress <= 34"
                  class="success"
                  mode="determinate"
                  [value]="item.progress"
                ></mat-progress-bar>
                <mat-progress-bar
                  *ngIf="item.progress > 34 && item.progress <= 68"
                  class="warning"
                  mode="determinate"
                  [value]="item.progress"
                ></mat-progress-bar>
                <mat-progress-bar
                  *ngIf="item.progress > 68"
                  class="danger"
                  mode="determinate"
                  [value]="item.progress"
                ></mat-progress-bar> -->
                </div>
                <div class="content-description">
                  <div>วันหมดอายุ</div>
                  <!-- <div>{{ item.expire_date | date: "dd/MM/yyyy" }}</div> -->
                </div>
                <div class="content-description">
                  <div>เวลาทั้งหมด</div>
                  <!-- <div>{{ item.expire_duration_video | durationVideo }}</div> -->
                </div>
                <div class="content-description">
                  <div>เวลาที่ใช้ไป</div>
                  <!-- <div>{{ item.duration_spent | durationVideo }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="course-box">
        <div class="course-item">
          <img />
          <div class="course-detail">
            <!-- <div class="detail-title">{{ item.course_name }}</div> -->

            <div class="course-open">เปิดใช้งานคอร์สเรียน</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="load-more">ไม่พบรายการ</div>
    </div>
  </div>

  <div class="load-more" *ngIf="isLoadMore">
    <button>ดูเพิ่มเติม</button>
  </div>
</div>
