import { Component, OnInit, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HostListener } from '@angular/core';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { LogoConfig } from 'src/app/_models/layout.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isExceptDesktop: boolean;
  screenWidth: number;
  config: LogoConfig;

  host = environment.apiUrl.replace('/api', '');

  constructor(private deviceDetectService: DeviceDetectorService, private layoutConfigService: LayoutConfigService) {}

  ngOnInit(): void {
    // this.isExceptDesktop = !this.deviceDetectService.isDesk
    this.initLayout();
    this.onResize();
  }

  initLayout(): void {
    this.config = this.layoutConfigService.getSavedConfig('config.logo_pic');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
