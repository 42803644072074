import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuConfig, MenuList } from 'src/app/config/layouts/menu.config';
import { BlogServiceCategoryID } from 'src/app/services/blog.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CourseCategoryService } from 'src/app/_store/course-category/state/course-category.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  menuList$ = new BehaviorSubject<MenuList[]>([]);

  constructor(
    private courseCategoriesService: CourseCategoryService,
    private BlogService: BlogServiceCategoryID,
    private route: Router
  ) {
  }

  course_categories: any = [];
  Blog_Categories: any; //new BehaviorSubject<any[]>(null);
  subscriptions: Subscription[] = [];

  isShowDropdown = true;
  selectedDatax: any;


  ngOnInit(): void {
    this.menuList$.next(MenuConfig);
    this.getCourseCategories();
    this.getAllCategoryBlog();
  }

  dropdown() {
    this.isShowDropdown = !this.isShowDropdown;
  }

  ngOnDestroy(): void { }

  getCourseCategories() {
    const idOrder = [8, 6, 7, 9, 10];
    this.subscriptions.push(
      this.courseCategoriesService.get().subscribe(response => {
        response.sort((a: any, b: any) => idOrder.indexOf(a.id) - idOrder.indexOf(b.id));
        this.course_categories = response;
      })
    )
  }

  getAllCategoryBlog() {
    this.subscriptions.push(
      this.BlogService.getAllCategoryBlog().subscribe(response => {
        this.Blog_Categories = response;
      })
    )
  }

  sendSelectedItemAndNavigate(selectedItem: any) {
    const selectedData = selectedItem;
    this.BlogService.setData(selectedData);
    this.route.navigate(['/blogs'])

  }
}
