import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  forwardRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ErrorMessage } from 'src/app/utils/error-message';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextareaComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class TextareaComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);
    }
  }

  @Input() name: string;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() readonly = false;
  @Input() label: string;
  @Input() customClass: string;
  @Input() maxlength: number;

  @Output() selected = new EventEmitter();

  form = new FormControl();
  subscription: Subscription;
  errorMessage = '';
  isRequired = false;
  countText = 0;
  isError = false;

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe((val) => {
      this.setTrimString();
      if (this.maxlength) {
        this.textCounter();
      }

      this.onChange(this.form.value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value);
    if (this.form.value) {
      this.countText = this.form.value.length;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  /**
   *
   * @Validate
   */

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  /**
   *
   * @SetValue
   */

  setTrimString(): void {
    if (this.isString(this.form.value)) {
      this.form.patchValue(this.form.value.trim());
    }
  }

  textCounter(): void {
    if (this.form.value) {
      if (this.form.value.length > this.maxlength) {
        this.form.patchValue(this.form.value.substring(0, this.maxlength));
      } else {
        this.countText = this.form.value.length;
      }
    }
  }
}
