export interface MenuList {
  name: string;
  path: string;
  disabled?: boolean;
  isMobile?: boolean;
  isRoot?: boolean;
  isSubRoot?: boolean;
  subMenuList?: MenuList[];
  toggle?: boolean;
}

export const MenuConfig: MenuList[] = [
  {
    name: 'MENU.MAIN_PAGE',
    path: '/',
  },
  {
    name: 'MENU.COURSE',
    path: '/courses',
    // disabled: true,
  },
  // {
  //   name: 'MENU.EXAM_STORAGE',
  //   path: '/exam-storage',
  // },
  // {
  //   name: 'MENU.NEWS',
  //   path: '/news',
  // },
  {
    name: 'MENU.ABOUT',
    path: '/about-us',
  },
  {
    name: 'MENU.BLOG',
    path: '',
  }
];

export const MenuStudentConfig: MenuList[] = [
  {
    name: 'MENU.COURSE',
    path: '/courses',
  },
  // {
  //   name: 'MENU.EXAM_STORAGE',
  //   path: '/exam-storage',
  // },
  // {
  //   name: 'MENU.SUPPORT',
  //   path: '/support',
  // },
  {
    name: 'MENU.MAIN_PAGE',
    path: '/',
  },
  {
    name: 'ห้องเรียน',
    path: '/students',
    isRoot: true,
    isMobile: true,
    subMenuList: [
      {
        name: 'แดชบอร์ด',
        path: '/students/home',
        isMobile: true,
      },
      {
        name: 'บันทึกข้อมูลการเรียน',
        path: '/students/monitor',
        isMobile: true,
      },
      {
        name: 'บัญชีและโปรไฟล์',
        path: '/students/profile',
        isRoot: true,
        isMobile: true,
        subMenuList: [
          {
            name: 'จัดการข้อมูลส่วนตัว',
            path: '/students/profile/info',
          },
          {
            name: 'รหัสผ่าน',
            path: '/students/profile/password',
          },
          {
            name: 'เมนูผู้ปกครอง',
            path: '/students/profile/parent',
          },
        ],
      },
    ]
  }
];


export const MenuParentConfig: MenuList[] = [
  {
    name: 'MENU.MAIN_PAGE',
    path: '/',
  },
  {
    name: 'MENU.COURSE',
    path: '/courses',
  },
  {
    name: 'MENU.SUPPORT',
    path: '/support',
  },
  {
    name: 'MENU.MAIN_PAGE',
    path: '/',
  },
  {
    name: 'ส่วนของผู้ปกครอง',
    path: '/parents',
    isRoot: true,
    isMobile: true,
    subMenuList: [
      {
        name: 'คลังคอร์ส',
        path: '/parents/course-storage',
        isMobile: true,
      },
      {
        name: 'บันทึกข้อมูลการเรียน',
        path: '/parents/student-info-monitor',
        isMobile: true,
      },
      {
        name: 'บัญชีและโปรไฟล์',
        path: '/',
        isRoot: true,
        isMobile: true,
        subMenuList: [
          {
            name: 'จัดการข้อมูลส่วนตัว',
            path: '/parents/profile/info',
          },
          {
            name: 'รหัสผ่าน',
            path: '/parents/profile/password',
          },
          {
            name: 'จัดการนักเรียน',
            path: '/parents/profile/student',
          },
        ],
      },
    ]
  }
];
