import { Component, OnInit, Input } from '@angular/core';

export interface Breadcrumb {
  title: string;
  route: string;
  queryParams?: any;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbConfig: Breadcrumb[];

  constructor() { }

  ngOnInit(): void { }
}
