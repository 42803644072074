import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { StudentExamResult } from './student-exam-result.model';

export interface StudentExamResultState {
  create: {
    isLoading: boolean;
    error: string;
    data: StudentExamResult;
  };
  list: {
    isLoading: boolean;
    error: string;
    data: StudentExamResult[];
  };
  find: {
    isLoading: boolean;
    error: string;
    data: StudentExamResult;
  };
  studentAnswer: any;
}

export const initializeState = (): StudentExamResultState => {
  return {
    create: {
      isLoading: false,
      error: null,
      data: null,
    },
    list: {
      isLoading: false,
      error: null,
      data: null,
    },
    find: {
      isLoading: false,
      error: null,
      data: null,
    },
    studentAnswer: null,
  };
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'student-exam-result' })
export class StudentExamResultStore extends Store<StudentExamResultState> {
  constructor() {
    super(initializeState());
  }
}
