<div class="footer-licence">
  <div class="footer-licence-contrainner">
    <div class="footer-licence-content">
      <span>
        © Copyright 2021
        <span>
          <!-- {{ "LAYOUTS.FOOTERS.FOOTER_LICENCE.NAME" | translate }} -->
          <!-- {{ config.title }} -->
          Wise
        </span>
        . All Rights Reserved.
        <a href="http://riseplus.tech" target="_blank">Powered by Riseplus Technology.</a>
      </span>
    </div>
    <div class="footer-licence-nav">
      <ul>
        <li>
          <a>{{ "LAYOUTS.FOOTERS.FOOTER_LICENCE.PRIVACY" | translate }}</a>
        </li>
        <li>
          <a>{{ "LAYOUTS.FOOTERS.FOOTER_LICENCE.TERMS" | translate }}</a>
        </li>
        <li>
          <a>{{ "LAYOUTS.FOOTERS.FOOTER_LICENCE.COOKIE" | translate }}</a>
        </li>
        <li>
          <a>{{ "LAYOUTS.FOOTERS.FOOTER_LICENCE.SITEMAP" | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
