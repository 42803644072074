import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import * as moment from 'moment';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(value: Date, format?: string) {
    const lang = this.translationService.getSelectedLanguage();
    moment.locale(lang);
    const result = moment(value);

    if (lang === 'th') {
      result.add(543, 'year');
    }

    return result.format(format || 'DD MMMM YYYY');
  }
}
