<div class="dialog-component" *ngIf="dialogValue">
  <h3>{{ dialogValue.title }}</h3>
  <div class="dialog-content">
    <p>{{ dialogValue.content }}</p>
  </div>
  <mat-dialog-actions align="end">
    <ng-template *ngIf="dialogValue.decisionBtn" [ngTemplateOutlet]="decisionButton"></ng-template>
    <ng-template *ngIf="dialogValue.closeBtn" [ngTemplateOutlet]="closeButton"></ng-template>
  </mat-dialog-actions>
</div>

<ng-template #decisionButton>
  <div class="dialog-action">
    <button class="dialog-btn light" (click)="close()">ยกเลิก</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="dialog-btn light">ตกลง</button>
  </div>
</ng-template>
<ng-template #closeButton>
  <div class="dialog-action">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="dialog-btn light">ปิด</button>
  </div>
</ng-template>
