import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ParentCourse } from 'src/app/_store/parent-course/parent-course.model';
import { ParentCourseService } from 'src/app/_store/parent-course/parent-course.service';
import { Student } from 'src/app/_store/student/state/student.model';
import { StudentService } from 'src/app/_store/student/state/student.service';
import { UnderParenting } from 'src/app/_store/under-parenting/under-parenting.model';
import { UnderParentingService } from 'src/app/_store/under-parenting/under-parenting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-give-course',
  templateUrl: './dialog-give-course.component.html',
  styleUrls: ['./dialog-give-course.component.scss'],
})
export class DialogGiveCourseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  host = environment.apiUrl.replace('/api', '');
  studentId = new FormControl();
  studentList = [];
  studentlistConstant = [];
  amount: number;

  constructor(
    private dialogRef: MatDialogRef<DialogGiveCourseComponent>,
    private spinnerService: NgxSpinnerService,
    private parentCourse: ParentCourseService,
    private notifierService: NotifierService,
    private studentService: StudentService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: ParentCourse
  ) {}

  ngOnInit(): void {
    this.initDialog();
    this.getlistStudent();
    this.amount = this.data.amount;
  }

  ngOnDestroy(): void {}

  initDialog(): void {
    this.form = this.fb.group({
      new_give_courses: this.fb.array([]),
    });
  }

  get getGaveList(): FormArray {
    return this.form.get('new_give_courses') as FormArray;
  }

  removeGaveList(i: number): void {
    // const oldList = this.getGaveList.value;
    // this.studentList = this.studentlistConstant.filter((f) => oldList.some((o) => f.id !== o.id));
    this.amount++;
    this.getGaveList.removeAt(i);
  }

  getlistStudent(): void {
    this.studentService
      .getStudentCoursesList(this.data.course_id)
      .pipe(untilDestroyed(this))
      .subscribe((res: Student[]) => {
        if (res) {
          const data = res.map((item) => {
            return { id: item.id, name: item.full_name, image_url: item.image_url };
          });
          this.studentlistConstant = this.studentList.concat(data);
          this.studentList = this.studentlistConstant;
        }
      });
  }

  async giveCourse(): Promise<void> {
    const check = await this.studentService
      .checkHaveAlreadyCourse(this.data.course_id, this.studentId.value)
      .toPromise()
      .then((res) => {
        if (!res.exist) {
          this.amount--;
          const student = this.studentList.find((el) => el.id === this.studentId.value);
          const form = this.fb.group({
            parent_course_id: this.data.id,
            student_id: this.studentId.value,
            student_name: student.name,
            student_image_url: student.image_url,
          });
          this.getGaveList.push(form);
          // this.studentList = this.studentList.filter((f) => f.id !== this.studentId.value);
          this.studentId.reset();
        } else {
          this.notifierService.notify('error', res.messages);
        }
      })
      .catch((e) => this.notifierService.notify('error', e.errors));
  }

  confirm(): void {
    if (this.getGaveList.length > 0) {
      this.spinnerService.show();
      const value = this.form.value;
      this.parentCourse
        .giveCourse(value)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (res) {
            setTimeout((_) => {
              this.spinnerService.hide();
              this.dialogRef.close(true);
            }, 500);
          }
        });
    } else {
      this.notifierService.notify('warning', 'กรุณาเพิ่มนักเรียน!');
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
