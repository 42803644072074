<div class="stat-study">
  <div class="stat-study-graph">
    <!-- <div class="stat-study-chanel-group">
      <div class="stat-study-chanel">
        <div class="stat-study-header">
          <div class="stat-study-title">เวลาเรียน</div>
          <div class="stat-study-filters">
            <button
              class="filters-day"
              [ngClass]="{ active: i === indexFIlterResultLesson }"
              (click)="onFilterResultLesson(i)"
              *ngFor="let filter of filterBackDateList; index as i"
            >
              {{ filter.name }}
            </button>
          </div>
        </div>
        <div class="stat-result" *ngIf="resultLesson$ | async as resultLesson">
          <circle-progress
            [percent]="100"
            [radius]="80"
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="0"
            [space]="0"
            [outerStrokeColor]="'#E50172'"
            [innerStrokeColor]="'#E5017226'"
            [animation]="false"
            [animationDuration]="300"
            [titleFontSize]="42"
            [subtitleFontSize]="16"
            [showUnits]="false"
            [title]="resultLesson.total_duratoin_time | durationVideo: 'HH.mm'"
            subtitle="ชั่วโมง"
          ></circle-progress>

          <div class="stat-detail-group" *ngIf="resultLesson$ | async as resultLesson">
            <div class="stat-detail">
              <span>จำนวนคอร์สเรียน</span>
              <span>{{ resultLesson.total_course }} คอร์ส</span>
            </div>
            <div class="stat-detail">
              <span>จำนวน Chapter</span>
              <span>{{ resultLesson.total_chapter_group }} Chapter</span>
            </div>
            <div class="stat-detail">
              <span>เวลาเรียนติดต่อกันสูงสุด</span>
              <span>{{ resultLesson.duration_watch_video_max | durationVideo: "HH:mm:ss" }}</span>
            </div>
            <div class="stat-detail">
              <span>เวลาเรียนติดต่อกันต่ำสุด</span>
              <span>{{ resultLesson.duration_watch_video_min | durationVideo: "HH:mm:ss" }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="stat-study-chanel">
        <div class="stat-study-header">
          <div class="stat-study-title">ช่วงเวลาที่เรียนในวันนี้</div>
          <div class="stat-study-filters">
            <button class="filters-day active">7 วัน</button>
            <button class="filters-day">30 วัน</button>
            <button class="filters-day">ทั้งหมด</button>
          </div>
        </div>
        <ngx-charts-bar-vertical
          class="stat-study-chart"
          [view]="[550, 180]"
          [scheme]="colorScheme1"
          [results]="singledata"
          [gradient]="true"
          [xAxis]="true"
          [yAxis]="true"
        >
        </ngx-charts-bar-vertical>
      </div>
    </div> -->

    <div class="stat-study-chanel">
      <div class="stat-study-header">
        <div class="stat-study-title">เวลาเรียนย้อนหลัง 7 วัน</div>
        <div class="stat-study-filters">
          <button
            class="filters-day"
            [ngClass]="{ active: i === indexFIlterDuratoinLesson }"
            (click)="onFilterDurationLesson(i)"
            *ngFor="let filter of filterBackDateList; index as i"
          >
            {{ filter.name }}
          </button>
        </div>
      </div>
      <ngx-charts-bar-vertical-2d
        class="stat-study-chart"
        [view]="[1150, 300]"
        [scheme]="colorScheme2"
        [results]="chartDurationLessonList"
        [gradient]="true"
        [legendTitle]="''"
        [legend]="true"
        [xAxis]="true"
        [yAxis]="true"
        legendPosition="below"
      >
      </ngx-charts-bar-vertical-2d>
    </div>
  </div>

  <div class="stat-study-table">
    <div class="stat-study-header">
      <div class="stat-study-title">ประวัติการเรียน</div>
      <div class="stat-study-filters">
        <button
          class="filters-day"
          [ngClass]="{ active: i === indexFIlterActivityHistory }"
          (click)="onFilterActivityHistory(i)"
          *ngFor="let filter of filterBackDateList; index as i"
        >
          {{ filter.name }}
        </button>
      </div>
    </div>
    <div class="study-history">
      <div class="stat-study-table">
        <table>
          <tr>
            <th>#</th>
            <th>วันที่</th>
            <!-- <th>เวลา</th> -->
            <th class="text-left">คอร์สเรียน</th>
            <th class="text-left">บทเรียน</th>
            <th>Chapter</th>
            <th>เวลารวม (ชม.)</th>
          </tr>
          <ng-container *ngFor="let data of (activityHistory$ | async)?.data; index as i">
            <tr>
              <td>{{ (page - 1) * limit + i + 1 }}</td>
              <td>{{ data.created_at | customDate: "DD/MM/YYYY HH:mm" }}</td>
              <!-- <td>{{ data.time }}</td> -->
              <td class="text-left">
                <span class="link">{{ data.course_name }}</span>
              </td>
              <td class="text-left">{{ data.chapter_group_name }}</td>
              <td>{{ data.chapter_name }}</td>
              <td>{{ data.duration_time | durationVideo: "fullTime" }}</td>
            </tr>
          </ng-container>
        </table>
      </div>

      <div class="stat-study-table-footer">
        <app-paginator
          [page]="page"
          [totalPage]="(activityHistory$ | async)?.total_page"
          (pageChange)="onPageActivityHistoryChange($event)"
        ></app-paginator>
      </div>
    </div>
  </div>
</div>
