<div class="page">
  <!-- <p>ยังไม่มีข้อมูล</p> -->
  <ng-container [formGroup]="form">
    <div class="statistics-header row">
      <div class="statistice-tab col-md-4">
        <ng-container *ngFor="let tab of tabStatistics; index as i">
          <button class="btn tab-btn" [ngClass]="{ active: tabIndex === tab.id }" (click)="toggleTab(i)">
            {{ tab.name }}
          </button>
        </ng-container>
      </div>
      <div class="col-md-4"></div>
      <div class="statistics-courses col-md-4">
        <app-select
          label="คอร์สเรียน"
          labelMode="inline"
          formControlName="course_id"
          [clearable]="false"
          [options]="courseFilterOptions"
        ></app-select>
      </div>
    </div>

    <div class="statistics-content">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #content>
  <div *ngIf="tabIndex === 1">
    <app-study-time></app-study-time>
  </div>
  <div *ngIf="tabIndex === 2">
    <app-examination [params]="params"></app-examination>
  </div>
  <!-- <router-outlet></router-outlet> -->
</ng-template>
