import { Injectable } from '@angular/core';
import { Auth } from './auth.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface AuthState extends EntityState<Auth> {
  isAuthenticate: boolean;
}

export function createInitialState(): AuthState {
  return {
    isAuthenticate: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends EntityStore<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
