import { Component, OnInit, Input, OnDestroy, forwardRef, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { GenerateRandom } from 'src/app/utils/rendom';
import { ColorConfig } from 'src/app/_models/layout.model';
import { LabelMode } from '../_enums/label-mode.enum';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SwitchComponent), multi: true }],
  encapsulation: ViewEncapsulation.None,
})
export class SwitchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];
      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = value;
      }
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() readonly = false;
  @Input() label: string;
  @Input() inputLabel: string;
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();
  subscription: Subscription;
  errorMessage = '';
  isRequired = false;
  isError = false;

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  color: ColorConfig;

  constructor(private layoutConfigService: LayoutConfigService) {}

  ngOnInit(): void {
    this.initColor();
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      const value = this.form.value;
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initColor(): void {
    this.color = this.layoutConfigService.getSavedConfig('config.color');
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}
}
