import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogsComponent } from './dialogs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DialogsComponent],
  imports: [CommonModule, MatDialogModule, ReactiveFormsModule, FormsModule],
})
export class DialogsModule {}
