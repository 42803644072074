<div class="footer">
  <div class="container">
    <div class="grid grid-cols-xl-2 gap-y-3">
      <div class="grid grid-cols-md-2 gap-x-2 gap-y-3">
        <div class="d-flex flex-column gap-75">
          <div class="footer-title">Eazy Cal</div>
          <p class="mb-0">{{ content.address }}</p>
          <a class="d-flex align-items-center gap-75 footer-link" href="tel:0815879998">
            <i-feather name="phone"></i-feather>
            <span>{{ content.tel }}</span>
          </a>
        </div>
        <div class="d-flex flex-column gap-75">
          <div class="footer-title">Eazy Cal Social Media</div>
          <a class="d-flex align-items-center gap-75 footer-link" [href]="content.facebook.url" target="_blank">
            <div class="social-icon">
              <img src="./assets/images/footer/facebook.svg">
            </div>
            <span>{{ content.facebook.name }}</span>
          </a>
          <a class="d-flex align-items-center gap-75 footer-link" [href]="content.line.url" target="_blank">
            <div class="social-icon">
              <img src="./assets/images/footer/line.svg">
            </div>
            <span>{{ content.line.name }}</span>
          </a>
          <a class="d-flex align-items-center gap-75 footer-link" [href]="content.youtube.url" target="_blank">
            <div class="social-icon">
              <img src="./assets/images/footer/youtube.svg">
            </div>
            <span>{{ content.youtube.name }}</span>
          </a>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center align-items-xl-end gap-50">
        <h1 class="footer-hero mb-0">
          <span class="text-yellow">Eazy Cal</span> The <span class="text-orange">easy</span><br><span class="text-blue">way</span> of <span class="text-primary">calculus</span>
        </h1>
        <div class="footer-term">
          <a class="footer-link" href="javascript:;">Terms of Use</a>
          <span class="divider"></span>
          <a class="footer-link" href="javascript:;">Privacy Policy</a>
          <span class="divider"></span>
          <a class="footer-link" href="javascript:;">Payment and Promotion Policy</a>
        </div>
        <p class="mb-0 f-s-14 text-center">© Copyright 2021 Wise. All Rights Reserved. Powered by Riseplus Technology.</p>
      </div>
    </div>
  </div>
</div>