<div class="page">
  <div class="side-tab">
    <ng-container [ngTemplateOutlet]="side_tab"></ng-container>
  </div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #side_tab>
  <ng-container *ngFor="let tab of sideMenu; index as i">
    <div class="btn" routerLinkActive="active" [routerLink]="tab.route">
      <feather-icon class="icon" [name]="tab.icon"></feather-icon> {{ tab.name }}
    </div>
  </ng-container>
</ng-template>
