import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-progress',
  templateUrl: './study-progress.component.html',
  styleUrls: ['./study-progress.component.scss'],
})
export class StudyProgressComponent implements OnInit {
  isLoadMore = false;
  isLoading = true;

  constructor() {}

  ngOnInit(): void {}
}
