import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Student } from './student.model';
import { StudentStore, StudentState } from './student.store';

@Injectable({ providedIn: 'root' })
export class StudentQuery extends QueryEntity<StudentState> {
  current$ = this.select('current');
  find$ = this.select('find');
  courseLasted$ = this.select('courseLasted');

  constructor(protected store: StudentStore) {
    super(store);
  }

  get getStudentCurrentData(): Student {
    return this.store.getValue().current;
  }
}
