import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudentCourseService } from 'src/app/_store/student-course/state/student-course.service';
import { StudentExamResultParams } from 'src/app/_store/student-exam-result/student-exam-result.model';
import { UserQuery } from 'src/app/_store/user/state/user.query';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss'],
})
export class MonitorComponent implements OnInit, OnDestroy {
  form: FormGroup;
  tabIndex = 1;

  tabStatistics = [
    {
      id: 1,
      name: 'เวลาเรียน',
      path: '/students/monitor/study-time',
    },
    {
      id: 2,
      name: 'การสอบ',
      path: '/students/monitor/examination',
    },
  ];

  courseFilterOptions = [
    {
      id: 0,
      name: 'ทั้งหมด',
    },
  ];

  params: StudentExamResultParams;

  constructor(
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private studentCourseService: StudentCourseService,
    private router: Router,
    private userQuery: UserQuery
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getUser();
    this.spinnerService.show();
    this.initTab();
    this.intStudentCourseList();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.spinnerService.show();
        setTimeout((_) => {
          this.spinnerService.hide();
        }, 500);
        if (res.course_id === 0) {
          this.params = { ...this.params, course_id: null };
        } else {
          this.params = { ...this.params, ...res };
        }
      }
    });
  }

  ngOnDestroy(): void {}

  intStudentCourseList(): void {
    this.studentCourseService
      .getSelectCourseList({ is_activated: true })
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          setTimeout((_) => {
            this.spinnerService.hide();
          }, 800);
          const data = res.map((item) => {
            return { id: item.course_id, name: item.course_name };
          });
          this.courseFilterOptions = this.courseFilterOptions.concat(data);
        }
      });
  }

  initTab(): void {
    this.tabStatistics.find((menu) => {
      if (menu.path === this.router.url) {
        this.tabIndex = menu.id;
      }
    });
  }

  toggleTab(index: number): void {
    this.tabIndex = index + 1;
    this.router.navigateByUrl(this.tabStatistics[index].path);
  }

  initForm(): void {
    this.form = this.fb.group({
      course_id: [0],
      user_id: [0],
    });
  }

  getUser(): void {
    this.userQuery.current$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.form.patchValue({ user_id: res.id });
        this.params = { ...this.form.value, ...this.params };
      }
    });
  }
}
