<div class="auth-dialog">
  <div class="auth-dialog-bg">
    <div class="bg-content">
      <div class="bg-title">{{ "AUTH.SIGN_IN.TITLE" | translate }}</div>
      <div class="bg-title">{{ "APP_TITLE" | translate }}</div>
      <div class="bg-line"></div>
      <div class="bg-description">{{ "AUTH.SIGN_IN.DESCRIPTION" | translate }}</div>
    </div>
    <div class="mobile-scroll">
      <div class="arrow bounce">
        <a class="fa fa-arrow-down fa-2x" href="#"></a>
      </div>
    </div>
  </div>
  <div class="auth-dialog-content">
    <div class="signin-page">
      <!-- <app-social-signin></app-social-signin>
      <div class="divide"><span>OR</span></div> -->
      <div class="signin-content">
        <form [formGroup]="form">
          <div class="form-signin input-group">
            <feather-icon name="mail" class="icon"></feather-icon>
            <input id="email" formControlName="email" class="form-control" placeholder="Email" />
          </div>
          <div class="form-signin input-group">
            <feather-icon name="lock" class="icon"></feather-icon>
            <input formControlName="password" class="form-control" placeholder="รหัสผ่าน" type="password" />
          </div>

          <div class="signin-forgot" (click)="forgotPassword()">{{ "AUTH.SIGN_IN.FORGET" | translate }}</div>

          <button class="btn btn-primary btn-signin" (click)="onSignin()">{{ "AUTH.SIGN_IN.NAME" | translate }}</button>

          <div class="signin-remember">
            <mat-checkbox>{{ "AUTH.SIGN_IN.LET_ME_STAY" | translate }}</mat-checkbox>
          </div>

          <div class="signup">
            {{ "AUTH.SIGN_IN.NO_HAVE_ACCOUNT_YET" | translate }}
            <span class="text-primary" (click)="onSignup()">{{ "AUTH.SIGN_IN.REGISTER" | translate }}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
