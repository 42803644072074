<div class="auth-dialog">
  <div class="auth-dialog-content">
    <div class="policy-page">
      <div class="content-title">
        {{ "AUTH.SIGN_UP.POLICY_CONTENT.TITLE" | translate }}
      </div>
      <div class="bg-line"></div>
      <div class="content-scroll">
        <div class="content-description">
          <p>
            ไรส์พลัส เทคโนโลยี จำกัด ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ
            โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล
            ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
          </p>
          <p >
            <span class="text-bold">การเก็บรวบรวมข้อมูลส่วนบุคคล</span>
          </p>
          <p>
            เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้
          </p>
          <p>
            ●	การสมัครสมาชิก
          </p>
          <p>
            ●	โทรศัพท์
          </p>
          <p>
            ●	อีเมล
          </p>
          <p>
            ●	Facebook Login
          </p>
          <p>
            ●	Google Login
          </p>
          <p>
            <span class="text-bold">ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</span>
          </p>
          <p>
            <span class="text-bold">ข้อมูลส่วนบุคคล</span> เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด สัญชาติ เลขประจำตัวประชาชน หนังสือเดินทาง เป็นต้น
          </p>
          <p>
            <span class="text-bold">ข้อมูลการติดต่อ</span> เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น
          </p>
          <p>
            <span class="text-bold">ข้อมูลบัญชี</span> เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น
          </p>
          <p>
            <span class="text-bold">หลักฐานแสดงตัวตน</span> เช่น สำเนาบัตรประจำตัวประชาชน สำเนาหนังสือเดินทาง เป็นต้น
          </p>
          <p>
            <span class="text-bold">ข้อมูลการทำธุรกรรมและการเงิน</span> เช่น ประวัติการสั่งซื้อ รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น
          </p>
          <p>
            <span class="text-bold">ข้อมูลทางเทคนิค</span> เช่น IP address, Cookie ID, ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น
          </p>
          <p>
            <span class="text-bold">ข้อมูลอื่น ๆ</span> เช่น รูปภาพ ภาพเคลื่อนไหว และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
          </p>
          <p>
            <span class="text-bold">ผู้เยาว์</span> หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้ หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
          </p>
          <p><span class="text-bold">วิธีการเก็บรักษาข้อมูลส่วนบุคคล</span></p>
          <p>
            เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
            เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้
          </p>
          <p>●	เซิร์ฟเวอร์บริษัทของเราในประเทศไทย</p>

          <p>เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
          <p>●	เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</p>
          <p>●	เพื่อจัดส่งสินค้าหรือบริการ</p>
          <p>●	เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</p>
          <p>●	เพื่อการตลาดและการส่งเสริมการขาย</p>
          <p>●	เพื่อการบริการหลังการขาย</p>
          <p>●	เพื่อรวบรวมข้อเสนอแนะ</p>
          <p>●	เพื่อชำระค่าสินค้าหรือบริการ</p>
          <p>●	เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</p>
          <p>●	เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ</p>
          <p><span class="text-bold">ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</span></p>
          <p>เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้ ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว</p>
          <p><span class="text-bold">สิทธิของเจ้าของข้อมูลส่วนบุคคล</span></p>
          <p>ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล  คุณมีสิทธิในการดำเนินการดังต่อไปนี้</p>
          <p><span class="text-bold">สิทธิขอถอนความยินยอม (right to withdraw consent)</span>หากคุณได้ให้ความยินยอม เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา</p>
          <p><span class="text-bold">สิทธิขอเข้าถึงข้อมูล (right to access)</span>คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ  รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร</p>
          <p><span class="text-bold">สิทธิขอถ่ายโอนข้อมูล (right to data portability)</span>คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค</p>
          <p><span class="text-bold">สิทธิขอคัดค้าน (right to object)</span>คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้ หากการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์</p>
          <p><span class="text-bold">สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction)</span>คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้ หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว</p>
          <p><span class="text-bold">สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing)</span>คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน</p>
          <p><span class="text-bold">สิทธิขอให้แก้ไขข้อมูล (right to rectification)</span>คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด</p>
          <p><span class="text-bold">สิทธิร้องเรียน (right to lodge a complaint)</span>คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง</p>
          <p>
            คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้ เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้ หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
          </p>
          <p>
            <span class="text-bold">การโฆษณาและการตลาด</span>
          </p>
          <p>เราอาจส่งข้อมูลหรือจดหมายข่าวไปยังอีเมลของคุณ โดยมีวัตถุประสงค์เพื่อเสนอสิ่งที่น่าสนกับคุณ หากคุณไม่ต้องการรับการติดต่อสื่อสารจากเราผ่านทางอีเมลอีกต่อไป คุณสามารถกด "ยกเลิกการติดต่อ" ในลิงก์อีเมลหรือติดต่อมายังอีเมลของเราได้</p>
          <p>
            <span class="text-bold">เทคโนโลยีติดตามตัวบุคคล (Cookies)</span>
          </p>
          <p>เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น เราใช้คุกกี้ (Cookies)หรือเทคโนโลยีที่คล้ายคลึงกัน เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม และติดตามการใช้งานของคุณ เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์และการเข้าถึงเว็บไซต์ของเรา หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้</p>
          <p>
            <span class="text-bold">การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</span>
          </p>
          <p>เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)</p>
          <p>
            <span class="text-bold">การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</span>
          </p>
          <p>ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน 72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้ ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง ๆ เช่น  เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น</p>
          <p>
            <span class="text-bold">การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</span>
          </p>
          <p>เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา</p>
          <p>นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 31 พฤษภาคม 2565</p>
          <p>
            <span class="text-bold">นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</span>
          </p>
          <p>นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย</p>
          <p>
            <span class="text-bold">รายละเอียดการติดต่อ</span>
          </p>
          <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราได้ ดังนี้</p>
          <p>
            <span class="text-bold">ผู้ควบคุมข้อมูลส่วนบุคคล</span>
          </p>
          <p>ไรส์พลัส เทคโนโลยี จำกัด</p>
          <p>999/42 หมู่ 15 ศิลา  ในเมือง  เมืองขอนแก่น  ขอนแก่น  40000</p>
          <p>อีเมล riseplus.technology@gmail.com</p>
          <p>
            <span class="text-bold">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</span>
          </p>
          <p>ทีมการตลาด</p>
          <p>999/42 หมู่ 15 ศิลา  ในเมือง  เมืองขอนแก่น  ขอนแก่น  40000</p>
          <p>อีเมล riseplus.technology@gmail.com</p>
          <p>หมายเลขโทรศัพท์ (+66)43 000 924</p>
        </div>
      </div>
      <div class="content-footer">
        <button class="btn btn-primary btn-reset btn-sm" (click)="closeModal()">
          {{ "AUTH.SIGN_UP.POLICY_CONTENT.CLOSE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
