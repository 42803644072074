import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StudentCourseStore, StudentCourseState } from './student-course.store';

@Injectable({ providedIn: 'root' })
export class StudentCourseQuery extends QueryEntity<StudentCourseState> {
  constructor(protected store: StudentCourseStore) {
    super(store);
  }

  selectCourse$ = this.select((state) => {
    return state.find ? state.find.course : null;
  });
}
