import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutConfigService } from 'src/app/services/layout-config.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { LogoConfig } from 'src/app/_models/layout.model';
import { StudentExamResultService } from 'src/app/_store/student-exam-result/student-exam-result.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-exam',
  templateUrl: './header-exam.component.html',
  styleUrls: ['./header-exam.component.scss'],
})
export class HeaderExamComponent implements OnInit {
  config: LogoConfig;

  host = environment.apiUrl.replace('/api', '');

  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private studentExamResultService: StudentExamResultService,
    private layoutConfigService: LayoutConfigService
  ) {}

  ngOnInit(): void {
    this.initLayout();
  }

  initLayout(): void {
    this.config = this.layoutConfigService.getSavedConfig('config.logo_pic');
  }

  getBack(): void {
    const current = this.sessionStorageService.sessionData;

    if (current.history) {
      this.router.navigate(['students', 'monitor', 'examination']);
    } else {
      if (current.student_course_id) {
        const id = current.student_course_id;
        this.router.navigate(['students', id, 'watch']);
      } else {
        this.router.navigate(['exam-storage']);
      }
    }

    this.studentExamResultService.clearStudentAnswer();
  }
}
