import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as objectPath from 'object-path';
import { ColorConfig, LayoutConfig } from '../_models/layout.model';

@Injectable({
  providedIn: 'root',
})
export class LayoutConfigService {
  layoutConfig: LayoutConfig;

  constructor(private http$: HttpClient) {}

  /**
   * Initialize layout config
   */
  async loadConfigs(): Promise<void> {
    this.layoutConfig = this.getSavedConfig();

    // if (!this.layoutConfig) {
    this.layoutConfig = await this.getConfig();
    // }
    this.saveConfig(this.layoutConfig);
    this.setSassVariable();
  }

  /**
   * Check layout version
   */
  async isVersionExist(): Promise<{ exist: boolean }> {
    const layout = this.getSavedConfig();

    const exist = await this.http$.get<{ exist: boolean }>(`/layouts/check_version/${layout?.version}`).toPromise();

    return exist;
  }

  /**
   * Save layout config to the local storage
   * @param layoutConfig
   */
  saveConfig(layoutConfig: LayoutConfig): void {
    if (layoutConfig) {
      localStorage.setItem('layoutConfig', JSON.stringify(layoutConfig));
    }
  }

  /**
   * Get layout config from local storage
   */
  getSavedConfig(path?: string): LayoutConfig | any {
    const config = localStorage.getItem('layoutConfig');
    try {
      if (path) {
        // if path is specified, get the value within object
        return objectPath.get(JSON.parse(config), path);
      }

      return JSON.parse(config);
    } catch (e) {}
  }

  /**
   * Get all config
   */
  async getConfig(): Promise<any> {
    this.layoutConfig = this.getSavedConfig();
    await this.http$
      .get<LayoutConfig>(`/layouts`)
      .toPromise()
      .then((res) => {
        if (res) {
          this.layoutConfig = res;
        }
      });

    return this.layoutConfig;
  }

  /**
   * Reload layout config
   */
  reloadConfigs(): void {
    // this.resetConfig();
    // this.loadConfigs();
  }

  /**
   * Remove saved layout config and revert back to default
   */
  resetConfig(): void {
    localStorage.removeItem('layoutConfig');
  }

  /**
   * Set sass variable
   */
  setSassVariable(): void {
    // console.log(this.layoutConfig);
    const layout = this.getSavedConfig() || this.layoutConfig;
    const color = objectPath.get(layout, 'config.color') as ColorConfig;

    // console.log(layout);
    // console.log(color);

    try {
      if (color) {
        document.documentElement.style.setProperty('--font-color', color.font);
        document.documentElement.style.setProperty('--primary-color', color.primary);
        document.documentElement.style.setProperty('--primary-color-hover', color.primary_hover);
        document.documentElement.style.setProperty('--primary-border', color.primary_border);
        document.documentElement.style.setProperty('--secondary-color', color.secondary);
        document.documentElement.style.setProperty('--secondary-color-hover', color.secondary_hover);
        document.documentElement.style.setProperty('--secondary-border', color.secondary_border);
      }
    } catch (e) {}
  }
}
